import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import style from "./style.module.scss";

interface Props {
    onClick: (e: React.MouseEvent) => void;
}

export const TrashIcon = (props: Props) => (
    <DeleteOutlined className={style.icon} onClick={props.onClick} />
);
