import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from '@apollo/client'

import "./App.scss";
import { StoreProvider } from "./stores";
import { HomePage, LoginPage } from "./containers";
import "antd/dist/antd.css";
import client from './graphql/client'

function App() {
    return (
        <ApolloProvider client={client}>
            <StoreProvider>
                <Router>
                    <Switch>
                        <Route path="/login">
                            <LoginPage />
                        </Route>
                        <Route path="/">
                            <HomePage />
                        </Route>
                    </Switch>
                </Router>
            </StoreProvider>
        </ApolloProvider>
    );
}

export default App;
