import React from 'react'
import {useHistory, useLocation} from "react-router-dom";
import {Routes} from "./routes";
import {useGlobalStore} from "../stores";

export const useNavigation = () => {
    const history = useHistory();
    const location = useLocation()
    const globalStore = useGlobalStore();

    //reset country on page change
    React.useEffect(() => {
        globalStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return {
        // Home
        goToHome() {
            history.push(Routes.HOME);
        },
        replaceToLogin() {
            history.replace(Routes.LOGIN);
        },
        // Move Requests
        goToMoveRequests() {
            history.push(Routes.MOVE_REQUESTS);
        },
        goToMoveRequest(moveRequestId: string) {
            history.push(`${Routes.MOVE_REQUESTS}/${moveRequestId}`);
        },
        // Organizations
        goToOrganizations() {
            history.push(Routes.ORGANIZATIONS);
        },
        createOrganization() {
            history.push(`${Routes.ORGANIZATIONS}/create`);
        },
        editOrganization(id?: string | null) {
            if (id) {
                history.push(`${Routes.ORGANIZATIONS}/edit/${id}`);
            }
        },
        goToOrganizationCategories() {
            history.push(Routes.ORGANIZATION_CATEGORIES);
        },
        createOrganizationCategory() {
            history.push(`${Routes.ORGANIZATION_CATEGORIES}/create`);
        },
        editOrganizationCategory(id?: string | null) {
            if (id) {
                history.push(`${Routes.ORGANIZATION_CATEGORIES}/edit/${id}`);
            }
        },
        // Users
        goToUsers() {
            history.push(Routes.USERS);
        },
        goToUser(userId?: string | null) {
            if (userId) {
                history.push(`${Routes.USERS}/${userId}`);
            }
        },
        // Brokers
        goToBrokers() {
            history.push(Routes.BROKERS);
        },
        createBroker() {
            history.push(`${Routes.BROKERS}/create`);
        },
        editBroker(id: string) {
            history.push(`${Routes.BROKERS}/edit/${id}`);
        },
        // Modules
        goToModules() {
            history.push(Routes.MODULES);
        },
        createModule() {
            history.push(`${Routes.MODULES}/create`);
        },
        editModule(id: string) {
            history.push(`${Routes.MODULES}/edit/${id}`);
        },

        goToModulesCustom() {
            history.push(Routes.MODULES_CUSTOM);
        },
        createMoveStep() {
            history.push(`${Routes.MOVE_STEPS}/create`);
        },
        editMoveStep(id: string) {
            history.push(`${Routes.MOVE_STEPS}/edit/${id}`);
        },

        createMoveCategory() {
            history.push(`${Routes.MOVE_CATEGORIES}/create`);
        },
        editMoveCategory(id: string) {
            history.push(`${Routes.MOVE_CATEGORIES}/edit/${id}`);
        },
        // Buy Requests
        goToBuyRequests() {
            history.push(`${Routes.BUY_REQUESTS}`);
        },
        goToBuyRequest(id: string) {
            history.push(`${Routes.BUY_REQUESTS}/${id}`);
        },
        goToRatings() {
            history.push(Routes.RATINGS);
        },
        goToConfig() {
            history.push(Routes.CONFIG);
        },
    };
};
