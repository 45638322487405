import React from "react";
import { EntityForm } from "../form";
import { Skeleton, Button, Drawer, Typography, Tooltip } from "antd";
import {
    InputWrapper,
    NewIcon,
    SelectWrapper,
    SwitchWrapper,
    SmartTrashIcon,
} from "../../../components";
import { contactMethods, contactLanguages } from "../../../utils";
import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import style from "./entities.module.scss";
import { EntityErrors } from "./errors-map";

const { Title } = Typography;

interface EntitiesListProps {
    onChange: (e: React.ChangeEvent) => void;
    onBlur: (e: React.ChangeEvent) => void;
    onAdd: () => void;
    onDelete: (index: number, id: string, isNew?: boolean) => void;
    onEntitySelect: (entity: EntityForm) => void;
    entities: EntityForm[];
    entityErrors: EntityErrors;
    selectedEntityId?: string;
    isLoading?: boolean;
}

export const EntitiesList = React.memo((props: EntitiesListProps) => {
    return (
        <div className="description-wrapper">
            <Skeleton
                loading={props.isLoading}
                active={true}
                paragraph={{ rows: 8 }}
            >
                <Title level={4} className="flex-12">
                    Entities
                </Title>
                <Button
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    className={style.add_button}
                    type="primary"
                    onClick={() => props.onAdd()}
                    icon={<PlusOutlined />}
                >
                    Add
                </Button>
                {props.entities.map((entity, index) => (
                    <div
                        onClick={() => props.onEntitySelect(entity)}
                        className={`${style.entity_row} ${
                            entity.id === props.selectedEntityId
                                ? style.selected
                                : ""
                        }`}
                        key={entity.id}
                    >
                        <span className={style.icon_list_holder}>
                            {entity.nameFr || "New Entity"}{" "}
                            <NewIcon id={entity.id} isNew={entity.isNew} />
                            {props.entityErrors.get(entity.id) && (
                                <Tooltip title="This entity has error">
                                    <WarningOutlined
                                        className={style.warning_icon}
                                    />
                                </Tooltip>
                            )}
                        </span>

                        <SmartTrashIcon
                            onConfirm={() =>
                                props.onDelete(index, entity.id, entity.isNew)
                            }
                            skipConfirmation={entity.isNew}
                        />
                    </div>
                ))}
            </Skeleton>
        </div>
    );
});

interface EntityDrawerProps {
    onChange: (e: React.ChangeEvent) => void;
    onBlur: (e: React.ChangeEvent) => void;
    onClose: (isOpen: false) => void;
    entity: EntityForm | null | undefined;
    setFieldValue: (field: string, value: any) => void;
    index: number;
    isOpen: boolean;
}

export const EntityDrawer = (props: EntityDrawerProps) => {
    return (
        <Drawer
            visible={props.isOpen}
            onClose={() => props.onClose(false)}
            width={720}
            mask={false}
        >
            <div id="entity-drawer" className="flex-wrapper">
                <Title level={3} className="flex-12">
                    Entity
                </Title>
                <InputWrapper
                    value={props.entity?.nameFr}
                    required={true}
                    title="Name (Fr)"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].nameFr`}
                    flexSize={6}
                />
                <InputWrapper
                    value={props.entity?.nameEn ?? ""}
                    title="Name (En)"
                    required={true}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].nameEn`}
                    flexSize={6}
                />
                <span className="small_separation_text flex-12">
                    Primary Contact
                </span>
                <InputWrapper
                    required={true}
                    value={props.entity?.primaryContactEmail}
                    title="Email"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].primaryContactEmail`}
                    flexSize={6}
                />
                <InputWrapper
                    value={props.entity?.secondaryContactEmail}
                    title="Email CC"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].secondaryContactEmail`}
                    flexSize={6}
                />
                <InputWrapper
                    value={props.entity?.primaryContactPhone}
                    title="Phone"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].primaryContactPhone`}
                    flexSize={6}
                />
                <SelectWrapper
                    flexSize={6}
                    options={contactMethods}
                    value={props.entity?.contactMethod}
                    name={`entities[${props.index}].contactMethod`}
                    setFieldValue={props.setFieldValue}
                    title="Contact method"
                    getPopupContainer={() =>
                        document.getElementById("entity-drawer")!!
                    }
                />
                <InputWrapper
                    value={props.entity?.contactWebsiteFr}
                    title="Contact website fr"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].contactWebsiteFr`}
                    flexSize={6}
                />
                <InputWrapper
                    value={props.entity?.contactWebsiteEn}
                    title="Contact website en"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].contactWebsiteEn`}
                    flexSize={6}
                />
                <SelectWrapper
                    flexSize={6}
                    options={contactLanguages}
                    value={props.entity?.primaryContactLang ?? ""}
                    name={`entities[${props.index}].primaryContactLang`}
                    setFieldValue={props.setFieldValue}
                    title="Contact lang"
                    getPopupContainer={() =>
                        document.getElementById("entity-drawer")!!
                    }
                />
                <span className="small_separation_text flex-12">
                    Customer Service
                </span>
                <InputWrapper
                    value={props.entity?.customerServiceEmail}
                    title="Email"
                    required={true}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].customerServiceEmail`}
                    flexSize={6}
                />
                <InputWrapper
                    value={props.entity?.customerServicePhone}
                    title="Phone"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={`entities[${props.index}].customerServicePhone`}
                    flexSize={6}
                />
                <SwitchWrapper
                    title="Require user agreement"
                    setFieldValue={props.setFieldValue}
                    name={`entities[${props.index}].agreementRequired`}
                    checked={props.entity?.agreementRequired ?? false}
                />
            </div>
        </Drawer>
    );
};
