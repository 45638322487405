import React, { ChangeEvent } from "react";
import { useStore } from "../../stores";
import style from "./style.module.scss";
import { Spin, Modal } from "antd";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import * as yup from "yup";

const YupImageUrl = yup
    .object({
        url: yup.string().required(),
    })
    .required();

interface Props {
    url: string | null | undefined;
    className?: string;
    folder: string;
    onChange: (url: string | null) => void;
    onDelete: () => void;
    accept?: string[]
}

export const ImageInput = (props: Props) => {
    const { folder, accept = ["image/png", "image/jpeg"]  } = props;

    const rootStore = useStore();
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);
    const hiddenInputRef = React.useRef<HTMLInputElement | null>(null);

    const onImageSelected = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            // User cancelled the operation
            return;
        }

        const formData = new FormData();
        formData.set("folder", folder);
        formData.set("file", file);

        setIsUploading(true);
        const response = await rootStore.makeNetworkCall(
            {
                url: "images",
                method: "POST",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            },
            YupImageUrl
        );
        setIsUploading(false);

        if (!response.err) {
            props.onChange(response.data.url);
        }
    };

    return (
        <div className={`${style.container} ${props.className ?? ""}`}>
            {props.url ? (
                <div className={style.image_holder}>
                    <div className={style.overlay}>
                        <EyeOutlined
                            className={style.eye_svg}
                            onClick={() => setModalVisible(true)}
                        />
                        <DeleteOutlined
                            className={style.delete_svg}
                            onClick={props.onDelete}
                        />
                    </div>
                    <img src={props.url ?? ""} alt="thumbnail" />
                </div>
            ) : (
                <div
                    className={style.empty_interface}
                    onClick={() => hiddenInputRef.current?.click()}
                >
                    {isUploading ? <Spin /> : <PlusOutlined />}
                </div>
            )}
            <input
                type="file"
                name="image"
                accept={accept.join(', ')}
                className={style.hidden_input}
                ref={hiddenInputRef}
                onChange={onImageSelected}
            />
            <Modal
                visible={isModalVisible}
                title="Image"
                footer={null}
                onCancel={() => setModalVisible(false)}
            >
                <img
                    alt="thumbnail"
                    style={{ width: "100%" }}
                    src={props.url ?? ""}
                />
            </Modal>
        </div>
    );
};
