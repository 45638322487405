import React from "react";
import { observer } from "mobx-react";
import { Typography, Button, Skeleton, message } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
    InputWrapper,
    SelectWrapper,
} from "../../components";
import { useNavigation } from "../../utils";
import { MoveCategoryForm, moveCategoryFormSchema } from "../../forms";
import { useMoveCategoryStore } from "../../stores";
import { transform } from "./transfomer";
import IMAGES_CHOICES from "../../utils/images-choices";

const { Title } = Typography;

const initialValues = {
    titleFr: "",
    titleEn: "",
    order: 0,
    timeline: "",
    name: "",
    image: ""
};

export const MoveCategoryPage = observer(() => {
    const navigation = useNavigation();
    const moveCategoryStore = useMoveCategoryStore();
    const [isFetchingMoveCategory, setFetchingMoveCategory] = React.useState(
        false
    );
    const [isUpserting, setUpserting] = React.useState(false);
    const params = useParams<{ id?: string }>();
    const formik = useFormik<MoveCategoryForm>({
        initialValues,
        validationSchema: moveCategoryFormSchema,
        validateOnMount: true,
        onSubmit: () => {}
    });

    React.useEffect(() => {
        if (params.id) {
            setFetchingMoveCategory(true);
            moveCategoryStore
                .fetchMoveCategory(params.id)
                .then(m => formik.setValues(transform(m)))
                .finally(() => setFetchingMoveCategory(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const editMoveCategory = React.useCallback(() => {
        if (params.id) {
            setUpserting(true);
            moveCategoryStore
                .editMoveCategory(params.id, formik.values)
                .then(() => void message.success("MoveCategory edited"))
                .finally(() => setUpserting(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, formik.values]);

    const createMoveCategory = React.useCallback(() => {
        setUpserting(true);
        moveCategoryStore
            .createMoveCategory(formik.values)
            .then(() => {
                message.success("MoveCategory Created");
                navigation.goToModulesCustom();
            })
            .finally(() => setUpserting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    return (
        <div>
            <Title level={2}>Categories Module</Title>

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingMoveCategory}
                    active={true}
                    paragraph={{ rows: 20 }}
                >
                    <span className="small_separation_text flex-12">
                        Global information
                    </span>

                    <InputWrapper
                        required
                        title="Name"
                        value={formik.values.name}
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <InputWrapper
                        title="Timeline"
                        value={formik.values.timeline ?? 0}
                        name="timeline"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />

                    <SelectWrapper
                        title="Image"
                        value={formik.values.image ?? ""}
                        name="image"
                        flexSize={3}
                        options={IMAGES_CHOICES}
                        setFieldValue={formik.setFieldValue}
                    />
                </Skeleton>
            </div>

            <br />

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingMoveCategory}
                    active={true}
                    paragraph={{ rows: 20 }}
                >
                    <div className="flex-12">
                        <span className="small_separation_text">
                            Public information
                        </span>
                    </div>

                    <InputWrapper
                        required
                        title="Title Fr"
                        value={formik.values.titleFr}
                        name="titleFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                    <InputWrapper
                        required
                        title="Title En"
                        value={formik.values.titleEn}
                        name="titleEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                </Skeleton>
            </div>
            <br />

            {params.id ? (
                <Button
                    onClick={editMoveCategory}
                    type="primary"
                    loading={isUpserting}
                    disabled={!formik.isValid || !formik.dirty || isUpserting}
                >
                    Edit
                </Button>
            ) : (
                <Button
                    onClick={createMoveCategory}
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty}
                >
                    Create
                </Button>
            )}
        </div>
    );
});
