import React from "react";
import {observer} from "mobx-react";
import {useOrganizationStore, useBuyRequestStore, useOrganizationCategoryStore} from "../../stores";
import {LightBuyRequest, OrganizationCategory} from "../../schemas";
import {Table, Pagination, Input} from "antd";
import {ColumnProps} from "antd/es/table";
import {
    usePagination,
    useNavigation,
    createBuyRequestsCsv,
    downloadFile,
} from "../../utils";
import {CsvExporter, SmartTrashIcon} from "../../components";
import {Typography} from "antd";
import style from "./style.module.scss";
import {useLazyEffect} from "../../utils/user-lazy-effect";

const {Title} = Typography;

export const BuyRequestsPage = observer(() => {
    const buyRequestStore = useBuyRequestStore();
    const organizationStore = useOrganizationStore();
    const organizationCategoryStore = useOrganizationCategoryStore();
    const navigation = useNavigation();
    const [params, setParams, setSearch, setAddToUrl] = usePagination();
    const [orgName, setOrgName] = React.useState("");

    //set to true in mounted
    React.useEffect(() => {
        setAddToUrl(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

        useLazyEffect(() => {
        buyRequestStore.fetchList({
            offset: params.offset,
            limit: params.limit,
            search: params.search,
        });

    }, [params], 500)


    React.useEffect(() => {
        organizationStore.fetchSelectionList(orgName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgName]);

    React.useEffect(() => {
        organizationCategoryStore.fetchList({
            offset: 0,
            limit: 999999
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: ColumnProps<LightBuyRequest>[] = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "User Email",
            key: "email",
            render: (_, record) => record.email,
        },
        {
            title: "Organization Name",
            key: "organization_name",
            render: (_, record) => record.name,
        },
        {
            title: "Organization Category",
            key: "organization_category",
            render: (_, record) => {
                let cat = organizationCategoryStore?.organizationCategoryList?.rows?.find((oc: OrganizationCategory) => oc.id === record.categoryId) || null
                if (!cat) {
                    return 'None'
                }
                return cat.titleEn
            }
        },
        {
            title: "Creation Date",
            key: "creation_date",
            render: (_, record) => record.createdAt.format("YYYY-MM-DD HH:mm"),
        },
        {
            title: "",
            key: "remove",
            render: (_, record) => (
                <SmartTrashIcon
                    onConfirm={() =>
                        buyRequestStore.delete(record.id, {
                            offset: params.offset,
                            limit: params.limit,
                            search: params.search,
                        })
                    }
                />
            ),
        },
    ];

    return (
        <div>
            <div className={style.top_content}>
                <div>
                    <Title level={2}>Buy Requests</Title>
                    <Input
                        className="search-input"
                        placeholder="search"
                        value={params.search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <CsvExporter
                    list={organizationStore.selectionList}
                    isListLoading={
                        organizationStore.isFetchingSelectionCount > 0
                    }
                    onSearchChange={(search) => setOrgName(search)}
                    onExportRequest={async (orgId, startDate, endDate) => {
                        const buyRequests = await buyRequestStore.fetchForCsv(
                            orgId,
                            startDate,
                            endDate
                        );
                        downloadFile('buy-requests.csv', createBuyRequestsCsv(buyRequests ?? []));
                    }}
                />
            </div>

            <Table<LightBuyRequest>
                dataSource={buyRequestStore.buyRequestsList?.rows}
                rowKey="id"
                columns={columns}
                loading={buyRequestStore.isFetchingListCount > 0}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => navigation.goToBuyRequest(record.id),
                })}
            />
            <br/>
            <Pagination
                showSizeChanger
                onChange={setParams}
                current={params.page}
                defaultCurrent={1}
                pageSize={params.pageSize}
                total={buyRequestStore.buyRequestsList?.count}
            />
        </div>
    );
});
