import * as yup from "yup";
import { RootStore } from "./root";
import { observable, action } from "mobx";
import { SearchParams, WithNetworkConcurrency } from "../utils";
import { buyRequestSchema, lightBuyRequestSchema } from "../schemas";
import { Moment } from "moment";

const buyRequestListSchema = yup
    .object({
        count: yup.number().required(),
        rows: yup.array().of(lightBuyRequestSchema).defined(),
    })
    .required();

export type BuyRequestList = yup.InferType<typeof buyRequestListSchema>;

export class BuyRequestStore extends WithNetworkConcurrency {
    @observable buyRequestsList: BuyRequestList | null = null;
    @observable isFetchingListCount = 0;

    constructor(private rootStore: RootStore) {
        super();
    }

    @action
    async fetchList(this: BuyRequestStore, params: SearchParams) {
        this.isFetchingListCount++;
        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "buy-requests/paginated",
                params,
            },
            buyRequestListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.buyRequestsList = response.data;
        }
    }

    @action
    async fetchBuyRequest(this: BuyRequestStore, moveRequestId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `buy-requests/${moveRequestId}`,
            },
            buyRequestSchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    fetchForCsv(
        this: BuyRequestStore,
        organizationId: string | undefined,
        from: Moment,
        to: Moment
    ) {
        return this.rootStore.callApiV2({
            url: 'buy-requests/export',
            params: {
                organizationId,
                from: from.toISOString(),
                to: to.toISOString(),
            }
        })
    }

    @action
    async delete(
        this: BuyRequestStore,
        buyRequestId: string,
        params: SearchParams
    ) {
        this.isFetchingListCount++;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `buy-requests/${buyRequestId}`,
        });

        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "buy-requests/paginated",
                params,
            },
            buyRequestListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.buyRequestsList = response.data;
        }
    }
}
