import * as yup from "yup";
import { langSchema, MomentSchema } from "../utils";

export const brokerOrganizationRulesSchema = yup.object({
    id: yup.string().nullable(),
    rule: yup.string().required(),
    organizationCategoryId: yup.string().required(),
    organizationsId: yup.array(yup.string().required()).nullable(),
}).required()

export const brokerInfoSchema = yup.object({
    id: yup.string().required(),
    lang: langSchema.required(),
    agencyName: yup.string().nullable(),
    message: yup.string().required(),
    logoUrl: yup.string().nullable(),
    photoUrl: yup.string().nullable(),
    websiteUrl: yup.string().nullable(),
});

export const longLinksRecords = yup.object({
    fr: yup.string().nullable(),
    en: yup.string().nullable(),
});

export const brokerSchema = yup.object({
    id: yup.string().required(),
    agentId: yup.string().nullable(),
    refererToken: yup.string().nullable(),
    source: yup.string().default(""),
    hiddenModules: yup.array(yup.string().required()),
    customModules: yup.array(yup.string().required()),
    organizationRules: yup.array(brokerOrganizationRulesSchema),
    preferredLang: yup.string().nullable(),
    preferredDisplay: yup.string().nullable(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.string().nullable(),
    facebookUrl: yup.string().nullable(),
    twitterUrl: yup.string().nullable(),
    instagramUrl: yup.string().nullable(),
    youtubeUrl: yup.string().nullable(),
    info: yup.array(brokerInfoSchema.required()).required().min(2).max(2),
    shortLink: yup.string().nullable(),
    longLinks: longLinksRecords.nullable(),
    stats: yup.object({
        nbReferredUsers: yup.number().nullable(),
    }).nullable(),
}).required();

export const lightBrokerSchema = yup.object({
    id: yup.string().required(),
    createdAt: new MomentSchema().required(),
    name: yup.string().nullable(),
    email: yup.string().nullable(),
    refererToken: yup.string().nullable(),
    source: yup.string().required(),
    agentId: yup.string().nullable(),
}).required();

export type BrokerForm = yup.InferType<typeof brokerSchema>;
export type BrokerOrganizationRulesSchema = yup.InferType<typeof brokerOrganizationRulesSchema>;
export type Broker = yup.InferType<typeof brokerSchema>;
export type LightBroker = yup.InferType<typeof lightBrokerSchema>;
