import React from "react";
import moment from "moment";
import { useUserStore } from "../../stores";
import { MoveWithRequests, UserWithMove } from "../../schemas";
import { useParams } from "react-router-dom";
import { LangTag, FieldWrapper, LinkIcon } from "../../components";
import { formatAddress, getOrganizationName, useNavigation } from "../../utils";
import {
    Typography,
    Badge,
    Skeleton,
    List,
    Avatar,
    Tag,
    Drawer,
    Tabs,
} from "antd";
import orderBy from "lodash/orderBy";
import style from "./style.module.scss";

const { Title } = Typography;
const { TabPane } = Tabs;

export const UserPage = () => {
    const userStore = useUserStore();
    const params = useParams<{ id: string }>();
    const navigation = useNavigation();
    const [user, setUser] = React.useState<UserWithMove | null>(null);
    const [isFetchingUser, setFetchingUser] = React.useState(false);
    const [drawerVisible, setDrawerVisible] = React.useState(false);
    const [move, setMove] = React.useState<MoveWithRequests | null>(null);

    React.useEffect(() => {
        setFetchingUser(true);
        userStore
            .fetchUser(params.id)
            .then((u) => setUser(u))
            .finally(() => setFetchingUser(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMoveClick = (selectedMove: MoveWithRequests) => {
        setDrawerVisible(true);
        setMove(selectedMove);
    };

    const onDrawerClose = () => {
        setDrawerVisible(false);
        setMove(null);
    };

    return (
        <div>
            <Drawer
                title="Move"
                placement="right"
                width={512}
                closable={true}
                onClose={onDrawerClose}
                visible={drawerVisible && !!move}
                mask={false}
            >
                {move && (
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            Base info
                        </span>
                        <FieldWrapper
                            flex={12}
                            text={moment(move.date).format("LL")}
                            label="Date"
                        />
                        <FieldWrapper
                            flex={12}
                            text={formatAddress(move, "from")}
                            label="From"
                        />
                        <ul className="flex-12">
                            <li>
                                Level: <b>{move.originResidenceLevel}</b>
                            </li>
                            <li>
                                Type: <b>{move.originResidenceType}</b>
                            </li>
                            <li>
                                Access: <b>{move.originResidenceAccess}</b>
                            </li>
                            <li>
                                Dimension:{" "}
                                <b>{move.originResidenceDimension}</b>
                            </li>
                        </ul>
                        <FieldWrapper
                            flex={12}
                            text={formatAddress(move, "to")}
                            label="To"
                        />
                        <ul className="flex-12">
                            <li>
                                Level: <b>{move.destinationResidenceLevel}</b>
                            </li>
                            <li>
                                Type: <b>{move.destinationResidenceType}</b>
                            </li>
                            <li>
                                Access: <b>{move.destinationResidenceAccess}</b>
                            </li>
                            <li>
                                Dimension:{" "}
                                <b>{move.destinationResidenceDimension}</b>
                            </li>
                        </ul>
                        <div className="flex-12">
                            <Tabs defaultActiveKey="1">
                                <TabPane
                                    tab={`Buy requests (${
                                        move?.buyRequests?.length ?? 0
                                    })`}
                                    key="1"
                                >
                                    {move?.buyRequests?.map((buyRequest) => (
                                        <div
                                            className="flex-wrapper row-odd"
                                            key={buyRequest.id}
                                        >
                                            <span className="flex-1">
                                                <LinkIcon
                                                    onClick={() =>
                                                        navigation.goToBuyRequest(
                                                            buyRequest.id
                                                        )
                                                    }
                                                />
                                            </span>
                                            <FieldWrapper
                                                flex={6}
                                                text={
                                                    getOrganizationName(
                                                        buyRequest.organization
                                                    ) ?? ""
                                                }
                                                label="Organization"
                                            />
                                            <FieldWrapper
                                                flex={5}
                                                text={
                                                    moment(
                                                        buyRequest.createdAt
                                                    ).format("LL") ?? ""
                                                }
                                                label="Created at"
                                            />
                                        </div>
                                    ))}
                                </TabPane>
                                <TabPane
                                    tab={`Move requests (${
                                        move?.requests?.length ?? 0
                                    })`}
                                    key="2"
                                >
                                    {move?.requests?.map((request) => (
                                        <div
                                            className={`flex-wrapper row-odd`}
                                            key={request.id}
                                        >
                                            <span className="flex-1">
                                                <LinkIcon
                                                    onClick={() =>
                                                        navigation.goToMoveRequest(
                                                            request.id
                                                        )
                                                    }
                                                />
                                            </span>
                                            <FieldWrapper
                                                flex={6}
                                                text={
                                                    getOrganizationName(
                                                        request.organization
                                                    ) ?? ""
                                                }
                                                label="Organization"
                                            />
                                            <FieldWrapper
                                                flex={5}
                                                text={
                                                    moment(
                                                        request.createdAt
                                                    ).format("LL") ?? ""
                                                }
                                                label="Created at"
                                            />
                                        </div>
                                    ))}
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                )}
            </Drawer>

            <Skeleton loading={isFetchingUser} active={true} paragraph={false}>
                <Title
                    level={2}
                >{`${user?.firstname} ${user?.lastname}`}</Title>
            </Skeleton>
            <div className="description-wrapper">
                <Skeleton loading={isFetchingUser} active={true}>
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            User Information
                        </span>
                        <FieldWrapper
                            flex={4}
                            label="Id"
                            text={user?.id ?? ""}
                        />
                        <FieldWrapper
                            flex={4}
                            label="Account created"
                            text={user?.createdAt ? moment(user?.createdAt).format("YYYY-MM-DD") : undefined}
                        />
                        <FieldWrapper
                            flex={4}
                            label="RefererToken"
                            text={user?.refererToken}
                        />
                        <FieldWrapper
                            flex={4}
                            label="Birth date"
                            text={user?.birthdate?.format("LL")}
                        />
                        <FieldWrapper
                            flex={4}
                            label="Ownership"
                            text={user?.homeOwnership ?? ""}
                        />
                        <FieldWrapper flex={4} label="Best time to reach">
                            {user?.reachTime
                                ?.filter((time): time is string => !!time)
                                .map((time) => (
                                    <Tag key={time}>{time}</Tag>
                                ))}
                        </FieldWrapper>
                        <span className="small_separation_text flex-12">
                            Contact information
                        </span>
                        <FieldWrapper
                            flex={4}
                            label="Email"
                            text={user?.email}
                        />
                        <FieldWrapper
                            flex={4}
                            label="Phone number"
                            text={
                                user?.phoneCell ||
                                user?.phoneWork ||
                                user?.phoneHome ||
                                ""
                            }
                        />

                        <FieldWrapper flex={4} label="Contact language">
                            <LangTag lang={user?.language} />
                        </FieldWrapper>
                        <span className="small_separation_text flex-12">
                            User agreement information
                        </span>
                        <FieldWrapper flex={4} label="User agreement signed?">
                            <Badge
                                status={user?.signature ? "success" : "error"}
                                text={user?.signature ? "Yes" : "No"}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            flex={4}
                            text={user?.agreementSignedAt?.format("LLL")}
                            label="User agreement signed at"
                        />

                        <FieldWrapper flex={4} label="User agreement">
                            {user?.signature && (
                                <img
                                    className={style.signature}
                                    src={user.signature}
                                    alt=""
                                />
                            )}
                        </FieldWrapper>
                    </div>
                </Skeleton>
            </div>
            <br />
            <div className="description-wrapper">
                <Skeleton loading={isFetchingUser} active={true}>
                    <span className="small_separation_text flex-12">
                        Moves ({user?.moves?.length ?? 0})
                    </span>
                    {orderBy(user?.moves, "createdAt", "desc").map(
                        (moveItem) => (
                            <div
                                className={`flex-wrapper row-even clickable ${
                                    moveItem.id === move?.id ? "selected" : ""
                                }`}
                                key={moveItem.id}
                                onClick={() => onMoveClick(moveItem)}
                            >
                                <FieldWrapper flex={3} label="From">
                                    <span>
                                        {formatAddress(moveItem, "from")}
                                    </span>
                                </FieldWrapper>
                                <FieldWrapper
                                    flex={3}
                                    text={formatAddress(moveItem, "to")}
                                    label="To"
                                />
                                <FieldWrapper
                                    flex={3}
                                    text={moment(moveItem.date).format("LL")}
                                    label="Date"
                                />

                                <FieldWrapper
                                    flex={3}
                                    text={moment(moveItem.updatedAt).format(
                                        "LL"
                                    )}
                                    label="Updated at"
                                />
                            </div>
                        )
                    )}
                </Skeleton>
            </div>
            <br />
            <div className="description-wrapper">
                <Skeleton loading={isFetchingUser} active={true}>
                    <span className="small_separation_text flex-12">
                        Referred users ({user?.referredUsers?.length ?? 0})
                    </span>

                    <List
                        itemLayout="horizontal"
                        dataSource={user?.referredUsers}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                    }
                                    title={
                                        <b>
                                            {item.firstName} {item.lastName}
                                        </b>
                                    }
                                    description={item.email}
                                />
                            </List.Item>
                        )}
                    />
                </Skeleton>
            </div>
            <br />
        </div>
    );
};
