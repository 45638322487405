import Papa from 'papaparse'
import moment from 'moment'

const BOM = '\uFEFF'

export const createMetroCsvExport = (rows: Record<string, any>[]) => {
    const headers = Object.keys(rows[0])
    const csv =  Papa.unparse([
        headers,
        ...rows.map(row => {
            const formattedRow = {...row}
            formattedRow.moveDate = moment.utc(formattedRow.moveDate).format('YYYY-MM-DD')
            return Object.values(formattedRow)
        })
    ])

    return BOM + csv
}
