import React from "react";
import { SelectWrapper, WrapperProps } from "../input-wrapper";
import {useGlobalStore} from "../../stores";

interface Props extends WrapperProps {
    value?: string;
    name: string;
    setFieldValue: (name: string, value: unknown) => void;
    //countries: { value: string; display: string }[];
}

export const CountriesSelect = (props: Props) => {
    const globalStore = useGlobalStore()
    const tValue = props.value ? props.value.split("|") : [];
    const tSetFieldValue = (name: string, value: unknown) => {
        if (value === undefined) {
            props.setFieldValue(props.name, "");
        } else if (Array.isArray(value)) {
            props.setFieldValue(props.name, value.join("|"));
        }
    };

    return (
        <SelectWrapper
            title={props.title}
            disabled={props.disabled}
            required={props.required}
            flexSize={props.flexSize}
            value={tValue}
            name={props.name}
            options={globalStore.countriesSelect}
            mode="multiple"
            setFieldValue={tSetFieldValue}
        />
    );
}
