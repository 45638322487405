import { observable, reaction, action } from "mobx";
import { NotificationManager } from "../components";
import { gql, GraphQLClient } from "graphql-request";

const { REACT_APP_BFF_URL } = process.env;

const graphQLClient = new GraphQLClient(`${REACT_APP_BFF_URL}/graphql`, {
    credentials: "include",
    mode: "cors",
});

const loginQuery = gql`
    mutation($email: Email!, $pass: String!, $locale: Locale) {
        authenticateUser(email: $email, pass: $pass, locale: $locale) {
            userId
        }
    }
`;

const tokenQuery = gql`
    {
        getApiTokenForAdmin {
            token
        }
    }
`;

export class AuthStore {
    @observable isAuthenticating = false;
    @observable jwt = window.localStorage.getItem("jwt");

    constructor() {
        reaction(
            () => this.jwt,
            (jwt) => {
                if (jwt) {
                    window.localStorage.setItem("jwt", jwt);
                } else {
                    window.localStorage.removeItem("jwt");
                }
            }
        );
    }

    @action
    setJwt(jwt: string | null) {
        this.jwt = jwt;
    }

    @action
    async login(email: string, pass: string) {
        if (this.isAuthenticating) {
            return;
        }

        let success = false;

        try {
            this.isAuthenticating = true;
            await graphQLClient.request(loginQuery, {
                email,
                pass,
                locale: "en",
            });
            const {
                getApiTokenForAdmin: { token },
            } = await graphQLClient.request(tokenQuery);
            this.setJwt(token);
            success = true;
        } catch (e) {
            console.log(e)
            NotificationManager.showError("Login failed!");
            this.setJwt(null);
            console.log("This is the login error", e);
        } finally {
            this.isAuthenticating = false;
        }

        return success;
    }
}
