import React from "react";
import { Tag } from "antd";

export const LangTag = (props: { lang?: string | null }) => {
    if (props.lang === "fr") {
        return <Tag color="processing">FR</Tag>;
    } else if (props.lang === "en") {
        return <Tag color="volcano">EN</Tag>;
    } else {
        return <Tag color="default">Not defined</Tag>;
    }
};
