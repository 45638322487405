import React, { useMemo } from "react";
import {observer} from "mobx-react";
import {
    Typography,
    Button,
    Skeleton,
    message,
} from "antd";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import {
    InputWrapper,
    FieldWrapper,
    SelectWrapper,
    ImageInput,
    TextAreaWrapper,
    AffiliateLink,
} from "../../components";
import {useNavigation, sortLocaleContent} from "../../utils";
import styleGlobal from "../style.module.scss";
import {BrokerForm, brokerFormSchema} from "../../forms";
import {useModuleStore, useBrokerStore, useOrganizationStore, useOrganizationCategoryStore} from "../../stores";
import {CountriesSelect} from "../../components/countries-select";
import {CountriesBtns} from "../../components/countries-btns";
import SOURCE_CHOICES from "../../utils/source-choices";
import LANGUAGE_CHOICES from "../../utils/language-choices";
import DISPLAY_CHOICES from "../../utils/display-choices";
import { SOURCE_MOVINGWALDO } from '../../utils/sources.const'
import OrganizationRules from './OrganizationRules'
import BrokerStats from './BrokerStats'

const {Title} = Typography;

const initialValues: BrokerForm = {
    id: undefined,
    agentId: '',
    refererToken: '',
    source: SOURCE_MOVINGWALDO,
    hiddenModules: [],
    customModules: [],
    organizationRules: [],
    preferredLang: 'en',
    preferredDisplay: 'broker',
    firstName: '',
    lastName: '',
    email: '',
    country: 'CA',
    phoneNumber: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    youtubeUrl: '',
    info: [
        {
            id: undefined,
            agencyName: '',
            message:
                'Ce fut un plaisir de travailler avec vous! N\'hésitez pas à contacter les concierges spécialisés en déménagement de MovingWaldo, ils sont vraiment incroyables!',
            lang: "fr",
            logoUrl: "",
            photoUrl: "",
            websiteUrl: "",
        },
        {
            id: undefined,
            agencyName: '',
            message:
                'It was a pleasure working with you! Don\'t hesitate to reach out to MovingWaldo\'s moving concierges, they are truly amazing!',
            lang: 'en',
            logoUrl: '',
            photoUrl: '',
            websiteUrl: '',
        },
    ],
};

export const BrokerPage = observer(() => {
    const navigation = useNavigation();
    const moduleStore = useModuleStore();
    const brokerStore = useBrokerStore();
    const organizationStore = useOrganizationStore();
    const organizationCategoryStore = useOrganizationCategoryStore();
    const organizations = organizationStore.organizationList?.rows
    const organizationCategories = organizationCategoryStore.organizationCategoryList?.rows
    const params = useParams<{ id?: string }>();
    const isEditing = !!params.id
    const [isFetching, setFetching] = React.useState(false);
    const [isUpserting, setUpserting] = React.useState(false);
    const formik = useFormik<BrokerForm>({
        initialValues,
        validationSchema: brokerFormSchema,
        validateOnMount: true,
        onSubmit: () => {
        },
    });

    const indexedOrganizations = useMemo(() => {
        const indexed: Record<string, [string, string][]> = {}
        if(!organizations?.length){
            return {}
        }
        organizations.forEach(({id, name, categoryId}) => {
            if(!categoryId || !name){
                return
            }
            if(!indexed[categoryId]){
                indexed[categoryId] = []
            }
            indexed[categoryId].push([id, name])
        })
        return indexed
    }, [ organizations ])

    const [ stats, setStats ] = React.useState<{} | undefined>()

    React.useEffect(() => {
        moduleStore.fetchList({});
        organizationStore.fetchList({
            offset: 0,
            limit: 999999
        })
        organizationCategoryStore.fetchList({
            offset: 0,
            limit: 999999
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (params.id) {
            setFetching(true);
            brokerStore
                .fetchBroker(params.id)
                .then((broker) => {
                    setStats(broker.stats ?? undefined)

                    void formik.setValues({
                        ...broker,
                        preferredLang: broker.preferredLang ?? ''
                    })
                })
                .finally(() => setFetching(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const create = () => {
        setUpserting(true);
        brokerStore
            .createBroker(formik.values)
            .then(() => {
                message.success("Broker created");
                navigation.goToBrokers();
            })
            .finally(() => setUpserting(false));
    };

    const edit = () => {
        if (params.id) {
            setUpserting(true);
            brokerStore
                .editBroker(params.id, formik.values)
                .then(broker => {
                    const {
                        refererToken,
                        shortLink,
                        longLinks,
                    } = broker as Record<string, any>
                    formik.setFieldValue('refererToken', refererToken)
                    formik.setFieldValue('shortLink', shortLink)
                    formik.setFieldValue('longLinks', longLinks)
                    void message.success("Broker edited")
                })
                .finally(() => setUpserting(false));
        }
    };

    return (
        <div>
            <Title level={2}>Broker</Title>

            <div className={styleGlobal.top_content}>
                <div className={styleGlobal.top_content__row}>
                    <CountriesBtns/>
                </div>
            </div>

            <br />

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    {isEditing && (
                        <FieldWrapper
                            flex={12}
                            label="Id"
                        >
                            <strong>{formik.values.id}</strong>
                        </FieldWrapper>
                    )}

                    <span className="small_separation_text flex-12">
                        Identification
                    </span>

                    <InputWrapper
                        required
                        title="First name"
                        value={formik.values.firstName ?? ''}
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <InputWrapper
                        required
                        title="Last name"
                        value={formik.values.lastName ?? ''}
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <CountriesSelect
                        required
                        title="Countries"
                        value={formik.values.country ?? ''}
                        name="country"
                        setFieldValue={formik.setFieldValue}
                        flexSize={4}
                    />

                    <InputWrapper
                        required
                        title="Email"
                        value={formik.values.email ?? ''}
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <InputWrapper
                        title="Phone number"
                        value={formik.values.phoneNumber ?? ''}
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <SelectWrapper
                        required
                        title="Preferred Language"
                        setFieldValue={formik.setFieldValue}
                        name="preferredLang"
                        value={formik.values.preferredLang ?? ''}
                        options={LANGUAGE_CHOICES}
                        flexSize={4}
                    />

                    <SelectWrapper
                        title="Display preference"
                        setFieldValue={formik.setFieldValue}
                        name="preferredDisplay"
                        value={formik.values.preferredDisplay ?? ''}
                        options={DISPLAY_CHOICES}
                        flexSize={4}
                    />

                    <span className="small_separation_text flex-12">
                        Social link
                    </span>

                    <InputWrapper
                        title="Facebook url"
                        value={formik.values.facebookUrl ?? ''}
                        name="facebookUrl"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <InputWrapper
                        title="Twitter url"
                        value={formik.values.twitterUrl ?? ''}
                        name="twitterUrl"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <InputWrapper
                        title="Youtube url"
                        value={formik.values.youtubeUrl ?? ''}
                        name="youtubeUrl"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <InputWrapper
                        title="Instagram url"
                        value={formik.values.instagramUrl ?? ''}
                        name="instagramUrl"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <span className="small_separation_text flex-12">
                        Source
                    </span>

                    <SelectWrapper
                        title="Source"
                        setFieldValue={formik.setFieldValue}
                        name="source"
                        value={formik.values.source ?? ''}
                        options={SOURCE_CHOICES}
                        flexSize={6}
                        disabled={isEditing}
                    />

                    <InputWrapper
                        required={formik.values.source !== SOURCE_MOVINGWALDO}
                        title="Agent id"
                        value={formik.values.agentId ?? ''}
                        name="agentId"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                        disabled={formik.values.source === SOURCE_MOVINGWALDO}
                    />
                </Skeleton>
            </div>

            <br />

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    <span className="small_separation_text flex-12">
                        Modules
                    </span>

                    <SelectWrapper
                        title="Hidden Modules"
                        isLoading={moduleStore.isFetchingList}
                        options={moduleStore.modulesAsOptions}
                        mode="multiple"
                        value={formik.values.hiddenModules}
                        flexSize={6}
                        name="hiddenModules"
                        setFieldValue={formik.setFieldValue}
                    />

                    <SelectWrapper
                        title="Custom Modules"
                        isLoading={moduleStore.isFetchingList}
                        options={moduleStore.privateModulesAsOptions}
                        mode="multiple"
                        value={formik.values.customModules}
                        flexSize={6}
                        name="customModules"
                        setFieldValue={formik.setFieldValue}
                    />

                    <span className="small_separation_text flex-12">
                        Organizations
                    </span>

                    <OrganizationRules
                        rules={formik.values.organizationRules ?? []}
                        organizations={indexedOrganizations}
                        organizationCategories={organizationCategories}
                        name="organizationRules"
                        setFieldValue={formik.setFieldValue}
                    />

                </Skeleton>
            </div>

            <br />

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    <span className="small_separation_text flex-12">
                        Referral
                    </span>

                    <InputWrapper
                        title="Referer Token"
                        value={formik.values.refererToken ?? ''}
                        name="refererToken"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    { isEditing && (<>
                    
                        <FieldWrapper
                            label="ShortLink"
                            flex={12}
                        >
                            <AffiliateLink link={formik.values?.shortLink} />
                        </FieldWrapper>
                                        
                        <FieldWrapper
                            label="Long link en"
                            flex={12}
                        >
                            <AffiliateLink link={formik.values?.longLinks?.en} />
                        </FieldWrapper>

                        <FieldWrapper
                            label="Long link fr"
                            flex={12}
                        >
                            <AffiliateLink link={formik.values?.longLinks?.fr} />
                        </FieldWrapper>

                        <BrokerStats stats={stats} />
                    
                    </>) }
                    
                </Skeleton>
            </div>

            <br/>

            {sortLocaleContent(formik.values.info).map((info, i) => (
                <React.Fragment key={i}>
                    <div className="description-wrapper flex-wrapper">
                        <Skeleton
                            loading={isFetching}
                            active={true}
                            paragraph={{rows: 16}}
                        >
                            <span className="small_separation_text flex-12">
                                Information {info.lang}
                            </span>

                            <InputWrapper
                                title="Agency name"
                                value={info.agencyName}
                                name={`info[${i}].agencyName`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                flexSize={6}
                            />

                            <TextAreaWrapper
                                required
                                title="Message"
                                value={info.message}
                                name={`info[${i}].message`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                flexSize={6}
                            />

                            <div className="flex-6">
                                <span>Agency Logo</span>
                                <ImageInput
                                    url={info.logoUrl ?? ""}
                                    folder="broker/banner"
                                    onChange={(v) =>
                                        formik.setFieldValue(
                                            `info[${i}].logoUrl`,
                                            v
                                        )
                                    }
                                    onDelete={() =>
                                        formik.setFieldValue(
                                            `info[${i}].logoUrl`,
                                            null
                                        )
                                    }
                                />
                            </div>

                            <div className="flex-6">
                                <span>Broker Photo</span>
                                <ImageInput
                                    url={info.photoUrl ?? ""}
                                    folder="broker/logo"
                                    onChange={(v) =>
                                        formik.setFieldValue(
                                            `info[${i}].photoUrl`,
                                            v
                                        )
                                    }
                                    onDelete={() =>
                                        formik.setFieldValue(
                                            `info[${i}].photoUrl`,
                                            null
                                        )
                                    }
                                />
                            </div>

                            <InputWrapper
                                title="Website url"
                                value={info.websiteUrl ?? ""}
                                name={`info[${i}].websiteUrl`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                flexSize={6}
                            />
                        </Skeleton>
                    </div>

                    <br/>
                </React.Fragment>
            ))}

            {isEditing ? (
                <Button
                    onClick={edit}
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty || isUpserting}
                    loading={isUpserting}
                >
                    Edit
                </Button>
            ) : (
                <Button
                    onClick={create}
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty || isUpserting}
                    loading={isUpserting}
                >
                    Create
                </Button>
            )}
        </div>
    );
});
