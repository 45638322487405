import React, {useCallback} from "react";
import {useFormik} from "formik";
import {observer} from "mobx-react";
import {
    Lang,
    sortLocaleContent,
    useNavigation,
} from "../../utils";
import {useOrganizationStore, useOrganizationCategoryStore} from "../../stores";
import {Typography, Skeleton, Button, Alert} from "antd";
import {EntitiesList, EntityDrawer} from "./entities/entities";
import {BuyOfferList, BuyOfferDrawer} from "./buy-offers";
import produce from "immer";
import toUpper from "lodash/toUpper";
import {
    InputWrapper,
    TextAreaWrapper,
    SelectWrapper,
    RegionsSelect,
    ImageInput,
    QuestionsWrapper,
    SwitchWrapper,
} from "../../components";
import {
    OrganizationForm,
    DescriptionForm,
    RelevantContentForm,
    ConfirmationTextForm,
    EntityForm,
    BuyOfferForm,
} from "./form";
import {useParams} from "react-router-dom";
import {v4 as uuid} from "uuid";
import {generateEntityErrors} from "./entities/errors-map";
import moment from "moment";
import {CountriesSelect} from "../../components/countries-select";

const {Title} = Typography;


const DEFAULT_CATEGORY_HOME_SERVICES: { value: string, display: string }[] = [
    {
        value: 'internet',
        display: 'Internet (Default)'
    },
    {
        value: 'home_phone',
        display: 'Home Phone (Default)'
    },
    {
        value: 'television',
        display: 'Television (Default)'
    },
    {
        value: 'telecom_all',
        display: 'Telecom All (Bell Promo)'
    }
]

const getNewEntity = () => ({
    id: uuid(),
    isNew: true,
    address: null,
    agreementRequired: false,
    contactMethod: "phone",
    country: "CA",
    customerServiceEmail: "",
    customerServicePhone: "",
    nameEn: "",
    nameFr: "",
    contactWebsiteFr: "",
    contactWebsiteEn: "",
    primaryContactEmail: "",
    primaryContactFirstname: "",
    primaryContactJobTitle: "",
    primaryContactLang: "",
    primaryContactLastname: "",
    primaryContactPhone: "",
    secondaryContactEmail: "",
    visible: true,
});

const getNewBuyOffer = () => ({
    id: uuid(),
    isNew: true,
    contactMethod: "",
    contactLanguage: "",
    customMessageEn: "",
    customMessageFr: "",
    email: "",
    emailCC: "",
    filter: "",
    imageMediumUrl: "",
    imageSmallUrl: "",
    longDescriptionEn: "",
    longDescriptionFr: "",
    organizationId: "",
    phoneNumber: "",
    price: 0,
    pricingInfoEn: "",
    pricingInfoFr: "",
    regions: "",
    country: "CA",
    shortDescriptionEn: "",
    shortDescriptionFr: "",
    smartMovingUrl: "",
    tagEn: "",
    tagFr: "",
    titleEn: "",
    titleFr: "",
    trackingId: "",
    types: [],
    urlEn: "",
    urlFr: "",
    zipCodeFile: "",
    zipCodeDestinationFile: "",
    buyOfferSections: [],
    buyOfferExtraInfo: [],
    updatedAt: moment(),
});

const initialValues: OrganizationForm = {
    isAvailable: true,
    isPrivate: false,
    categoryId: null,
    country: "CA",
    favoriteForRegions: "",
    regions: "",
    filter: "",
    logoEn: "",
    logoFr: "",
    web: "",
    questions: [],
    descriptions: [
        {
            descriptionLong: null,
            descriptionShort: "",
            name: "",
            questions: "",
            lang: "fr",
        },
        {
            descriptionLong: null,
            descriptionShort: "",
            name: "",
            questions: "",
            lang: "en",
        },
    ],
    confirmationTexts: [
        {
            value: "",
            lang: "fr",
        },
        {
            value: "",
            lang: "en",
        },
    ],
    relevantContents: [
        {
            title: "",
            url: "",
            imageUrl: "",
            contactNumber: "",
            movingProcedureUrl: "",
            lang: "fr",
        },
        {
            title: "",
            url: "",
            imageUrl: "",
            contactNumber: "",
            movingProcedureUrl: "",
            lang: "en",
        },
    ],
    entities: [],
    buyOffers: [],
};

const checkIfOrganizationHasErrors = (organization: OrganizationForm) => {
    const hasEmptyName = !!organization?.descriptions?.find(({name}) => name === '')
    return !organization.categoryId || hasEmptyName
};

const getBuyOfferErrors = (buyOffers: BuyOfferForm[]) => {
    const buyOffersErrors: Record<string, boolean> = {}
    for(const buyOffer of buyOffers){
        const hasIncompleteExtraInfo = !!buyOffer.buyOfferExtraInfo?.find(extraInfo => {
            return extraInfo.titleEn === ''
                || extraInfo.titleFr === ''
                || extraInfo.descriptionEn === ''
                || extraInfo.descriptionFr === ''
        })
        const hasIncompleteSections = !!buyOffer.buyOfferSections?.find(section => {
            return section.titleEn === ''
                || section.titleFr === ''
                || section.descriptionEn === ''
                || section.descriptionFr === ''
        })
        if(
            hasIncompleteExtraInfo
            || hasIncompleteSections
            || buyOffer.titleEn === ''
            || buyOffer.titleFr === ''
            || buyOffer.contactMethod === ''
            || buyOffer.contactLanguage === ''
        ){
            buyOffersErrors[buyOffer.id] = true
            break;
        }
    }
    return buyOffersErrors
}

const SelectAvailableOptions = [
    {value: "true", display: "Available"},
    {value: "false", display: "Not available"},
];

const mapTranslations = (
    organization: OrganizationForm,
    fn: (
        description: DescriptionForm,
        relevantContent: RelevantContentForm | undefined,
        confirmationText: ConfirmationTextForm | undefined,
        lang: Lang,
        i: number
    ) => React.ReactNode
) => {
    const descriptions = sortLocaleContent(organization.descriptions);
    const relevantContents = sortLocaleContent(organization.relevantContents);
    const confirmationTexts = sortLocaleContent(
        organization.confirmationTexts ?? []
    );

    const reactElements: React.ReactNode[] = [];
    for (let i = 0; i < descriptions.length; i++) {
        const lang = descriptions[i].lang;
        reactElements.push(
            fn(
                descriptions[i],
                relevantContents[i],
                confirmationTexts[i],
                lang,
                i
            )
        );
    }
    return reactElements;
};

export const OrganizationPage = observer(() => {
    const organizationStore = useOrganizationStore();
    const organizationCategoryStore = useOrganizationCategoryStore();
    const params = useParams<{ id?: string }>();
    const navigation = useNavigation();
    const formik = useFormik<OrganizationForm>({
        initialValues,
        onSubmit: () => console.log("submit"),
    });

    const [selectedEntityId, setSelectedEntityId] = React.useState("");
    const [selectedBuyOfferId, setSelectedBuyOfferId] = React.useState("");
    const [entityDrawerOpen, setEntityDrawerOpen] = React.useState(false);
    const [buyOfferDrawerOpen, setBuyOfferDrawerOpen] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initialCategoryId, setInitialCategoryId] = React.useState<string | null | undefined>(null);
    const [isFetchingOrg, setFetchingOrg] = React.useState(false);
    const [categories, setCategories] = React.useState<{ value: string, display: string }[]>([]);
    const [categoryTypes, setCategoryTypes] = React.useState<{ value: string, display: string }[]>([]);

    /*
        For some reason, Yup was getting VERY slow when trying to validate a large number of entities (hundred).
        So this is not the cleanest solution, but has a fallback, we are going to validate the entity manually.
    */
    const entityErrors = generateEntityErrors(formik.values.entities);
    const hasOrganizationErrors = checkIfOrganizationHasErrors(formik.values);
    const buyOffersErrors = getBuyOfferErrors(formik.values.buyOffers ?? []);
    const hasError = entityErrors.hasError() || hasOrganizationErrors || Object.keys(buyOffersErrors).length > 0;

    React.useEffect(() => {
        if (params.id) {
            setFetchingOrg(true);
            organizationStore
                .fetchOne(params.id)
                .then((org: any) => {
                    setInitialCategoryId(org.categoryId)
                    if(!org.relevantContents || org.relevantContents.length === 0){
                        org.relevantContents = (initialValues as any).relevantContents
                    }
                    formik.setValues(org as any)
                })
                .finally(() => setFetchingOrg(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    React.useEffect(() => {
        organizationCategoryStore.fetchList({
            offset: 0,
            limit: 999999
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let categories: { value: string, display: string }[] = []
        let types: { value: string, display: string }[] = []

        if (!organizationCategoryStore.organizationCategoryList?.rows) {
            return
        }

        let categorySelected = organizationCategoryStore.organizationCategoryList.rows.find(c => c.id === formik.values.categoryId) || null


        categories = organizationCategoryStore.organizationCategoryList.rows.map(category => ({
            display: category.titleEn,
            value: category.id
        }));

        if (categorySelected) {
            types = categorySelected.types.map(type => ({
                display: type.titleEn,
                value: type.handle
            }));

            if (categorySelected.handle === 'residential-telecom') {
                types = types.concat(DEFAULT_CATEGORY_HOME_SERVICES)
            }
        }

        setCategories(categories)
        setCategoryTypes(types)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationCategoryStore.organizationCategoryList?.rows, formik.values]);


    /**
     * Methods to close a drawer
     */
    const onEntityDrawerClose = useCallback(() => {
        setEntityDrawerOpen(false);
        setSelectedEntityId("");
    }, []);

    const onBuyOfferDrawerClose = useCallback(() => {
        setBuyOfferDrawerOpen(false);
        setSelectedBuyOfferId("");
    }, []);

    /**
     * Methods to open a drawer
     */
    const onEntitySelect = useCallback((entity: EntityForm) => {
        setSelectedEntityId(entity.id);
        setEntityDrawerOpen(true);
        onBuyOfferDrawerClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBuyOfferSelect = useCallback((buyOffer: BuyOfferForm) => {
        setSelectedBuyOfferId(buyOffer.id);
        setBuyOfferDrawerOpen(true);
        onEntityDrawerClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Method to add things
     */
    const addNewEntity = useCallback(() => {
        formik.setFieldValue(
            "entities",
            produce(formik.values.entities, (draft) => {
                draft?.unshift(getNewEntity());
            })
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.setFieldValue, formik.values.entities]);

    const addNewBuyOffer = useCallback(() => {
        formik.setFieldValue(
            "buyOffers",
            produce(formik.values.buyOffers, (draft) => {
                draft?.unshift(getNewBuyOffer());
            })
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.setFieldValue, formik.values.buyOffers]);

    /**
     * Methods to remove things
     */
    const deleteBuyOffer = (index: number, id: string, isNew?: boolean) => {
        if (!isNew) {
            organizationStore.deleteBuyOffer(id);
        }
        const modifiedList = produce(formik.values.buyOffers, (draft) => {
            draft?.splice(index, 1);
        });
        formik.setFieldValue("buyOffers", modifiedList);
    };

    const deleteEntity = (index: number, id: string, isNew?: boolean) => {
        if (!isNew) {
            organizationStore.deleteEntity(id);
        }
        const modifiedList = produce(formik.values.entities, (draft) => {
            draft?.splice(index, 1);
        });
        formik.setFieldValue("entities", modifiedList);
    };

    const selectedEntityIndex =
        formik.values.entities?.findIndex(
            (entity) => entity.id === selectedEntityId
        ) ?? -1;

    const selectedBuyOfferIndex =
        formik.values.buyOffers?.findIndex(
            (buyOffer) => buyOffer.id === selectedBuyOfferId
        ) ?? -1;

    const patchOrganization = async () => {
        setBuyOfferDrawerOpen(false);
        setEntityDrawerOpen(false);
        await organizationStore.patch(formik.values);
        navigation.goToOrganizations();
    };

    const createOrganization = async () => {
        setBuyOfferDrawerOpen(false);
        setEntityDrawerOpen(false);
        await organizationStore.create(formik.values);
        navigation.goToOrganizations();
    };


    return (
        <div>
            {/* Is available */}
            <SelectWrapper
                value={String(formik.values.isAvailable)}
                defaultValue="true"
                setFieldValue={formik.setFieldValue}
                name="isAvailable"
                options={SelectAvailableOptions}
                isLoading={isFetchingOrg}
                disabled={isFetchingOrg}
            />

            {/* Translations */}
            {mapTranslations(
                formik.values,
                (
                    description,
                    relevantContent,
                    confirmationText,
                    lang,
                    index
                ) => (
                    <React.Fragment key={index}>
                        <div className="description-wrapper flex-wrapper">
                            <Skeleton
                                loading={isFetchingOrg}
                                active={true}
                                paragraph={{rows: 16}}
                            >
                                <Title level={4} className="flex-12">
                                    {toUpper(description.lang)} Translation
                                </Title>

                                <span className="small_separation_text flex-12">
                                    Traduction
                                </span>

                                <InputWrapper
                                    required
                                    title="Name"
                                    value={description.name}
                                    name={`descriptions[${index}].name`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    flexSize={6}
                                />

                                <InputWrapper
                                    title="Description short"
                                    value={description.descriptionShort}
                                    name={`descriptions[${index}].descriptionShort`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    flexSize={6}
                                />

                                <QuestionsWrapper
                                    organizationId={formik.values.id}
                                    questions={formik.values.questions}
                                    name="questions"
                                    onBlur={formik.handleBlur}
                                    setFieldValue={formik.setFieldValue}
                                    lang={lang}
                                />

                                <span className="small_separation_text flex-12">
                                    Confirmation Text
                                </span>

                                <TextAreaWrapper
                                    title="Value"
                                    flexSize={12}
                                    value={confirmationText?.value}
                                    name={`confirmationTexts[${index}].value`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <span className="small_separation_text flex-12">
                                    Relevant content
                                </span>

                                <InputWrapper
                                    title="Url"
                                    value={relevantContent?.url}
                                    name={`relevantContents[${index}].url`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <InputWrapper
                                    title="Title"
                                    value={relevantContent?.title}
                                    name={`relevantContents[${index}].title`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <InputWrapper
                                    title="Image url"
                                    value={relevantContent?.imageUrl}
                                    name={`relevantContents[${index}].imageUrl`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <InputWrapper
                                    title="Contact number"
                                    value={relevantContent?.contactNumber}
                                    name={`relevantContents[${index}].contactNumber`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    flexSize={6}
                                />

                                <InputWrapper
                                    title="Moving procedure url"
                                    value={relevantContent?.movingProcedureUrl}
                                    name={`relevantContents[${index}].movingProcedureUrl`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    flexSize={6}
                                />
                            </Skeleton>
                        </div>
                        <br/>
                        <br/>
                    </React.Fragment>
                )
            )}

            {/* Other Information */}
            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingOrg}
                    active={true}
                    paragraph={{rows: 8}}
                >
                    <Title level={4} className="flex-12">
                        Other information
                    </Title>

                    <InputWrapper
                        title="Website"
                        name="web"
                        value={formik.values.web}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <InputWrapper
                        title="Filter"
                        name="filter"
                        value={formik.values.filter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <div className="flex-4">
                        <SelectWrapper
                            title="Category"
                            name="categoryId"
                            value={formik.values.categoryId ?? ''}
                            setFieldValue={formik.setFieldValue}
                            options={categories}
                            required={true}
                            flexSize={12}
                        />

                        {formik.values.categoryId && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a className="flex-12" onClick={() => {
                                navigation.editOrganizationCategory(formik.values.categoryId)
                            }}>
                                Edit Category
                            </a>
                        )}
                    </div>

                    <CountriesSelect
                        title="Countries"
                        value={formik.values.country ?? ""}
                        name="country"
                        setFieldValue={formik.setFieldValue}
                        flexSize={4}
                    />

                    <RegionsSelect
                        country={formik.values.country}
                        title="Regions"
                        value={formik.values.regions ?? ""}
                        name="regions"
                        setFieldValue={formik.setFieldValue}
                        flexSize={4}
                    />

                    <RegionsSelect
                        country={formik.values.country}
                        title="Favorite for regions"
                        value={formik.values.favoriteForRegions ?? ""}
                        name="favoriteForRegions"
                        setFieldValue={formik.setFieldValue}
                        flexSize={4}
                    />


                    <div className="flex-4">
                        <span>Logo Fr</span>
                        <ImageInput
                            url={formik.values.logoFr ?? ""}
                            folder="organization/logo-fr"
                            onChange={(v) => formik.setFieldValue("logoFr", v)}
                            onDelete={() =>
                                formik.setFieldValue("logoFr", null)
                            }
                        />
                    </div>

                    <div className="flex-4">
                        <span>Logo En</span>
                        <ImageInput
                            url={formik.values.logoEn ?? ""}
                            folder="organization/logo-en"
                            onChange={(v) => formik.setFieldValue("logoEn", v)}
                            onDelete={() =>
                                formik.setFieldValue("logoEn", null)
                            }
                        />
                    </div>
                    <div className="flex-4">
                        <SwitchWrapper
                            title="Is a private organization"
                            checked={formik.values.isPrivate ?? false}
                            setFieldValue={formik.setFieldValue}
                            name="isPrivate"
                            flexSize={12}
                        />
                        {formik.values.isPrivate && (
                            <Alert
                                message="This organization will not be displayed by default"
                                type="warning"
                                showIcon
                            />
                        )}
                    </div>
                </Skeleton>
            </div>

            {/* Entities */}
            <br/>
            <br/>
            <EntityDrawer
                entity={formik.values.entities?.[selectedEntityIndex]}
                index={selectedEntityIndex}
                isOpen={entityDrawerOpen}
                onClose={onEntityDrawerClose}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
            />
            <EntitiesList
                isLoading={isFetchingOrg}
                selectedEntityId={selectedEntityId}
                entities={formik.values.entities ?? []}
                entityErrors={entityErrors}
                onDelete={deleteEntity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onAdd={addNewEntity}
                onEntitySelect={onEntitySelect}
            />

            {/* Buy Offers */}
            <br/>
            <br/>
            <BuyOfferList
                isLoading={isFetchingOrg}
                selectedBuyOfferId={selectedBuyOfferId}
                buyOffers={formik.values.buyOffers ?? []}
                onDelete={deleteBuyOffer}
                onAdd={addNewBuyOffer}
                onBuyOfferSelect={onBuyOfferSelect}
                errors={buyOffersErrors}
            />

            <BuyOfferDrawer
                buyOffer={formik.values.buyOffers?.[selectedBuyOfferIndex]}
                index={selectedBuyOfferIndex}
                isOpen={buyOfferDrawerOpen}
                onClose={onBuyOfferDrawerClose}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                types={categoryTypes}
            />
            <br/>
            <br/>
            {params.id ? (
                <Button
                    type="primary"
                    disabled={hasError}
                    loading={organizationStore.isPatching}
                    onClick={patchOrganization}
                >
                    Update
                </Button>
            ) : (
                <Button
                    type="primary"
                    disabled={hasError}
                    loading={organizationStore.isCreating}
                    onClick={createOrganization}
                >
                    Create
                </Button>
            )}
        </div>
    );
});
