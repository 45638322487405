import React, {useState} from "react";
import {observer} from "mobx-react";
import {useOrganizationCategoryStore} from "../../stores";
import {OrganizationCategory} from "../../schemas";
import {Typography, Button, Row, Col} from "antd";
import {PlusOutlined, EditOutlined} from "@ant-design/icons";
import {usePagination, useNavigation, reorderArr} from "../../utils";
import {SmartTrashIcon} from "../../components";
import style from "../style.module.scss";
import {GROUP_CATEGORIES} from "../../utils";
import cloneDeep from 'lodash/cloneDeep'
import {
    Draggable,
    Droppable,
    DragDropContext,
    DropResult
} from "react-beautiful-dnd";
import {CountriesBtns} from "../../components/countries-btns";

const {Title} = Typography;

export const OrganizationCategoriesPage = observer(() => {
    const organizationCategoryStore = useOrganizationCategoryStore();
    const navigation = useNavigation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setParams, setSearch, setAddToUrl] = usePagination();
    const [organizationCategories, setOrganizationCategories] = useState<any[]>([]);

    //set to true in mounted
    React.useEffect(() => {
        setAddToUrl(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        organizationCategoryStore.fetchList({
            offset: 0,
            limit: 9999999,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    React.useEffect(() => {
        if (organizationCategoryStore?.organizationCategoryList?.rows) {
            setOrganizationCategories(organizationCategoryStore?.organizationCategoryList?.rows)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationCategoryStore?.organizationCategoryList?.rows]);

    const editOrganizationCategoryOrder = React.useCallback((id, newOrder) => {
        organizationCategoryStore.editOrder(id, newOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getGroupCategoryName = React.useCallback((organizationCategory: OrganizationCategory) => {
        if (!organizationCategory.groupHandle) {
            return 'None'
        }
        let group = GROUP_CATEGORIES.find(g => g.value === organizationCategory.groupHandle);
        if (!group) {
            return 'None'
        }
        return group.display
    }, []);

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.source || !result.destination) {
            return;
        }

        let newCategories = organizationCategories.map(t => cloneDeep(t));

        //just a reorder
        newCategories = reorderArr(
            newCategories as any,
            result.source.index,
            result.destination.index
        );

        newCategories = newCategories.map((category, idx) => {
            category.order = idx
            editOrganizationCategoryOrder(category.id, idx)
            return category;
        })

        setOrganizationCategories(newCategories)


    }


    return (
        <div>
            <Title level={2}>Categories</Title>
             <div className={style.top_content}>
                <div className={style.top_content__row}>
                    <CountriesBtns />
                </div>
                 <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    className={style.create_button}
                    onClick={() => navigation.createOrganizationCategory()}
                >
                    Create new
                </Button>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <div className="ant-list-items--header">
                    <Row>
                        <Col span={8} className="">
                            Id
                        </Col>
                        <Col span={4} className="">
                            Name
                        </Col>
                        <Col span={4} className="">
                            Group
                        </Col>
                        <Col span={4} className="">
                            Handle
                        </Col>
                        <Col span={2} className=""/>
                        <Col span={2} className=""/>
                    </Row>
                </div>

                <div className="ant-list-items">

                    <Droppable
                        droppableId="categories"
                        type="organization-category"
                    >
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {organizationCategories.map((organizationCategory: any, idx) => (
                                    <Draggable
                                        key={organizationCategory.id}
                                        draggableId={organizationCategory.id}
                                        index={idx}
                                    >
                                        {(
                                            providedCategory,
                                            snapshotCategory
                                        ) => (
                                            <div
                                                className="ant-list-item"
                                                style={{
                                                    paddingLeft: 30
                                                }}
                                                key={idx}
                                                ref={
                                                    providedCategory.innerRef
                                                }
                                                {...providedCategory.draggableProps}
                                                {...providedCategory.dragHandleProps}
                                            >
                                                <Row>
                                                    <Col
                                                        span={8}
                                                        className=""
                                                    >
                                                        {organizationCategory.id}
                                                    </Col>
                                                    <Col
                                                        span={4}
                                                        className=""
                                                    >
                                                        {organizationCategory.titleEn}
                                                    </Col>
                                                    <Col
                                                        span={4}
                                                        className=""
                                                    >
                                                        {getGroupCategoryName(organizationCategory)}
                                                    </Col>
                                                    <Col
                                                        span={4}
                                                        className=""
                                                    >
                                                        {organizationCategory.handle}
                                                    </Col>
                                                    <Col span={2} className="">
                                                        <EditOutlined
                                                            onClick={() => {
                                                                navigation.editOrganizationCategory(
                                                                    organizationCategory.id
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        span={2}
                                                        className=""
                                                    >
                                                        <SmartTrashIcon
                                                            onConfirm={() => {
                                                                organizationCategoryStore.delete(organizationCategory.id, {
                                                                    offset: 0,
                                                                    limit: 9999999,
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </div>
    );
});
