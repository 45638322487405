import * as yup from "yup";
import {MomentSchema, langSchema} from "../utils";
import {
    moveWithUserSchema,
    organizationSchema,
    questionSchema,
    organizationEntitySchema,
} from "./index";

export const moveRequestSchema = yup
    .object({
        id: yup.string().required(),
        externalId: yup.string().nullable(),
        moveId: yup.string().required(),
        move: moveWithUserSchema,
        organizationId: yup.string().required(),
        organization: organizationSchema,
        organizationEntityId: yup.string().required(),
        organizationEntity: organizationEntitySchema.nullable(),
        questions: yup.array().of(questionSchema),
        requestStatus: yup
            .object({
                id: yup.string().required(),
                requestStatusTranslations: yup.array(
                    yup
                        .object({
                            id: yup.string(),
                            lang: langSchema.required(),
                            status: yup.string(),
                        })
                        .required()
                ),
            })
            .required(),
        createdAt: new MomentSchema().required(),
        updatedAt: new MomentSchema().required(),
        deletedAt: new MomentSchema().nullable(),
    })
    .required();

export const lightMoveRequestSchema = yup.object({
    id: yup.string().required(),
    email: yup.string(),
    name: yup.string(),
    createdAt: new MomentSchema().required(),
    categoryId: yup.string()
}).required();

export type MoveRequest = yup.InferType<typeof moveRequestSchema>;
export type LightMoveRequest = yup.InferType<typeof lightMoveRequestSchema>;
