import React from "react";
import {getQuery} from "./use-query";
import qs from "querystring";
import {useLocation, useHistory} from 'react-router-dom'
import {useGlobalStore} from "../stores";
import {autorun} from 'mobx'

const DEFAULT = {
    page: 1,
    pageSize: 100,
    limit: 100,
    offset: 0,
    search: '',
    searchExtra: {},
}

type UsePaginationArguments = {
    defaultExtra?: Record<string, any>
}

export const usePagination = (argument?:UsePaginationArguments) => {
    const {defaultExtra} = argument ?? {}
    const globalStore = useGlobalStore()
    const location = useLocation();
    const history = useHistory();
    const [addToUrl, setAddToUrl] = React.useState<boolean>(false)

    const [params, setParams] = React.useState<{
        page: number;
        pageSize: number;
        limit: number;
        offset: number;
        search: string;
        searchExtra?: Record<string, any>
    }>({
        page: parseInt(getQuery('page', DEFAULT.page)),
        pageSize: parseInt(getQuery('pageSize', DEFAULT.pageSize)),
        limit: parseInt(getQuery('limit', DEFAULT.limit)),
        offset: parseInt(getQuery('offset', DEFAULT.offset)),
        search: getQuery('search', DEFAULT.search),
        searchExtra: defaultExtra ?? DEFAULT.searchExtra,
    });

    let setDefaultValues = React.useCallback(() => {
        setParams({
            page: DEFAULT.page,
            pageSize: DEFAULT.pageSize,
            limit: DEFAULT.limit,
            offset: DEFAULT.offset,
            search: DEFAULT.search,
            searchExtra: defaultExtra ?? DEFAULT.searchExtra,
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //reset on country change
    React.useEffect(() => autorun(() => {
        setDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [globalStore.countriesSelected])


    //when its reset, reset params
    React.useEffect(() => {
        if (location.search !== '') return
        setDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    //to set data into url
    React.useEffect(() => {
        if (!addToUrl) return
        let diff: Record<string, any> = {}
        Object.keys(params).forEach((key: string) => {
            // @ts-ignore
            if (params[key] !== DEFAULT[key]) {
                // @ts-ignore
                diff[key] = params[key]
            }
        })
        delete diff['searchExtra']
        history.replace(window.location.pathname + '?' + qs.stringify({...diff, ...params.searchExtra ?? {}}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return [
        params,
        (page: number = 1, pageSize: number = 10) => {
            if (page !== params.page || pageSize !== params.pageSize) {
                setParams({
                    page,
                    pageSize,
                    offset: (page - 1) * pageSize,
                    limit: pageSize,
                    search: params.search,
                    searchExtra: params.searchExtra,
                });
            }
        },
        (search: string, searchExtra?: Record<string, any>) => {
            const searchExtrasChanged = JSON.stringify(params.searchExtra) !== JSON.stringify(searchExtra)
            if (search !== params.search || searchExtrasChanged) {
                setParams((p) => ({
                    page: 1,
                    pageSize: p.pageSize,
                    limit: p.pageSize,
                    offset: 0,
                    search: search,
                    searchExtra: searchExtra ?? params.searchExtra,
                }));
            }
        },
        (newValue: boolean) => {
            setAddToUrl(newValue);
        }
    ] as const;
};
