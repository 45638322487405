import React from "react";
import { Modal } from "antd";
import { TrashIcon } from "../trash-icon";

type Props = {
    onConfirm: () => void;
    skipConfirmation?: boolean;
};

export const SmartTrashIcon = (props: Props) => {
    const askForConfirmation = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (props.skipConfirmation) {
            props.onConfirm();
            return;
        }

        Modal.confirm({
            title: "Attention you are about to delete this item",
            content: <div></div>,
            onOk() {
                props.onConfirm();
            },
            onCancel() {},
        });
    };

    return (
        <>
            <TrashIcon onClick={askForConfirmation} />
        </>
    );
};
