import React from "react";
import {observer} from "mobx-react";
import {useOrganizationStore, useOrganizationCategoryStore} from "../../stores";
import {LightOrganization, OrganizationCategory} from "../../schemas";
import {Table, Pagination, Typography, Button, Input, Select, Tag, Tooltip} from "antd";
import {ColumnProps} from "antd/es/table";
import {PlusOutlined, EyeInvisibleFilled} from "@ant-design/icons";
import {usePagination, useNavigation} from "../../utils";
import {SmartTrashIcon} from "../../components";
import style from "../style.module.scss";
import {useLazyEffect} from "../../utils/user-lazy-effect";
import {CountriesBtns} from "../../components/countries-btns";

const {Title} = Typography;
const CATEGORY_FILTER_LOCALSTORAGE_KEY = 'organizations-category-filter'

export const OrganizationsPage = observer(() => {
    const store = useOrganizationStore();
    const organizationCategoryStore = useOrganizationCategoryStore()
    const navigation = useNavigation();
    const [params, setParams, setSearch, setAddToUrl] = usePagination({
        defaultExtra: {
            categoryId: localStorage.getItem(CATEGORY_FILTER_LOCALSTORAGE_KEY)
        }
    });

    const categories = organizationCategoryStore.organizationCategoryList?.rows ?? []
    const categoryOptions = categories.map(({ id, titleEn }) => ({id, label: titleEn}))

    //set to true in mounted
    React.useEffect(() => {
        setAddToUrl(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLazyEffect(() => {
        store.fetchList({
            offset: params.offset,
            limit: params.limit,
            search: params.search,
            categoryId: params.searchExtra?.categoryId,
        });

    }, [params], 500)


    React.useEffect(() => {
        organizationCategoryStore.fetchList({
            offset: 0,
            limit: 999999,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    const columns: ColumnProps<LightOrganization>[] = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Name",
            key: "organization_name",
            render: (_, record) => (
                <>
                    { record.isPrivate && <Tooltip title="This organization is not visible by default"><EyeInvisibleFilled />{' '}</Tooltip> }
                    { record.name }
                </>
),
        },
        {
            title: "Category",
            key: "organization_name",
            render: (_, record) => {
                let cat = organizationCategoryStore?.organizationCategoryList?.rows?.find((oc: OrganizationCategory) => oc.id === record.categoryId) || null
                if (!cat) {
                    return 'None'
                }
                return cat.titleEn
            }
        },
        {
            title: "Available",
            key: "available",
            render: (_, record) =>
                record.isAvailable ? (
                    <Tag color="green">Yes</Tag>
                ) : (
                    <Tag color="volcano">No</Tag>
                ),
        },
        {
            title: "Entities",
            key: "entities",
            render: (_, record) =>
                record.entityCount ? (
                    <Tag color="green">{record.entityCount}</Tag>
                ) : (
                    <Tag color="volcano">0</Tag>
                ),
        },
        {
            title: "Buy Offers",
            key: "buy_offers",
            render: (_, record) =>
                record.buyOfferCount ? (
                    <Tag color="green">{record.buyOfferCount}</Tag>
                ) : (
                    <Tag color="volcano">0</Tag>
                ),
        },
        {
            title: "",
            key: "remove",
            render: (_, record) => (
                <SmartTrashIcon
                    onConfirm={() =>
                        store.delete(record.id, {
                            offset: params.offset,
                            limit: params.limit,
                            search: params.search,
                        })
                    }
                />
            ),
        },
    ];

    const onSearchFilterChange = (query: string, categoryId?: string) => {
        setSearch(query, { categoryId })

        if(categoryId){
            localStorage.setItem(CATEGORY_FILTER_LOCALSTORAGE_KEY, categoryId)
        }else{
            localStorage.removeItem(CATEGORY_FILTER_LOCALSTORAGE_KEY)
        }
    }

    return (
        <div>
            <Title level={2}>Organizations</Title>
            <div className={style.top_content}>
                <div className={style.top_content__row}>
                    <Input
                        className="search-input"
                        placeholder="search"
                        value={params.search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    {categoryOptions.length > 0 && (
                    <Select
                        showSearch
                        className="search-input"
                        value={params.searchExtra?.categoryId ?? ''}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(categoryId: string) => onSearchFilterChange(params.search, categoryId)}
                    >
                        <Select.Option value="">All categories</Select.Option>
                        {categoryOptions.map(({id, label}) => (
                            <Select.Option key={id} value={id}>{label}</Select.Option>
                        ))}
                    </Select>
                    )}
                    <CountriesBtns />
                </div>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    className={style.create_button}
                    onClick={() => navigation.createOrganization()}
                >
                    Create new
                </Button>
            </div>
            <Table<LightOrganization>
                dataSource={store.organizationList?.rows}
                rowKey="id"
                columns={columns}
                loading={store.isFetchingListCount > 0}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => navigation.editOrganization(record.id),
                })}
            />
            <br/>
            <Pagination
                showSizeChanger
                onChange={setParams}
                current={params.page}
                defaultCurrent={1}
                pageSize={params.pageSize}
                total={store.organizationList?.count}
            />
        </div>
    );
});
