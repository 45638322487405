import React from "react";
import { Skeleton, Typography, Button, Tooltip } from "antd";
import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { NewIcon, SmartTrashIcon } from "../../../components";
import { BuyOfferForm } from "../form";
import style from "./style.module.scss";

const { Title } = Typography;

interface BuyOfferListProps {
    onAdd: () => void;
    onDelete: (index: number, id: string, isNew?: boolean) => void;
    onBuyOfferSelect: (entity: BuyOfferForm) => void;
    buyOffers: BuyOfferForm[];
    selectedBuyOfferId?: string;
    isLoading?: boolean;
    errors: Record<string, boolean>
}

export const BuyOfferList = React.memo((props: BuyOfferListProps) => {

    function getRegions(regions:string|null|undefined){
        if (!regions) {
            return regions
        }

        if (regions.length > 15){
            return regions.substr(0, 15) + '...'
        }
        return regions
    }
    return (
        <div className="description-wrapper">
            <Skeleton
                loading={props.isLoading}
                active={true}
                paragraph={{ rows: 8 }}
            >
                <Title level={4} className="flex-12">
                    Buy Offers
                </Title>
                <Button
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    className={style.add_button}
                    type="primary"
                    onClick={() => props.onAdd()}
                    icon={<PlusOutlined />}
                >
                    Add
                </Button>
                <div className={style.buy_offer_row_header}>
                    <span className="flex-4">
                        <b>Name Fr</b>
                    </span>
                    <span className="flex-2">
                        <b>Regions</b>
                    </span>
                    <span className="flex-1">
                        <b>Price</b>
                    </span>
                    <span className="flex-2">
                        <b>Tracking Id</b>
                    </span>
                    <span className="flex-2">
                        <b>Last Updated</b>
                    </span>
                    <span className="flex-1"></span>
                </div>
                {props.buyOffers.map((buyOffer, index) => (
                    <div
                        onClick={() => props.onBuyOfferSelect(buyOffer)}
                        className={`${style.buy_offer_row} ${
                            buyOffer.id === props.selectedBuyOfferId
                                ? style.selected
                                : ""
                        }`}
                        key={buyOffer.id}
                    >
                        <span className="flex-4">
                            {buyOffer.titleEn || "New Buy Offer"}{" "}
                            <NewIcon id={buyOffer.id} isNew={buyOffer.isNew} />
                            {props.errors[buyOffer.id] && (
                                <Tooltip title="This buy offer has error">
                                    <WarningOutlined
                                        className={style.warning_icon}
                                    />
                                </Tooltip>
                            )}
                        </span>
                        <span className="flex-2">{getRegions(buyOffer.regions)}</span>
                        <span className="flex-1">{buyOffer.price}</span>
                        <span className="flex-2">{buyOffer.trackingId}</span>
                        <span className="flex-2">
                            {buyOffer.updatedAt?.format("YYYY-MM-DD HH:mm") ??
                                "-"}
                        </span>
                        <span className={`flex-1 ${style.trash_wrapper}`}>
                            <SmartTrashIcon
                                skipConfirmation={buyOffer.isNew}
                                onConfirm={() => {
                                    props.onDelete(
                                        index,
                                        buyOffer.id,
                                        buyOffer.isNew
                                    );
                                }}
                            />
                        </span>
                    </div>
                ))}
            </Skeleton>
        </div>
    );
});
