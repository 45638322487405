import Papa from 'papaparse'

const BOM = '\uFEFF'

const userFriendlyColumns: Record<string, string> = {
    buyRequestCreatedAt: 'Request date',
    buyRequestId: 'Request ID',
    organizationName: 'Organization',
    buyOfferId: 'Buy offer ID',
    buyOfferDescription: 'Buy offer description',
    userFirstName: 'User firstname',
    userLastName: 'User lastname',
    userLanguage: 'User language',
    userBirthDate: 'User birthdate',
    userEmail: 'User email',
    userPhone: 'User phone',
    moveFromAppt: 'From appt',
    moveFromStreet: 'From street',
    moveFromCity: 'From city',
    moveFromRegion: 'From region',
    moveFromCountry: 'From country',
    moveFromZip: 'From zip',
    moveToAppt: 'To appt',
    moveToStreet: 'To street',
    moveToCity: 'To city',
    moveToRegion: 'To region',
    moveToCountry: 'To country',
    moveToZip: 'To zip',
    moveDate: 'Move date',
    moveFromAddress: 'From address',
    moveToAddress: 'To address',
}

export const createBuyRequestsCsv = (buyRequests: Record<string, any>[]) => {
    if(!Array.isArray(buyRequests) || buyRequests.length === 0){
        return ''
    }
     const csv =  Papa.unparse([
        Object.keys(buyRequests[0]).map((key: string) => userFriendlyColumns[key] ?? key),
        ...buyRequests.map(row => Object.values(row))
    ])
    return BOM + csv
}
