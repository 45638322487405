import { gql } from '@apollo/client'

export const getAllFrontendConfig = gql`
  query GetAllFrontendConfig {
    getAllFrontendConfig {
      name
      type
      value
    }
  }
`
