import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import style from './style.module.scss'

const COPIED_DURATION = 1000

type AffiliateLinkProps = {
  link?: string | undefined | null
}

export const AffiliateLink: React.FC<AffiliateLinkProps> = ({ link }) => {
  const [ displayCopied, setDisplayCopied ] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayCopied(false)
    }, COPIED_DURATION)

    return () => clearTimeout(timeout)
  }, [setDisplayCopied, displayCopied])

  if(!link){
    return null
  }

  const onClick = () => {
    navigator.clipboard.writeText(link)
    setDisplayCopied(true)
  }

  return (
    <>
      <Button
        icon={<CopyOutlined />}
        size='small'
        className={[
          style.copyButton,
          displayCopied && style.success,
        ].join(' ')}
        onClick={onClick}
      >
        {displayCopied ? 'Copied!' : 'Copy'}
      </Button>
      {' '}
      <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
    </>
  )
}
