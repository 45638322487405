import * as yup from "yup";
import { SearchParams, WithNetworkConcurrency } from "../utils";
import { RootStore } from "./root";
import { userSchemaRequired, userWithMovesSchema, User } from "../schemas";
import { observable, action } from "mobx";
import { Moment } from "moment";

type UserList = {
    count: number;
    rows?: User[];
};

export class UserStore extends WithNetworkConcurrency {
    @observable userList: UserList | null = null;
    @observable isFetchingListCount = 0;

    constructor(private rootStore: RootStore) {
        super();
    }

    @action
    async fetchList(this: UserStore, params: SearchParams) {
        const tag = this.getTag();
        this.isFetchingListCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "users/paginated",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(userSchemaRequired).defined(),
            })
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.userList = response.data;
        }
    }

    @action
    async fetchUser(this: UserStore, userId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `users/${userId}/complete`,
            },
            userWithMovesSchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }
    
    fetchForCsv(
        this: UserStore,
        organization: string | undefined,
        from: Moment,
        to: Moment
    ) {
        return this.rootStore.callApiV2({
            url: 'users/export',
            params: {
                organization,
                from: from.toISOString(),
                to: to.toISOString(),
            }
        })
    }

    @action
    async delete(this: UserStore, userId: string, params: SearchParams) {
        this.isFetchingListCount++;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `users/${userId}`,
            params,
        });

        const tag = this.getTag();

        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "users/paginated",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(userSchemaRequired).defined(),
            })
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.userList = response.data;
        }
    }

    @action
    async getMetroExport(this: UserStore) {
        const metroUsers = await this.rootStore.callApiV2({
            url: 'users/metro'
        })
        return metroUsers
    }
}
