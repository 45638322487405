import Papa from 'papaparse'

const BOM = '\uFEFF'

const userFriendlyColumns: Record<string, string> = {
    moveRequestCreatedAt: 'Request date',
    moveRequestId: 'Request ID',
    organizationName: 'Organization',
    organizationEntityId: 'Entity ID',
    organizationEntityName: 'Entity name',
    userFirstName: 'User firstname',
    userLastName: 'User lastname',
    userLanguage: 'User language',
    userBirthDate: 'User birthdate',
    userEmail: 'User email',
    userPhone: 'User phone',
    moveFromAppt: 'From appt',
    moveFromStreet: 'From street',
    moveFromCity: 'From city',
    moveFromRegion: 'From region',
    moveFromCountry: 'From country',
    moveFromZip: 'From zip',
    moveToAppt: 'To appt',
    moveToStreet: 'To street',
    moveToCity: 'To city',
    moveToRegion: 'To region',
    moveToCountry: 'To country',
    moveToZip: 'To zip',
    moveDate: 'Move date',
    moveFromAddress: 'From address',
    moveToAddress: 'To address',
    question1: 'Question 1',
    answer1: 'Answer 1',
    question2: 'Question 2',
    answer2: 'Answer 2',
    question3: 'Question 3',
    answer3: 'Answer 3',
    question4: 'Question 4',
    answer4: 'Answer 4',
}

export const createMoveRequestsCsv = (moveRequests: Record<string, any>[]) => {
    if(!Array.isArray(moveRequests) || moveRequests.length === 0){
        return ''
    }
     const csv =  Papa.unparse([
        Object.keys(moveRequests[0]).map((key: string) => userFriendlyColumns[key] ?? key),
        ...moveRequests.map(row => Object.values(row))
    ])
    return BOM + csv
}
