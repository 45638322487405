import * as yup from "yup";
import { Organization, Move } from "../schemas";

export { Routes } from "./routes";
export { useNavigation } from "./use-navigation";
export { usePagination } from "./use-pagination";
export { MomentSchema } from "./yup-schema";
export { WithNetworkConcurrency } from "./with-network-concurrency";
export { organizationNetworkToForm } from "./converter";
export type Lang = "fr" | "en";
export { createMoveRequestsCsv } from "./csv/move-request-csv-helper";
export { createBuyRequestsCsv } from "./csv/buy-request-csv-helper";
export { createUsersCsv } from "./csv/users-csv-helper";
export { downloadFile } from "./client-side-downloader";

/**
 * Generic Search params type
 */
export interface SearchParams {
    offset?: number;
    limit?: number;
    search?: string;
    categoryId?: string;
}

/**
 * Little helper to format move address
 *
 * @param move
 * @param prefix
 */
export const formatAddress = (move: Move, prefix: "from" | "to") => {
    const apptField = `${prefix}Appt` as "fromAppt" | "toAppt";
    const addressField = `${prefix}Street` as "fromStreet" | "toStreet";
    const cityField = `${prefix}City` as "fromCity" | "toCity";
    const regionField = `${prefix}Region` as "fromRegion" | "toRegion";
    const zipField = `${prefix}Zip` as "fromZip" | "toZip";

    return `${move[addressField] || ""}, ${move[apptField] ?? ""}${
        move[apptField] ? ", " : ""
    }${move[cityField]}, ${move[regionField]}, ${move[zipField]}`;
};

/**
 * List of all the available regions

export const regions = [
    {
        display: "Ontario",
        value: "ON",
    },
    {
        display: "Québec",
        value: "QC",
    },
    {
        display: "British Columbia",
        value: "BC",
    },
    {
        display: "Alberta",
        value: "AB",
    },
    {
        display: "Manitoba",
        value: "MB",
    },
    {
        display: "Newfoundland and Labrador",
        value: "NL",
    },
    {
        display: "Prince Edward Island",
        value: "PE",
    },
    {
        display: "Saskatchewan",
        value: "SK",
    },
    {
        display: "New Brunswick",
        value: "NB",
    },
    {
        display: "Yukon",
        value: "YT",
    },
    {
        display: "Northwest Territories ",
        value: "NT",
    },
    {
        display: "Nunavut",
        value: "NU",
    },
    {
        display: "Nova Scotia",
        value: "NS",
    },
]; */

export const GROUP_CATEGORIES = [
    {
        value: "moving",
        display: "Moving",
    },
    {
        value: "home-services",
        display: "Home Services",
    },
];

export const contactMethods = [
    {
        value: "emailWithPdf",
        display: "Standard email",
    },
    {
        value: "emailWithoutPdf",
        display: "Email without agreement form link",
    },
    {
        value: "fax",
        display: "Send a fax",
    },
    {
        value: "phone",
        display: "Call by phone",
    },
    {
        value: "website",
        display: "Go to website",
    },
];

export const buyOfferContactMethods = [
    { value: "phone", display: "Phone" },
    { value: "email", display: "Email" },
    { value: "smartMoving", display: "Smart Moving" },
    { value: "bellhopApi", display: "Bellhop API" },
    { value: "none", display: "None" },
];

export const contactLanguages = [
    {
        value: "en",
        display: "English",
    },
    {
        value: "fr",
        display: "French",
    },
];

/**
 * The array is sorted in place, but it should not have any impact (I hope)
 *
 * @param content
 */
export const sortLocaleContent = <T extends { lang: Lang }>(content: T[]) => {
    return content.sort((a, b) => (a.lang === "fr" ? -1 : 1));
};

/**
 * Custom yup lang schema
 */
export const langSchema = yup
    .mixed<Lang>()
    .test(
        "valid lang",
        // eslint-disable-next-line no-template-curly-in-string
        "${path} is not a valid lang",
        (v) => typeof v === "string" && !!v.match(/en|fr/)
    );

// Helper method to extract a localized item
export const getItem = <T extends { lang: Lang }>(items: T[], lang: Lang) => {
    return items?.find((i) => i.lang === lang);
};

// Get the prop of a localized item
export const getItemProp = <T extends { lang: Lang }>(
    items: T[] | undefined,
    lang: Lang,
    key: keyof T
) => {
    const item = getItem(items ?? [], lang);
    return item?.[key];
};

/**
 * Helper method to extract the organization description based on the Fr locale
 *
 * @param organization
 */
export const getOrganizationDescription = (
    organization: Organization,
    lang: Lang = "fr"
) => {
    return organization.descriptions?.find((d) => d.lang === lang);
};

/**
 * Helper method to extract the organization description based on the Fr locale
 *
 * @param organization
 */
export const getOrganizationName = (
    organization: Organization,
    lang: Lang = "fr"
) => {
    return getItemProp(organization.descriptions, lang, "name");
};

/**
 * Helper method to extract the question string
 *
 * @param organization
 * @param lang
 */
export const extractOrganizationQuestions = (
    organization: Organization,
    lang: Lang
) => {
    const questions: string[] = [];
    for (const question of organization.questions ?? []) {
        const translation = getItemProp(question.translations, lang, "value");
        if (translation) {
            questions.push(translation);
        }
    }
    return questions.join("|");
};

export const processOrganizationQuestions = (organization: Organization) => {
    return {
        ...organization,
        questions: [
            {
                lang: "fr",
                value: extractOrganizationQuestions(organization, "fr"),
            },
            {
                lang: "en",
                value: extractOrganizationQuestions(organization, "en"),
            },
        ],
    };
};

export const reorderArr = (
    list: any[],
    startIndex: number,
    endIndex: number
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const findMax = <T>(
    array: T[] | undefined,
    predicate: (a: T, b: T) => boolean
) => {
    if (!array || array.length === 0) {
        return undefined;
    }
    return array.reduce((a, b) => (predicate(a, b) ? a : b));
};
