import React from "react";
import {SelectWrapper, WrapperProps} from "../input-wrapper";
import {useGlobalStore} from "../../stores";
import _ from 'lodash'
import {autorun} from "mobx";

interface Props extends WrapperProps {
    value?: string;
    name: string;
    setFieldValue: (name: string, value: unknown) => void;
    country: string | undefined | null;
}


const COUNTRY_SPLIT_VALUE: string = 'COUNTRY-'

export const RegionsSelect = (props: Props) => {
    const globalStore = useGlobalStore()

    const [options, setOptions] = React.useState<{ display: string, value: string }[]>([])

    React.useEffect(() => autorun(() => {
        if (!globalStore.isFetch) {
            return
        }
        let allCountries = globalStore.countries.map((c: any) => c.id)
        let wantedCountries = props.country ? props.country.split('|') : []

        //only get ALL COUNTRY for the selected country
        let countriesOptions = wantedCountries.reduce<{ value: string; display: string }[]>((acc, c) => {
            c = c.toUpperCase()
            let country = globalStore.countriesSelect.find((cS: any) => cS.value === c)
            if (country) {
                acc.push({
                    display: 'ALL - ' + country.display,
                    value: COUNTRY_SPLIT_VALUE + country.value///CA is canada but also California so we need to differ them, so we add COUNTRY-
                })
            }
            return acc
        }, [])

        //if we want all countries
        if (_.isEmpty(_.xor(allCountries, wantedCountries))) {

            setOptions([
                ...globalStore.regionsSelect,
                ...countriesOptions
            ])
        } else {
            let optionsForWantedCountries: { display: string, value: string }[] = []

            if (wantedCountries.length > 0) {
                wantedCountries.forEach((c: string) => {
                    let regions = globalStore.regionsByCountrySelect[c]
                    if (regions) {
                        optionsForWantedCountries = optionsForWantedCountries.concat(regions)
                    }
                })
            } else {
                optionsForWantedCountries = [
                    ...globalStore.regionsSelect
                ]
            }
            setOptions([
                ...optionsForWantedCountries,
                ...countriesOptions
            ])
        }


        //remove regions that are not in the new country if countries is fetch
        if (globalStore.countries.length > 0 && wantedCountries.length > 0) {
            let newValues: string[] = []
            wantedCountries.forEach((country: string) => {
                let regions = props.value ? props.value.split('|') : []
                let regionsForCountry = globalStore.regionsByCountrySelect[country]
                if (!regionsForCountry) return;

                newValues = newValues.concat(regions.filter(region => {
                    //remove all region that are not in the selected country
                    if (!regionsForCountry.some(r => r.value === region)) {
                        return false
                    }

                    return true
                }))
            })

            props.setFieldValue(props.name, newValues.join('|'));
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [props.country, globalStore.isFetch, globalStore.countries])

    const tValue = props.value ? props.value.split("|") : [];
    const tSetFieldValue = (name: string, value: unknown) => {

        let finalValue: string[] = []

        if (value === undefined) {
            props.setFieldValue(props.name, finalValue.join('|'));
            return
        }


        if (Array.isArray(value)) {
            //clone it
            finalValue = [
                ...value
            ]
        } else if (typeof value === 'string') {
            finalValue = value.split('|')
        }

        //if we select countries
        globalStore.countries.forEach((country: any) => {
            let idx = finalValue.findIndex(value => value === COUNTRY_SPLIT_VALUE + country.id)
            if (idx === -1) {
                return
            }

            //remove country in value
            finalValue.splice(idx, 1)


            //add regions for country that we don't have
            globalStore.regionsByCountrySelect[country.id].forEach(region => {
                if (!finalValue.some(v => v === region.value)) {
                    finalValue.push(region.value)
                }
            })

        })


        props.setFieldValue(props.name, finalValue.join('|'));

    };

    return (
        <SelectWrapper
            title={props.title}
            disabled={props.disabled}
            required={props.required}
            flexSize={props.flexSize}
            value={tValue}
            name={props.name}
            options={options}
            mode="multiple"
            setFieldValue={tSetFieldValue}
        />
    );
}
