import * as yup from "yup";
import { MomentSchema } from "../utils";
import {
    moveSchema,
    organizationSchema,
    userSchema,
    buyOfferSchema,
} from "./index";

export const buyRequestSchema = yup
    .object({
        buyOffer: buyOfferSchema,
        buyOfferId: yup.string().required(),
        createdAt: new MomentSchema().nullable(),
        updatedAt: new MomentSchema().nullable(),
        deletedAt: new MomentSchema().nullable(),
        id: yup.string().required(),
        move: moveSchema,
        moveId: yup.string().required(),
        organization: organizationSchema,
        organizationId: yup.string().required(),
        user: userSchema.nullable(),
        userId: yup.string().required(),
    })
    .required();

export const lightBuyRequestSchema = yup
    .object({
        id: yup.string().required(),
        email: yup.string(),
        name: yup.string(),
        categoryId: yup.string(),
        createdAt: new MomentSchema().required(),
    })
    .required();

export type BuyRequest = yup.InferType<typeof buyRequestSchema>;
export type LightBuyRequest = yup.InferType<typeof lightBuyRequestSchema>;
