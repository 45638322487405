import React from "react";
import { LinkOutlined } from "@ant-design/icons";
import style from "./style.module.scss";

interface Props {
    onClick: () => void;
}

export const LinkIcon = (props: Props) => (
    <LinkOutlined className={style.icon} onClick={props.onClick} />
);
