import { gql } from '@apollo/client'

export const getAggregatedRatings = gql`
  query GetAggregatedRatings {
    getAggregatedRatings {
      averageRating
      last30DaysAverageRating
      last30DaysProgress
      last30DaysTotalRating
      totalRatings
      nbRatingsByScore {
        nbRatings
        rating
      }
      averageRatingByMonth {
        averageRating
        month
      }
      last100Ratings {
        id
        createdAt
        user {
          firstname
          id
          lastname
        }
        rating
        comments
      }
    }
  }
`
