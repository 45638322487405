//@ts-ignore
const IMAGES_CHOICES = [
    {
        display: "None",
        value: ""
    },
    {
        display: "Deal",
        value: "deal"
    },
    {
        display: "Home",
        value: "home"
    },
    {
        display: "Move",
        value: "move"
    },
    {
        display: "New Start",
        value: "new-start"
    },
     {
        display: "Deals",
        value: "deals"
    },
     {
        display: "Neighborhood",
        value: "neighborhood"
    },
     {
        display: "Renovation",
        value: "renovation"
    }
];

//@ts-ignore
export default IMAGES_CHOICES;
//@ts-ignore
