import * as yup from "yup";
import {MomentSchema} from "../utils";

export const moduleSchema = yup
    .object({
        id: yup.string().required(),
        titleFr: yup.string().required(),
        titleEn: yup.string().required(),
        anonymousLinkFr: yup.string().nullable(),
        anonymousLinkEn: yup.string().nullable(),
        shortDescriptionFr: yup.string().required(),
        shortDescriptionEn: yup.string().required(),
        longDescriptionFr: yup.string().required(),
        longDescriptionEn: yup.string().required(),
        buttonTextFr: yup.string().required(),
        buttonTextEn: yup.string().required(),
        linkFr: yup.string(),
        linkEn: yup.string(),
        name: yup.string().nullable(),
        isExternalLink: yup.boolean().nullable(),
        isAnonExternalLink: yup.boolean().nullable(),
        linkTarget: yup.string().nullable(),
        showFor: yup.string(),
        isPrivate: yup.boolean().nullable(),
        brokerSourceWhitelisted: yup.string().nullable(),
        brokerSourceBlacklisted: yup.string().nullable(),
        anonLinkTarget: yup.string().nullable(),
        order: yup.number().required().nullable(),
        trackingId: yup.string().nullable(),
        provinceFilter: yup.string().nullable(),
        country: yup.string().required(),
        zipCodeFilter: yup.string().nullable(),
        customType: yup.string().nullable(),
        buttonType: yup.string().nullable(),
        buttonIcon: yup.string().nullable(),
        moveStepId: yup.string().nullable(),
        extraData: yup
            .object({
                featuredBuyOfferId: yup.string(),
            })
            .nullable(),
        createdAt: new MomentSchema().required(),
        updatedAt: new MomentSchema().required(),
        deletedAt: new MomentSchema().nullable(),
    })
    .required();

export type Module = yup.InferType<typeof moduleSchema>;
