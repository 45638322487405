import React from "react";
import { RootStore } from "./root";

const rootStore = new RootStore();
const storeContext = React.createContext<RootStore | null>(null);

interface StoreProviderProps {
    children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
    return (
        <storeContext.Provider value={rootStore}>
            {children}
        </storeContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        throw new Error("useStore must be used within a StoreProvider.");
    }
    return store;
};

export const useMoveRequestStore = () => {
    const store = useStore();
    return store.moveRequestStore;
};

export const useOrganizationStore = () => {
    const store = useStore();
    return store.organizationStore;
};

export const useOrganizationCategoryStore = () => {
    const store = useStore();
    return store.organizationCategoryStore;
};

export const useUserStore = () => {
    const store = useStore();
    return store.userStore;
};

export const useMoveStore = () => {
    const store = useStore();
    return store.moveStore;
};

export const useModuleStore = () => {
    const store = useStore();
    return store.moduleStore;
};
export const useMoveStepStore = () => {
    const store = useStore();
    return store.moveStepStore;
};
export const useMoveCategoryStore = () => {
    const store = useStore();
    return store.moveCategoryStore;
};

export const useBrokerStore = () => {
    const store = useStore();
    return store.brokerStore;
};

export const useBuyRequestStore = () => {
    const store = useStore();
    return store.buyRequestStore;
};

export const useAuthStore = () => {
    const store = useStore();
    return store.authStore;
};

export const useGlobalStore = () => {
    const store = useStore();
    return store.globalStore;
};
