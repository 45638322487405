import React from "react";
import {observer} from "mobx-react";
import {
    useMoveCategoryStore,
    useModuleStore,
    useMoveStepStore
} from "../../stores";
import {MoveCategory, MoveStep} from "../../schemas";
import {
    Button,
    Space,
    Row,
    Col,
    message,
    Divider,
    Collapse,
    Empty,
    Tooltip
} from "antd";
import {PlusOutlined, EditOutlined, CloseOutlined, EyeInvisibleFilled} from "@ant-design/icons";
import {SmartTrashIcon} from "../../components";
import {useNavigation, reorderArr} from "../../utils";
import {Typography} from "antd";
import {cloneDeep, remove} from "lodash";

import {
    Draggable,
    Droppable,
    DragDropContext,
    DropResult
} from "react-beautiful-dnd";
import "./style.scss";
import style from "../style.module.scss";

const {Title} = Typography;

export const ModulesCustomPage = observer(() => {
    const moveCategoryStore = useMoveCategoryStore();
    const moveStepStore = useMoveStepStore();
    const moduleStore = useModuleStore();
    const navigation = useNavigation();

    const [modules, setModules] = React.useState<any[]>([]);
    const [moveCategories, setMoveCategories] = React.useState<any[]>([]);
    const [moveSteps, setMoveSteps] = React.useState<any[]>([]);

    const [otherModules, setOtherModules] = React.useState<any[]>([]);
    const [otherMoveSteps, setOtherMoveSteps] = React.useState<any[]>([]);

    React.useEffect(() => {
        moduleStore.fetchList({
            offset: 0,
            limit: 999999
        });
        moveStepStore.fetchList({
            offset: 0,
            limit: 999999
        });
        moveCategoryStore.fetchList({
            offset: 0,
            limit: 999999
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let newMoveCategories: any[] = [];
        let newMoveSteps: any[] = [];
        let newModules: any[] = [];
        let newOtherMoveSteps: any[] = [];
        let newOtherModules: any[] = [];

        if (
            moveStepStore.moveStepList &&
            moveCategoryStore.moveCategoryList &&
            moduleStore.moduleList
        ) {
            newMoveCategories = moveCategoryStore.moveCategoryList.rows.map(
                (moveCategory: MoveCategory) => {
                    moveCategory.children = [];
                    return moveCategory;
                }
            );

            newMoveSteps = moveStepStore.moveStepList.rows.map(
                (moveStep: MoveStep) => {
                    moveStep.children = [];
                    return moveStep;
                }
            );

            newModules = [...moduleStore.moduleList.rows];

            newModules.forEach((module: any) => {
                let moveStepIdx = newMoveSteps.findIndex(
                    (moveStep: any) => moveStep.id === module.moveStepId
                );
                if (
                    module.moveStepId !== null &&
                    moveStepIdx !== -1 &&
                    newMoveSteps[moveStepIdx]
                ) {
                    newMoveSteps[moveStepIdx].children.splice(
                        module.order,
                        0,
                        module
                    );
                } else {
                    newOtherModules.push(module);
                }
            });

            newMoveSteps.forEach((moveStep: any) => {
                let moveCategoryIdx = newMoveCategories.findIndex(
                    (moveCategory: any) =>
                        moveCategory.id === moveStep.moveCategoryId
                );
                if (
                    moveStep.moveCategoryId !== null &&
                    moveCategoryIdx !== -1 &&
                    typeof newMoveCategories[moveCategoryIdx] !== "undefined"
                ) {
                    newMoveCategories[moveCategoryIdx].children.splice(
                        moveStep.order,
                        0,
                        moveStep
                    );
                } else {
                    newOtherMoveSteps.push(moveStep);
                }
            });

            setOtherMoveSteps(newOtherMoveSteps);
            setOtherModules(newOtherModules);
            setModules(newModules);
            setMoveSteps(newMoveSteps);
            setMoveCategories(newMoveCategories);
        }
    }, [
        moveStepStore.moveStepList,
        moveCategoryStore.moveCategoryList,
        moduleStore.moduleList
    ]);

    const deleteMoveCategory = (id: string) => {
        moveCategoryStore.delete(id, {
            offset: 0,
            limit: 999999
        });
    }
    const deleteMoveStep = (id: string) => {
        moveStepStore.delete(id, {
            offset: 0,
            limit: 999999
        });
    }
    const deleteModule = (id: string) => {
        moduleStore.delete(id, {
            offset: 0,
            limit: 999999
        });
    }

    const save = () => {
        //just loop and see for changes
        let hasChanges = false;
        moveCategories.forEach((moveCategory: any, moveCategoryIdx: number) => {
            let originalMoveCategory = moveCategories.find(
                mc => mc.id === moveCategory.id
            );

            //check for order diff
            if (originalMoveCategory.order !== moveCategoryIdx) {
                moveCategory.order = moveCategoryIdx;
                moveCategoryStore.editMoveCategory(
                    moveCategory.id,
                    moveCategory
                );
                hasChanges = true;
            }

            moveCategory.children.forEach(
                (moveStep: any, moveStepIdx: number) => {
                    let originalMoveStep = moveSteps.find(
                        ms => ms.id === moveStep.id
                    );

                    //if changes, update the move step
                    if (
                        originalMoveStep.moveCategoryId === null ||
                        originalMoveStep.moveCategoryId !==
                        moveStep.moveCategoryId ||
                        originalMoveStep.order !== moveStepIdx
                    ) {
                        moveStep.order = moveStepIdx;
                        moveStepStore.editMoveStep(moveStep.id, moveStep);
                        hasChanges = true;
                    }

                    moveStep.children.forEach(
                        (module: any, moduleIdx: number) => {
                            let originalModule = modules.find(
                                m => m.id === module.id
                            );

                            //if changes, update the module
                            if (
                                originalModule.moveStepId === null ||
                                originalModule.moveStepId !==
                                module.moveStepId ||
                                originalModule.order !== moduleIdx
                            ) {
                                module.order = moduleIdx;
                                moduleStore.editModule(module.id, module);
                                hasChanges = true;
                            }
                        }
                    );
                }
            );
        });

        otherModules.forEach((module: any) => {
            let originalModule = modules.find((m: any) => m.id === module.id)
            module.order = 0
            module.moveStepId = null

            if (originalModule.order !== module.order || originalModule.moveStepId !== null) {
                moduleStore.editModule(module.id, module);

                hasChanges = true
            }
        })

        otherMoveSteps.forEach((moveStep: any) => {
            let originalMoveStep = moveSteps.find((s: any) => s.id === moveStep.id)
            moveStep.children = []
            moveStep.order = 0
            moveStep.moveCategoryId = null

            if (originalMoveStep.order !== moveStep.order || originalMoveStep.moveCategoryId !== null) {
                moveStepStore.editMoveStep(moveStep.id, moveStep);
                hasChanges = true
            }
        })

        if (hasChanges) {
            message.success("Move Categories saved");
        } else {
            message.info("No changes found");
        }
    };

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }


        if (result.type === "move-category") {
            let newMoveCategories = moveCategories.map(mc => cloneDeep(mc));

            //just a reorder
            newMoveCategories = reorderArr(
                newMoveCategories,
                result.source.index,
                result.destination.index
            );
            setMoveCategories(newMoveCategories);
        } else if (result.type === "move-step") {
            let moveStepId = result.draggableId;
            let moveCategoryId = result.destination.droppableId;
            let oldMoveCategoryId = result.source.droppableId;

            //cancel if item is drag into himself
            if (moveCategoryId === "steps-others-drop" || moveCategoryId === "modules-others-drop") {
                return
            }

            let moveStep = cloneDeep(
                moveSteps.find(ms => ms.id === moveStepId)
            );

            //set new values
            moveStep.moveCategoryId = moveCategoryId;
            moveStep.children = []//empty modules list

            //remove it from the "other list"
            if (oldMoveCategoryId === "steps-others-drop") {
                let newOtherMoveSteps = otherMoveSteps.filter(
                    ms => ms.id !== moveStepId
                );
                setOtherMoveSteps(newOtherMoveSteps);
            }

            let newMoveCategoryIdx = moveCategories.findIndex(
                mc => mc.id === moveCategoryId
            );

            let newMoveCategories = moveCategories.map(mc => cloneDeep(mc));

            //just a reorder
            if (moveCategoryId === oldMoveCategoryId) {
                newMoveCategories[newMoveCategoryIdx].children = reorderArr(
                    newMoveCategories[newMoveCategoryIdx].children,
                    result.source.index,
                    result.destination.index
                );
            } else {
                //add new one
                //add the moveStep at the specific index
                newMoveCategories[newMoveCategoryIdx].children.splice(
                    result.destination.index,
                    0,
                    moveStep
                );

                if (oldMoveCategoryId !== "steps-others-drop") {
                    let oldMoveCategoryIdx = moveCategories.findIndex(
                        mc => mc.id === oldMoveCategoryId
                    );
                    remove(
                        newMoveCategories[oldMoveCategoryIdx].children,
                        (ms: any) => ms.id === moveStepId
                    );
                }
            }

            setMoveCategories(newMoveCategories);
        } else if (result.type === "module") {
            let moduleId = result.draggableId;
            let moveStepId = result.destination.droppableId;
            let oldMoveStepId = result.source.droppableId;

            //cancel if item is drag into himself
            if (moveStepId === "modules-others-drop" || moveStepId === "steps-others-drop") {
                return
            }

            let module = cloneDeep(modules.find(m => m.id === moduleId));

            //set in values
            module.moveStepId = moveStepId;

            //remove it from the "other list"
            if (oldMoveStepId === "modules-others-drop") {
                let newOtherModules = otherModules.filter(
                    ms => ms.id !== moduleId
                );
                setOtherModules(newOtherModules);
            }

            let moveCategoryForMoveStepIdIdx = moveCategories.findIndex(mc => {
                return (
                    mc.children.find((ms: any) => ms.id === moveStepId) !==
                    undefined
                );
            });

            let moveStepIdx = moveCategories[
                moveCategoryForMoveStepIdIdx
                ].children.findIndex((ms: any) => ms.id === moveStepId);

            let newMoveCategories = moveCategories.map(mc => cloneDeep(mc));

            //just a reorder
            if (moveStepId === oldMoveStepId) {
                newMoveCategories[moveCategoryForMoveStepIdIdx].children[
                    moveStepIdx
                    ].children = reorderArr(
                    newMoveCategories[moveCategoryForMoveStepIdIdx].children[
                        moveStepIdx
                        ].children,
                    result.source.index,
                    result.destination.index
                );
            } else {
                //add new one
                //add the module at the specific index
                newMoveCategories[moveCategoryForMoveStepIdIdx].children[
                    moveStepIdx
                    ].children.splice(result.destination.index, 0, module);

                //remove old one from the old move step
                if (oldMoveStepId !== "modules-others-drop") {
                    let oldMoveCategoryForMoveStepIdIdx = moveCategories.findIndex(
                        mc => {
                            return (
                                mc.children.find(
                                    (ms: any) => ms.id === oldMoveStepId
                                ) !== undefined
                            );
                        }
                    );
                    let oldMoveStepIdx = moveCategories[
                        oldMoveCategoryForMoveStepIdIdx
                        ].children.findIndex((ms: any) => ms.id === oldMoveStepId);
                    remove(
                        newMoveCategories[oldMoveCategoryForMoveStepIdIdx]
                            .children[oldMoveStepIdx].children,
                        (m: any) => m.id === moduleId
                    );
                }
            }

            //set to state
            setMoveCategories(newMoveCategories);
        }
    };


    const removeModule = (moveCategoryIdx: any, stepIdx: any, moduleIdx: any) => {
        let newMoveCategories = moveCategories.map(mc => cloneDeep(mc));

        let module = cloneDeep(newMoveCategories[moveCategoryIdx].children[stepIdx].children[moduleIdx])
        let moduleId = module.id
        let originalModule = cloneDeep(modules.find(m => m.id === moduleId))
        originalModule.children = []//need to add

        //remove the move category
        newMoveCategories[moveCategoryIdx].children[stepIdx].children.splice(moduleIdx, 1);
        setMoveCategories(newMoveCategories);

        //remove module
        let newOtherModules = otherModules.map(m => cloneDeep(m));
        originalModule.moveStepId = null
        originalModule.order = 0
        newOtherModules.push(originalModule)
        setOtherModules(newOtherModules);

        // IF we want to save
        //moduleStore.editModule(originalModule.id, originalModule);


    }

    const removeStep = (moveCategoryIdx: any, stepIdx: any) => {
        let newMoveCategories = moveCategories.map(mc => cloneDeep(mc));

        let moveStep = cloneDeep(newMoveCategories[moveCategoryIdx].children[stepIdx])
        let moveStepId = moveStep.id
        let originalMoveStep = cloneDeep(moveSteps.find(ms => ms.id === moveStepId))

        //remove the move step
        newMoveCategories[moveCategoryIdx].children.splice(stepIdx, 1);
        setMoveCategories(newMoveCategories);

        //remove module
        let newOtherModules = otherModules.map(m => cloneDeep(m));
        moveStep.children.forEach((module: any) => {
            let originalModule = cloneDeep(modules.find(m => m.id === module.id))
            originalModule.moveStepId = null
            originalModule.order = 0
            // IF we want to save
            // moduleStore.editModule(originalModule.id, originalModule);
            newOtherModules.push(originalModule)
        })
        setOtherModules(newOtherModules);

        //remove move step
        let newOtherMoveSteps = otherMoveSteps.map(m => cloneDeep(m));
        delete originalMoveStep.children//reset children
        originalMoveStep.moveCategoryId = null
        originalMoveStep.order = 0
        newOtherMoveSteps.push(originalMoveStep)
        setOtherMoveSteps(newOtherMoveSteps);

        // IF we want to save
        // moveStepStore.editMoveStep(originalMoveStep.id, originalMoveStep);
    }


    return (
        <div>
            <div>
                <Title level={2}>Modules Categories</Title>
            </div>
            <Row justify="space-between">
                <Col>
                    <Space>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            className={style.create_button}
                            onClick={() => {
                                navigation.createMoveCategory();
                            }}
                        >
                            Create new category
                        </Button>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            className={style.create_button}
                            onClick={() => {
                                navigation.createMoveStep();
                            }}
                        >
                            Create new step
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        className={style.create_button}
                        onClick={() => {
                            save();
                        }}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
            <div>
                <div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="ant-list-items--header">
                            <Row>
                                <Col span={8} className="">
                                    Id
                                </Col>
                                <Col span={6} className="">
                                    Name
                                </Col>
                                <Col span={6} className="">
                                    Title
                                </Col>
                                <Col span={4} className=""/>
                            </Row>
                        </div>

                        <div className="ant-list-items">
                            <Droppable
                                droppableId="move-categories"
                                type="move-category"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        style={{paddingBottom: 60}}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {moveCategories &&
                                        moveCategories.map(
                                            (moveCategory, moveCategoryIdx) => (
                                                <Draggable
                                                    key={moveCategory.id}
                                                    draggableId={
                                                        moveCategory.id
                                                    }
                                                    index={moveCategoryIdx}
                                                >
                                                    {(
                                                        providedMoveCategory,
                                                        snapshotMoveCategory
                                                    ) => (
                                                        <div
                                                            className="ant-list-item"
                                                            style={{
                                                                paddingLeft: 30
                                                            }}
                                                            ref={
                                                                providedMoveCategory.innerRef
                                                            }
                                                            {...providedMoveCategory.draggableProps}
                                                            {...providedMoveCategory.dragHandleProps}
                                                        >
                                                            <Row>
                                                                <Col
                                                                    span={8}
                                                                    className=""
                                                                >
                                                                    {
                                                                        moveCategory.id
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={6}
                                                                    className=""
                                                                >
                                                                    {
                                                                        moveCategory.name
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={6}
                                                                    className=""
                                                                >
                                                                    {
                                                                        moveCategory.titleFr
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={1}
                                                                    className=""
                                                                >
                                                                    <EditOutlined
                                                                        onClick={() => {
                                                                            navigation.editMoveCategory(
                                                                                moveCategory.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    span={1}
                                                                    className=""
                                                                >
                                                                    <SmartTrashIcon
                                                                        onConfirm={() => {
                                                                            deleteMoveCategory(moveCategory.id)
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Divider/>
                                                            <Row className="">
                                                                <Col
                                                                    span={
                                                                        24
                                                                    }
                                                                >
                                                                    <Collapse>
                                                                        <Collapse.Panel
                                                                            header="Steps"
                                                                            key="1"
                                                                        >
                                                                            <Droppable
                                                                                droppableId={
                                                                                    moveCategory.id
                                                                                }
                                                                                type="move-step"
                                                                            >
                                                                                {(
                                                                                    provided,
                                                                                    snapshot
                                                                                ) => (
                                                                                    <div
                                                                                        style={{
                                                                                            paddingBottom: 60
                                                                                        }}
                                                                                        {...provided.droppableProps}
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                    >
                                                                                        {moveCategory
                                                                                            .children
                                                                                            .length ===
                                                                                        0 && (
                                                                                            <Empty
                                                                                                image={
                                                                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {moveCategory &&
                                                                                        moveCategory.children.map(
                                                                                            (
                                                                                                moveStep: any,
                                                                                                moveStepIdx: number
                                                                                            ) => (
                                                                                                <Draggable
                                                                                                    key={
                                                                                                        moveStep.id
                                                                                                    }
                                                                                                    draggableId={
                                                                                                        moveStep.id
                                                                                                    }
                                                                                                    index={
                                                                                                        moveStepIdx
                                                                                                    }
                                                                                                >
                                                                                                    {(
                                                                                                        providedMoveStep,
                                                                                                        snapshotMoveStep
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="ant-list-item"
                                                                                                            style={{
                                                                                                                paddingLeft: 30
                                                                                                            }}
                                                                                                            ref={
                                                                                                                providedMoveStep.innerRef
                                                                                                            }
                                                                                                            {...providedMoveStep.draggableProps}
                                                                                                            {...providedMoveStep.dragHandleProps}
                                                                                                        >
                                                                                                            <Row>
                                                                                                                <Col
                                                                                                                    span={
                                                                                                                        8
                                                                                                                    }
                                                                                                                    className=""
                                                                                                                >
                                                                                                                    {
                                                                                                                        moveStep.id
                                                                                                                    }
                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    span={
                                                                                                                        6
                                                                                                                    }
                                                                                                                    className=""
                                                                                                                >
                                                                                                                    {
                                                                                                                        moveStep.name
                                                                                                                    }
                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    span={
                                                                                                                        6
                                                                                                                    }
                                                                                                                    className=""
                                                                                                                >
                                                                                                                    {
                                                                                                                        moveStep.titleFr
                                                                                                                    }
                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    span={1}
                                                                                                                    className=""
                                                                                                                >
                                                                                                                    <CloseOutlined
                                                                                                                        onClick={() => {
                                                                                                                            removeStep(moveCategoryIdx, moveStepIdx)
                                                                                                                        }}
                                                                                                                    />

                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    span={1}
                                                                                                                    className=""
                                                                                                                >
                                                                                                                    <EditOutlined
                                                                                                                        onClick={() => {
                                                                                                                            navigation.editMoveStep(
                                                                                                                                moveStep.id
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    span={2}
                                                                                                                    className=""
                                                                                                                >
                                                                                                                    <SmartTrashIcon
                                                                                                                        onConfirm={() => {
                                                                                                                            deleteMoveStep(moveStep.id)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <Divider/>
                                                                                                            <Row
                                                                                                                className="">
                                                                                                                <Col
                                                                                                                    span={
                                                                                                                        24
                                                                                                                    }
                                                                                                                >
                                                                                                                    <Collapse>
                                                                                                                        <Collapse.Panel
                                                                                                                            header="Modules"
                                                                                                                            key="1"
                                                                                                                        >
                                                                                                                            <Droppable
                                                                                                                                droppableId={
                                                                                                                                    moveStep.id
                                                                                                                                }
                                                                                                                                type="module"
                                                                                                                            >
                                                                                                                                {(
                                                                                                                                    provided,
                                                                                                                                    snapshot
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            paddingBottom: 60
                                                                                                                                        }}
                                                                                                                                        {...provided.droppableProps}
                                                                                                                                        ref={
                                                                                                                                            provided.innerRef
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {moveStep
                                                                                                                                            .children
                                                                                                                                            .length ===
                                                                                                                                        0 && (
                                                                                                                                            <Empty
                                                                                                                                                image={
                                                                                                                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                        {moveStep &&
                                                                                                                                        moveStep.children.map(
                                                                                                                                            (
                                                                                                                                                module: any,
                                                                                                                                                idx: number
                                                                                                                                            ) => (
                                                                                                                                                <Draggable
                                                                                                                                                    key={
                                                                                                                                                        module.id
                                                                                                                                                    }
                                                                                                                                                    draggableId={
                                                                                                                                                        module.id
                                                                                                                                                    }
                                                                                                                                                    index={
                                                                                                                                                        idx
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {(
                                                                                                                                                        provided,
                                                                                                                                                        snapshot
                                                                                                                                                    ) => (
                                                                                                                                                        <div
                                                                                                                                                            className="ant-list-item"
                                                                                                                                                            style={{
                                                                                                                                                                paddingLeft: 30
                                                                                                                                                            }}
                                                                                                                                                            ref={
                                                                                                                                                                provided.innerRef
                                                                                                                                                            }
                                                                                                                                                            {...provided.draggableProps}
                                                                                                                                                            {...provided.dragHandleProps}
                                                                                                                                                        >
                                                                                                                                                            <Row>
                                                                                                                                                                <Col
                                                                                                                                                                    span={
                                                                                                                                                                        8
                                                                                                                                                                    }
                                                                                                                                                                    className=""
                                                                                                                                                                >
                                                                                                                                                                    {
                                                                                                                                                                        module.id
                                                                                                                                                                    }
                                                                                                                                                                </Col>
                                                                                                                                                                <Col
                                                                                                                                                                    span={
                                                                                                                                                                        6
                                                                                                                                                                    }
                                                                                                                                                                    className=""
                                                                                                                                                                >
                                                                                                                                                                    { module.isPrivate && <Tooltip title="This module is not visible by default"><EyeInvisibleFilled />{' '}</Tooltip> }
                                                                                                                                                                    {
                                                                                                                                                                        module.name
                                                                                                                                                                    }
                                                                                                                                                                </Col>
                                                                                                                                                                <Col
                                                                                                                                                                    span={
                                                                                                                                                                        6
                                                                                                                                                                    }
                                                                                                                                                                    className=""
                                                                                                                                                                >
                                                                                                                                                                    {
                                                                                                                                                                        module.titleFr
                                                                                                                                                                    }
                                                                                                                                                                </Col>
                                                                                                                                                                <Col
                                                                                                                                                                    span={
                                                                                                                                                                        1
                                                                                                                                                                    }
                                                                                                                                                                    className=""
                                                                                                                                                                >

                                                                                                                                                                    <CloseOutlined
                                                                                                                                                                        onClick={() => {
                                                                                                                                                                            removeModule(moveCategoryIdx, moveStepIdx, idx)
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                </Col>
                                                                                                                                                                <Col
                                                                                                                                                                    span={
                                                                                                                                                                        1
                                                                                                                                                                    }
                                                                                                                                                                    className=""
                                                                                                                                                                >

                                                                                                                                                                    <EditOutlined
                                                                                                                                                                        onClick={() => {
                                                                                                                                                                            navigation.editModule(
                                                                                                                                                                                module.id
                                                                                                                                                                            );
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                </Col>
                                                                                                                                                                <Col
                                                                                                                                                                    span={
                                                                                                                                                                        2
                                                                                                                                                                    }
                                                                                                                                                                    className=""
                                                                                                                                                                >
                                                                                                                                                                    <SmartTrashIcon
                                                                                                                                                                        onConfirm={() => {
                                                                                                                                                                            deleteModule(module.id)
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                </Col>
                                                                                                                                                            </Row>
                                                                                                                                                        </div>
                                                                                                                                                    )}
                                                                                                                                                </Draggable>
                                                                                                                                            )
                                                                                                                                        )}
                                                                                                                                        {
                                                                                                                                            provided.placeholder
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </Droppable>
                                                                                                                        </Collapse.Panel>
                                                                                                                    </Collapse>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Draggable>
                                                                                            )
                                                                                        )}
                                                                                        {
                                                                                            provided.placeholder
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </Droppable>
                                                                        </Collapse.Panel>
                                                                    </Collapse>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>


                        <div style={{marginBottom: 30}} className="ant-list-items">
                            <h3 style={{marginBottom: 30}}>Others</h3>
                            <Droppable
                                droppableId={`steps-others-drop`}
                                type="move-step"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        style={{minHeight: 50}}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {otherMoveSteps &&
                                        otherMoveSteps.map(
                                            (moveStep, idx) => (
                                                <Draggable
                                                    key={moveStep.id}
                                                    draggableId={
                                                        moveStep.id
                                                    }
                                                    index={idx}
                                                >
                                                    {(
                                                        provided,
                                                        snapshot
                                                    ) => (
                                                        <div
                                                            className="ant-list-item"
                                                            style={{
                                                                paddingLeft: 30
                                                            }}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Row>
                                                                <Col
                                                                    span={
                                                                        4
                                                                    }
                                                                    className=""
                                                                >
                                                                    Move Step
                                                                </Col>
                                                                <Col
                                                                    span={
                                                                        4
                                                                    }
                                                                    className=""
                                                                >
                                                                    {
                                                                        moveStep.id
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={
                                                                        6
                                                                    }
                                                                    className=""
                                                                >
                                                                    {
                                                                        moveStep.name
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={
                                                                        6
                                                                    }
                                                                    className=""
                                                                >
                                                                    {
                                                                        moveStep.titleFr
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={2}
                                                                    className=""
                                                                >
                                                                    <EditOutlined
                                                                        onClick={() => {
                                                                            navigation.editMoveStep(
                                                                                moveStep.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    span={2}
                                                                    className=""
                                                                >
                                                                    <SmartTrashIcon
                                                                        onConfirm={() => {
                                                                            deleteMoveStep(moveStep.id)
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable
                                droppableId={`modules-others-drop`}
                                type="module"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {otherModules &&
                                        otherModules.map(
                                            (module, idx) => (
                                                <Draggable
                                                    key={module.id}
                                                    draggableId={
                                                        module.id
                                                    }
                                                    index={idx}
                                                >
                                                    {(
                                                        provided,
                                                        snapshot
                                                    ) => (
                                                        <div
                                                            className="ant-list-item"
                                                            style={{
                                                                paddingLeft: 60
                                                            }}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Row>
                                                                <Col
                                                                    span={
                                                                        4
                                                                    }
                                                                    className=""
                                                                >
                                                                    Module
                                                                </Col>
                                                                <Col
                                                                    span={
                                                                        4
                                                                    }
                                                                    className=""
                                                                >
                                                                    {
                                                                        module.id
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={
                                                                        6
                                                                    }
                                                                    className=""
                                                                >
                                                                    {
                                                                        module.name
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={
                                                                        6
                                                                    }
                                                                    className=""
                                                                >
                                                                    {
                                                                        module.titleFr
                                                                    }
                                                                </Col>
                                                                <Col
                                                                    span={2}
                                                                    className=""
                                                                >
                                                                    <EditOutlined
                                                                        onClick={() => {
                                                                            navigation.editModule(
                                                                                module.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    span={2}
                                                                    className=""
                                                                >
                                                                    <SmartTrashIcon
                                                                        onConfirm={() => {
                                                                            deleteModule(module.id)
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
});
