import React from "react";
import {SelectWrapper, WrapperProps} from "../input-wrapper";

interface Props extends WrapperProps {
    value?: string[];
    name: string;
    setFieldValue: (name: string, value: unknown) => void;
    types: { value: string, display: string }[] | [];
}

export const TypesSelect = React.memo((props: Props) => {
    const tSetFieldValue = (name: string, value: unknown) => {
        if (value === undefined) {
            props.setFieldValue(props.name, []);
        } else if (Array.isArray(value)) {
            props.setFieldValue(props.name, value);
        }
    };

    return (
        <SelectWrapper
            title={props.title}
            disabled={props.disabled}
            required={props.required}
            flexSize={props.flexSize}
            value={props.value}
            name={props.name}
            options={props.types}
            mode="tags"
            setFieldValue={tSetFieldValue}
        />
    );
});
