import * as yup from "yup";
import {SearchParams} from "../utils";
import {RootStore} from "./root";
import {moveCategorySchema, MoveCategory} from "../schemas";
import {observable, action} from "mobx";
import {MoveCategoryForm} from "../forms";


type MoveCategoriesList = {
    count: number;
    rows: MoveCategory[];
};

export class MoveCategoryStore {
    @observable moveCategoryList: MoveCategoriesList | null = null;
    @observable isFetchingList = false;

    constructor(private rootStore: RootStore) {
    }

    @action
    async fetchList(this: MoveCategoryStore, params: SearchParams) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "move-categories?all=1",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(moveCategorySchema).defined(),
            })
        );
        this.isFetchingList = false;

        if (response.data) {
            this.moveCategoryList = response.data;
        }
    }

    get moveCategoriesAsOptions(): { value: string; display: string }[] {
        return (this.moveCategoryList?.rows ?? []).map((m) => ({
            value: m.name ?? "",
            display: m.name ?? "",
        }));
    }

    async fetchMoveCategory(this: MoveCategoryStore, moveCategoryId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `move-categories/${moveCategoryId}`,
            },
            moveCategorySchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    async editMoveCategory(this: MoveCategoryStore, moveCategoryId: string, moveCategory: MoveCategoryForm) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: `move-categories/${moveCategoryId}`,
            data: moveCategory,
        });

        if (response.err) {
            throw response.err;
        }
    }

    async createMoveCategory(this: MoveCategoryStore, moveCategory: MoveCategoryForm) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: `move-categories`,
            data: moveCategory,
        });

        if (response.err) {
            throw response.err;
        }
    }

    @action
    async delete(this: MoveCategoryStore, moveCategoryId: string, params: SearchParams) {
        this.isFetchingList = true;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `move-categories/${moveCategoryId}`,
        });

        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "move-categories",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(moveCategorySchema).defined(),
            })
        );
        this.isFetchingList = false;

        if (response.data) {
            this.moveCategoryList = response.data;
        }
    }
}
