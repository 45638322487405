import React from "react";
import style from "./style.module.scss";
import { observer } from "mobx-react";
import { Input, Button, Card } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAuthStore } from "../../stores";
import { useNavigation } from "../../utils";

const validationSchema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
});

const initialValues = {
    email: "",
    password: "",
};

export const LoginPage = observer(() => {
    const authStore = useAuthStore();
    const navigation = useNavigation();
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: () => {},
    });

    const login = async (e: React.FormEvent) => {
        e.preventDefault();
        const success = await authStore.login(
            formik.values.email,
            formik.values.password
        );
        if (success) {
            navigation.goToHome();
        }
    };

    return (
        <form className={style.login_page} onSubmit={login}>
            <Card title="Login" className={style.login_card}>
                <Input
                    className={style.input}
                    placeholder="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <Input.Password
                    className={style.input}
                    name="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={login}
                    disabled={
                        authStore.isAuthenticating ||
                        !formik.isValid ||
                        !formik.dirty
                    }
                    loading={authStore.isAuthenticating}
                >
                    Login
                </Button>
            </Card>
        </form>
    );
});
