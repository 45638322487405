import { gql } from '@apollo/client'

export const updateConfig = gql`
  mutation UpdateConfig($updateConfig: [UpdateConfigDto!]!) {
    updateConfig(updateConfig: $updateConfig) {
      name
      type
      value
    }
}
`
