import React from "react";
import style from "./style.module.scss";
import {Input, Select, Switch} from "antd";
import produce from "immer";

type FlexSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface WrapperProps {
    flexSize?: FlexSize;
    required?: boolean;
    title?: string;
    disabled?: boolean;
}

interface WrapperWithChildProps extends WrapperProps {
    children: JSX.Element;
}

interface InputProps extends WrapperProps {
    onChange: (e: React.ChangeEvent) => void;
    onBlur: (e: React.FocusEvent) => void;
    name: string;
    value?: string | number | null;
    type?: "text" | "number";
    disabled?: boolean;
    maxLength?: number
}

interface SelectProps extends WrapperProps {
    defaultValue?: string;
    value?: string | string[];
    className?: string;
    setFieldValue: (name: string, value: unknown) => void;
    name: string;
    options: { value: string; display: string }[];
    mode?: "multiple" | "tags";
    isLoading?: boolean;
    getPopupContainer?: () => HTMLElement;
}

interface SwitchProps extends WrapperProps {
    name: string;
    checked: boolean;
    setFieldValue: (name: string, value: unknown) => void;
}

export const Label = (props: { title?: string; required?: boolean }) => (
    <span>
        {props.title ?? ""}
        {props.required ? <span className={style.red_start}>*</span> : ""}
    </span>
);

const GenericWrapper = (props: WrapperWithChildProps) => (
    <div className={`${style.input_holder} flex-${props.flexSize ?? 4}`}>
        <Label title={props.title} required={props.required}/>
        {props.children}
    </div>
);

export const InputWrapper = React.memo((props: InputProps) => {
    return (
        <GenericWrapper
            title={props.title}
            required={props.required}
            flexSize={props.flexSize}
        >
            <Input
                onChange={props.onChange}
                onBlur={props.onBlur}
                name={props.name}
                value={props.value ?? undefined}
                type={props.type ?? "text"}
                disabled={!!props.disabled}
                maxLength={props.maxLength}
            />
        </GenericWrapper>
    );
});

export const TextAreaWrapper = React.memo((props: InputProps) => {
    return (
        <GenericWrapper
            title={props.title}
            required={props.required}
            flexSize={props.flexSize}
        >
            <Input.TextArea
                onChange={props.onChange}
                onBlur={props.onBlur}
                name={props.name}
                value={props.value ?? undefined}
            />
        </GenericWrapper>
    );
});

export const SelectWrapper = React.memo((props: SelectProps) => {
    /**
     * Handle module selection
     */
    const onSelect = (value: string) => {
        if (props.mode === "multiple" || props.mode === "tags") {
            if (props.value && Array.isArray(props.value)) {
                const newList = produce(
                    props.value,
                    (draft) => void draft.push(value)
                );
                props.setFieldValue(props.name, newList);
            } else {
                props.setFieldValue(props.name, [value]);
            }
        } else {
            props.setFieldValue(props.name, value);
        }
    };

    /**
     * Handle module de-selection
     */
    const onDeselect = (value: string) => {
        if (props.mode === "multiple" || props.mode === "tags") {
            if (props.value && Array.isArray(props.value)) {
                const index = props.value.indexOf(value);
                const newList = produce(
                    props.value,
                    (draft) => void draft.splice(index, 1)
                );
                props.setFieldValue(props.name, newList);
            } else {
                props.setFieldValue(props.name, []);
            }
        }
    };

    return (
        <GenericWrapper
            title={props.title}
            required={props.required}
            flexSize={props.flexSize}
        >
            <Select
                mode={props.mode}
                defaultValue={props.defaultValue}
                onSelect={onSelect}
                onDeselect={onDeselect}
                value={props.value}
                loading={props.isLoading}
                disabled={props.disabled}
                getPopupContainer={props.getPopupContainer}
            >
                {props.options.map((option) => (
                    <Select.Option value={option.value} key={option.value}>
                        {option.display}
                    </Select.Option>
                ))}
            </Select>
        </GenericWrapper>
    );
});

export const SwitchWrapper = React.memo((props: SwitchProps) => {
    return (
        <GenericWrapper
            title={props.title}
            required={props.required}
            flexSize={props.flexSize}
        >
            <Switch
                style={{width: 20}}
                checked={props.checked}
                onChange={(c) => props.setFieldValue(props.name, c)}
            />
        </GenericWrapper>
    );
});
