import * as yup from "yup";
import {MomentSchema, langSchema} from "../utils";
import {buyOfferSchema} from "./index";

export const organizationCategoryTypeSchema = yup.object({
    id: yup.string().required(),
    titleFr: yup.string().required(),
    titleEn: yup.string().required(),
    handle: yup.string().required(),
    icon: yup.string().nullable(),
    order: yup.number().required(),
    createdAt: new MomentSchema().required(),
    updatedAt: new MomentSchema().required(),
}).required();


export const organizationCategorySchema = yup.object({
    id: yup.string().required(),
    titleFr: yup.string().required(),
    titleEn: yup.string().required(),
    headlineFr: yup.string(),
    headlineEn: yup.string(),
    order: yup.number(),
    ctaFr: yup.string(),
    ctaEn: yup.string(),
    handle: yup.string().required(),
    image: yup.string().nullable(),
    icon: yup.string().nullable(),
    groupHandle: yup.string().nullable(),
    visibleInMove: yup.boolean().required(),
    types: yup.array(organizationCategoryTypeSchema).defined().min(0),
    country: yup.string().required(),
    createdAt: new MomentSchema().required(),
    updatedAt: new MomentSchema().required(),
}).required();


export const organizationEntitySchema = yup.object({
    id: yup.string().required(),
    address: yup.string().nullable(),
    agreementRequired: yup.boolean().nullable(),
    contactMethod: yup.string().nullable(),
    country: yup.string().required(),
    customerServiceEmail: yup.string().nullable(),
    customerServicePhone: yup.string().nullable(),
    isSpecialType: yup.boolean().nullable(),
    nameEn: yup.string().nullable(),
    nameFr: yup.string().nullable(),
    organizationId: yup.string().required(),
    primaryContactEmail: yup.string().nullable(),
    primaryContactFirstname: yup.string().nullable(),
    primaryContactJobTitle: yup.string().nullable(),
    primaryContactLang: yup.string().nullable(),
    primaryContactLastname: yup.string().nullable(),
    primaryContactPhone: yup.string().nullable(),
    contactWebsiteFr: yup.string().nullable(),
    contactWebsiteEn: yup.string().nullable(),
    region: yup.string().nullable(),
    secondaryContactEmail: yup.string().nullable(),
    visible: yup.boolean().nullable(),
    createdAt: new MomentSchema().required(),
    updatedAt: new MomentSchema().required(),
});

export const organizationDescriptionSchema = yup
    .object({
        id: yup.string().required(),
        descriptionLong: yup.string().nullable(),
        descriptionShort: yup.string().nullable(),
        lang: langSchema,
        name: yup.string().nullable(),
        organizationId: yup.string().required(),
        questions: yup.string().nullable(),
    })
    .required();

export const confirmationTextSchema = yup.object({
    id: yup.string().required(),
    value: yup.string().nullable(),
    lang: yup.string().nullable(),
});

export const relevantContentSchema = yup.object({
    id: yup.string().required(),
    lang: yup.string().nullable(),
    title: yup.string().nullable(),
    url: yup.string().nullable(),
    imageUrl: yup.string().nullable(),
    contactNumber: yup.string().nullable(),
    movingProcedureUrl: yup.string().nullable(),
});

export const questionSchema = yup
    .object({
        id: yup.string().required(),
        organizationId: yup.string().required(),
        translations: yup.array(
            yup
                .object({
                    id: yup.string().required(),
                    lang: langSchema,
                    questionId: yup.string(),
                    value: yup.string().nullable(),
                })
                .required()
        ),
    })
    .required();

export const organizationSchema = yup
    .object({
        id: yup.string().required(),
        agreementRequired: yup.boolean().nullable(),
        country: yup.string().nullable(),
        categoryId: yup.string().nullable(),
        confirmationTexts: yup.array(confirmationTextSchema),
        relevantContents: yup.array(relevantContentSchema),
        descriptions: yup.array().of(organizationDescriptionSchema),
        externalId: yup.string().nullable(),
        favoriteForRegions: yup.string().nullable(),
        filter: yup.string().nullable(),
        isAvailable: yup.boolean().nullable(),
        isPartner: yup.boolean().nullable(),
        logoEn: yup.string().nullable(),
        logoFr: yup.string().nullable(),
        regions: yup.string().nullable(),
        visible: yup.boolean().nullable(),
        web: yup.string().nullable(),
        entities: yup.array(organizationEntitySchema),
        buyOffers: yup.array(buyOfferSchema).nullable(),
        questions: yup.array(questionSchema),
        createdAt: new MomentSchema().required(),
        updatedAt: new MomentSchema().required(),
        deletedAt: new MomentSchema().nullable(),
    })
    .required();

export const lightOrganizationSchema = yup
    .object({
        id: yup.string().required(),
        isAvailable: yup.number(),
        isPrivate: yup.boolean(),
        name: yup.string(),
        categoryId: yup.string(),
        buyOfferCount: yup.number(),
        entityCount: yup.number(),
    })
    .required();

export type Organization = yup.InferType<typeof organizationSchema>;
export type OrganizationCategory = yup.InferType<typeof organizationCategorySchema>;
export type OrganizationCategoryType = yup.InferType<typeof organizationCategoryTypeSchema>;
export type OrganizationDescription = yup.InferType<typeof organizationDescriptionSchema>;
export type LightOrganization = yup.InferType<typeof lightOrganizationSchema>;
