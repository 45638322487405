import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  Skeleton,
  Alert,
  Input,
  Select
} from 'antd'
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import { useAllFrontendConfig, useUpdateFrontendConfigAction } from './config.hooks'
import { Config } from './config.model'
import ConfigField from './ConfigField'

const {Title} = Typography

const ConfigPage: React.FC = () => {
  const { loading, error, data: config } = useAllFrontendConfig()
  const { updateConfig, loading: saving, data: updatedConfig, error: savingError } = useUpdateFrontendConfigAction()
  const [ formData, setFormData ] = useState<Config[]>()
  const [ formSuccess, setFormSuccess ] = useState(false)
  const [ formError, setFormError ] = useState<string|undefined>()

  const formHasError = !!formError

  useEffect(() => {
    if(saving || savingError){
      return
    }
    const data: Config[] = updatedConfig?.length > 0 ? updatedConfig : config

    if(!Array.isArray(data) || data.length === 0){
      return
    }

    const sortedData = [...data].sort((a, b) => {
      if(a.name < b.name){
        return -1
      }else if(a.name > b.name){
        return 1
      }
      return 0
    })
    if(sortedData.length > 0){
      setFormData(sortedData)
    }
  }, [config, updatedConfig, saving, savingError])

  /**
   * submit form and update config
   */
  const onSubmit = async () => {
    if(!formData){
      return
    }
    setFormSuccess(false)
    const names = formData.map(({ name }) => name)
    if(names.includes('')){
      setFormError('Config name cannot be empty')
      return
    }
    if(Array.from(new Set(names)).length !== names.length){
      setFormError('Config name must be unique')
      return
    }
    setFormError(undefined)

    try {
      await updateConfig(formData.map(({name, type, value}) => ({name, type, value})))
      setFormSuccess(true)
    } catch(error){
      setFormError(String(error))
    }
  }

  /**
   * add config
   */
  const onAddConfig = () => {
    if(!formData){
      return
    }
    const newData = [...formData]
    newData.push({ name: '', type: 'string', value: '""' })
    setFormData(newData) 
  }

  /**
   * update config
   */
  const onFieldChange = (index: number, field: keyof Config, value: string) => {
    if(!formData || !formData[index]){
      return
    }
    const newData= [...formData]
    const newRow = {...newData[index]}
    newRow[field] = value

    if(field === 'type'){
      switch(value){
        case 'string':
          newRow.value = '""'
        break
        case 'number':
          newRow.value = '0'
        break
        case 'boolean':
          newRow.value = 'false'
        break
        default:
          newRow.value = ''
      }
    }

    newData[index] = newRow
    setFormData(newData) 
  }

  /**
   * remove config
   */
   const onDeleteConfig = (index: number) => {
    if(!formData || !formData[index]){
      return
    }
    const newData = [...formData]
    newData.splice(index, 1)
    setFormData(newData) 
  }

  return (
    <div>
      <Title level={2}>Config</Title>
      <Alert
        message="Config are used by MovingWaldo application. Be carefull as they might break the application."
        type="warning"
        showIcon
      />
      <br />

      { formError && (<>
        <Alert
          message={formError}
          type="error"
          showIcon
        />
        <br />
      </>) }

      { !formError && formSuccess && (<>
        <Alert
          message="Config updated successfully"
          type="success"
          showIcon
        />
        <br />
      </>) }

      <div className="description-wrapper flex-wrapper">
        <Skeleton
            loading={loading}
            active={true}
            paragraph={{rows: 16}}
        >
          {error && (
            <Alert
              message={`An error occured while loading the config. ${error}`}
              type="error"
              showIcon
            />
          )}
          {formData && formData.length > 0 && (
            <>
              <table width="100%">
                <thead className="ant-table-thead">
                  <tr>
                    <th style={{ width: 150 }}>Name</th>
                    <th style={{ width: 150 }}>Type</th>
                    <th>Value</th>
                    <th style={{ width: 150 }}></th>
                  </tr>
                </thead>

                <tbody className="ant-table-tbody">

                  {formData.filter(c => !!c).map(({ name, type, value }, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          value={name}
                          style={{ width: 350 }}
                          onChange={(event) => { onFieldChange(index, 'name', event.target.value) }}
                        />
                      </td>
                      <td>
                        <Select
                          style={{ width: 100 }}
                          value={type}
                          onChange={(value) => { onFieldChange(index, 'type', value) }}
                        >
                          <Select.Option value="string">String</Select.Option>
                          <Select.Option value="number">Number</Select.Option>
                          <Select.Option value="boolean">Boolean</Select.Option>
                          <Select.Option value="date">Date</Select.Option>
                        </Select>
                      </td>
                      <td>
                        <ConfigField config={{ name, type, value }} onChange={value => onFieldChange(index, 'value', value)} />
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <Button danger size="small" onClick={() => onDeleteConfig(index)}>Delete</Button>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>

              <div style={{ padding: '2rem 0 1rem' }}>
                <Button onClick={onAddConfig}><PlusCircleOutlined /> Add new config</Button>
              </div>
            </>
          )}

        </Skeleton>
      </div>

      <br />
      <Button type="primary" onClick={onSubmit} loading={saving}>Save</Button>
      { formHasError && (
        <span style={{paddingLeft: '0.5rem', color: 'red'}}>
          <CloseCircleOutlined /> Form contains errors
        </span>
      ) }
    </div>
  )
}

export default ConfigPage
