import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { useAggregatedRatings } from './ratings.hooks'
import { MoveRating } from './ratings.models'
import styles from './ratings.module.css'
import Stars from './Stars'

const Comment: React.FC<MoveRating> = ({
  createdAt,
  user,
  rating,
  comments,
}) => {
  return (
    <div className={styles.commentRow}>
      <Stars rating={rating} />
      <div className={styles.commentInfoContainer}>
        <Link to={`/users/${user.id}`}>{ user.firstname } { user.lastname }</Link>, { moment.utc(createdAt).format('YYYY-MM-DD') }
      </div>
      {comments && (
        <div className={styles.comment}>
          { comments }
        </div>
      )}
    </div>
  )
}

const LatestComments: React.FC = () => {
  const { data: aggregatedRatings } = useAggregatedRatings()

  if(!aggregatedRatings || aggregatedRatings.last100Ratings.length === 0){
    return <em>No rating yet</em>
  }

  return (
    <div className={styles.comments}>
      { aggregatedRatings.last100Ratings.map(rating => (
        <Comment
          key={rating.id}
          {...rating}
        />
      )) }
    </div>
  )
}

export default LatestComments
