import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Cookies from "js-cookie";

const env = process.env.NODE_ENV
const endpoint = process.env.REACT_APP_API_V2_URL
const debugMode = env !== 'production'

const httpLink = new HttpLink({
  uri: `${endpoint}/graphql`,
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-token': Cookies.get('token'),
  },
}))

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: debugMode,
})

export default client