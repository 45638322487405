import React from 'react'
import { Statistic, Card } from 'antd'
import { TeamOutlined } from '@ant-design/icons'

import styles from './style.module.scss'

type BrokerstatsProps = {
  stats?: Record<string, number>
}

const formatNumber = (number: Number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Brokerstats: React.FC<BrokerstatsProps> = ({
  stats = {}
}) => {

  const getNbReferredUsers = () => stats.nbReferredUsers ?? 0

  return (
    <div className={styles.statsContainer}>
          <Card>
            <Statistic
              title="Associated users"
              value={formatNumber(getNbReferredUsers())}
              valueStyle={{ color: '#3f8600' }}
              prefix={<TeamOutlined />}
            />
          </Card>
    </div>
  )
}

export default Brokerstats
