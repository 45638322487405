import { EntityForm } from "../form";

export class EntityErrors extends Map<string, boolean> {
    hasError(this: EntityErrors) {
        return this.size > 0;
    }
}

const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export const generateEntityErrors = (entities?: EntityForm[]) => {
    const map = new EntityErrors();
    for (const entity of entities ?? []) {
        const hasError =
            !entity.primaryContactEmail ||
            !validateEmail(entity.primaryContactEmail) ||
            !entity.nameEn ||
            !entity.nameFr ||
            !entity.customerServiceEmail ||
            !validateEmail(entity.customerServiceEmail)
        if (hasError) {
            map.set(entity.id, true);
        } else {
            map.delete(entity.id);
        }
    }
    return map;
};
