import * as yup from "yup";
import {MomentSchema} from "../utils";
import {moveWithRequestsSchema} from "./index";

export const userSchema = yup
    .object({
        id: yup.string().required(),
        agreementSignedAt: new MomentSchema().nullable(),
        birthdate: new MomentSchema().nullable(),
        email: yup.string().required(),
        externalId: yup.string().nullable(),
        firstname: yup.string().nullable(),
        gender: yup.string().nullable(),
        hasHomeInsurance: yup.boolean().nullable(),
        homeInsuranceRenewalMonth: yup.string().nullable(),
        homeOwnership: yup.string().nullable(),
        language: yup.string().nullable(),
        lastname: yup.string().nullable(),
        needTelcom: yup.boolean().nullable(),
        phoneCell: yup.string().nullable(),
        phoneHome: yup.string().nullable(),
        phoneProviderId: yup.string().nullable(),
        phoneWork: yup.string().nullable(),
        reachTime: yup.array().of(yup.string()),
        roles: yup.array().of(yup.string()),
        signature: yup.string().nullable(),
        refererToken: yup.string(),
        createdAt: new MomentSchema().required(),
        updatedAt: new MomentSchema().nullable(),
        deletedAt: new MomentSchema().nullable(),
        proReferer: yup.object({
            id: yup.string(),
            refererToken: yup.string(),
            usersCount: yup.number().nullable(),
        }).nullable(),
        telecomProvider: yup.string().nullable(),
    })

export const userSchemaRequired = userSchema.required();

export const userWithMovesSchema = userSchemaRequired.shape({
    moves: yup.array(moveWithRequestsSchema),
    referredUsers: yup.array(
        yup
            .object({
                id: yup.string().required(),
                firstName: yup.string().required(),
                lastName: yup.string().required(),
                email: yup.string().required(),
            })
            .required()
    ),
});


export type User = yup.InferType<typeof userSchemaRequired>;
export type UserWithMove = yup.InferType<typeof userWithMovesSchema>;
