import React from "react";
import style from "./style.module.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const NewIcon = (props: { id: string; isNew?: boolean }) => {
    if (props.isNew) {
        return (
            <Tooltip title="This item is new">
                <PlusCircleOutlined className={style.new_icon} />
            </Tooltip>
        );
    }
    return null;
};
