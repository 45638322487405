import * as yup from "yup";
import {RootStore} from "./root";
import {Region, Country, countrySchema} from "../schemas";
import {observable, action} from "mobx";

type RegionByCountry = {
    [key: string]: Region[]
};

type RegionByCountrySelect = {
    [key: string]: { value: string; display: string }[]
};

export class GlobalStore {
    @observable countriesSelected: string[] = [];
    @observable isFetch = false;
    @observable countries: Country[] = [];
    @observable regions: Region[] = [];
    @observable regionsByCountry: RegionByCountry = {};
    @observable countriesSelect: { value: string; display: string }[] = []
    @observable regionsSelect: { value: string; display: string }[] = []
    @observable regionsByCountrySelect: RegionByCountrySelect = {}
    @observable isFetchingList = false;

    constructor(private rootStore: RootStore) {
    }

    @action
    async fetchList(this: GlobalStore) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "countries"
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(countrySchema).defined(),
            })
        );
        this.isFetchingList = false;


        if (response.data) {
            this.countries = response.data.rows;

            this.countries.forEach((country: any) => {
                this.countriesSelect.push({display: country.name, value: country.id})

                //add default
                this.countriesSelected.push(country.id)

                this.regionsByCountry[country?.id] = []
                this.regionsByCountrySelect[country?.id] = []

                country.regions.forEach((region: any) => {
                    this.regions.push(region)
                    this.regionsSelect.push({display: region.name, value: region.id})

                    this.regionsByCountry[country?.id].push(region)
                    this.regionsByCountrySelect[country?.id].push({display: region.name, value: region.id})
                })

            })

        }

        this.isFetch = true
    }


    reset(this: GlobalStore) {
        this.countriesSelected = this.countries.map((c: any) => c.id)
    }

    toggleCountry(this: GlobalStore, countryCode: string) {
        let countriesSelected = [
            ...this.countriesSelected
        ]
        let idx = this.countriesSelected.findIndex(c => c === countryCode)
        if (idx === -1) {
            countriesSelected.push(countryCode)
        } else {
            countriesSelected.splice(idx, 1)
        }
        this.countriesSelected = countriesSelected
    }

}
