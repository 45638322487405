import React from "react";
import {Layout, Menu} from "antd";
import {Switch, Route} from "react-router-dom";
import {
    ShoppingCartOutlined,
    CarOutlined,
    ApartmentOutlined,
    UserOutlined,
    RobotOutlined,
    HomeOutlined,
    StarOutlined,
    SettingOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
import {useAuthStore, useGlobalStore} from "../../stores";
import {observer} from "mobx-react";
import style from "./style.module.scss";
import {MenuInfo} from "rc-menu/lib/interface";
import {Routes, useNavigation} from "../../utils";
import {
    BrokersPage,
    BrokerPage,
    MoveRequestsPage,
    OrganizationsPage,
    OrganizationPage,
    UserPage,
    UsersPage,
    MoveRequestPage,
    ModulesPage,
    ModulesCustomPage,
    ModulePage,
    MoveStepPage,
    MoveCategoryPage,
    BuyRequestsPage,
    BuyRequestPage,
    OrganizationCategoryPage,
    OrganizationCategoriesPage,
    RatingsPage,
    ConfigPage,
} from "../index";

const {Header, Content, Footer, Sider} = Layout;

export const HomePage = observer(() => {
    const [collapsed, setCollapsed] = React.useState(false);
    const navigation = useNavigation();
    const authStore = useAuthStore();
    const gobalStore = useGlobalStore();

    //fetch global
    React.useEffect(() => {
        if (gobalStore.countries.length === 0) {
            gobalStore.fetchList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!authStore.jwt) {
            navigation.replaceToLogin();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.jwt]);

    const onItemSelected = (param: MenuInfo) => {
        switch (param.key) {
            case "move-requests":
                navigation.goToMoveRequests();
                break;
            case "organizations":
                navigation.goToOrganizations();
                break;
            case "organization-categories":
                navigation.goToOrganizationCategories();
                break;
            case "users":
                navigation.goToUsers();
                break;
            case "brokers":
                navigation.goToBrokers();
                break;
            case "modules":
                navigation.goToModules();
                break;
            case "modules-custom":
                navigation.goToModulesCustom();
                break;
            case "buy-requests":
                navigation.goToBuyRequests();
                break;
            case "ratings":
                navigation.goToRatings();
                break;
            case "config":
                navigation.goToConfig();
                break;
            case "log-out":
                authStore.setJwt(null);
                break;
            default:
                navigation.goToHome();
        }
    };

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(c) => setCollapsed(c)}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                }}
            >
                <div className={style.logo}/>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={["1"]}
                    mode="inline"
                    onClick={onItemSelected}
                >
                    <Menu.Item key="move-requests" icon={<CarOutlined/>}>
                        Move Requests
                    </Menu.Item>
                    <Menu.Item
                        key="buy-requests"
                        icon={<ShoppingCartOutlined/>}
                    >
                        Buy Requests
                    </Menu.Item>
                    <Menu.Item key="organizations" icon={<ApartmentOutlined/>}>
                        Organizations
                    </Menu.Item>
                    <Menu.Item key="organization-categories" icon={<RobotOutlined/>}>
                        Categories
                    </Menu.Item>
                    <Menu.Item key="users" icon={<UserOutlined/>}>
                        Users
                    </Menu.Item>
                    <Menu.Item key="modules" icon={<RobotOutlined/>}>
                        Modules
                    </Menu.Item>
                    <Menu.Item key="modules-custom" icon={<RobotOutlined/>}>
                        Modules Categories
                    </Menu.Item>
                    <Menu.Item key="brokers" icon={<HomeOutlined/>}>
                        Brokers
                    </Menu.Item>
                    <Menu.Item key="ratings" icon={<StarOutlined/>}>
                        Ratings
                    </Menu.Item>
                    <Menu.Item key="config" icon={<SettingOutlined/>}>
                        Config
                    </Menu.Item>
                    <Menu.Item key="log-out" icon={<LogoutOutlined/>}>
                        Log out
                    </Menu.Item>
                </Menu>
            </Sider>


            <Layout
                className="site-layout"
                style={{
                    marginLeft: collapsed ? 80 : 200,
                    transition: "margin-left 0.2s",
                }}
            >
                <Header
                    className="site-layout-background"
                    style={{padding: 0}}
                />
                {gobalStore.isFetch && (
                    <Content style={{padding: "0 16px"}}>
                        <br/>
                        <Switch>
                            <Route path={`${Routes.MOVE_REQUESTS}/:id`}>
                                <MoveRequestPage/>
                            </Route>
                            <Route path={Routes.MOVE_REQUESTS}>
                                <MoveRequestsPage/>
                            </Route>
                            <Route path={`${Routes.BUY_REQUESTS}/:id`}>
                                <BuyRequestPage/>
                            </Route>
                            <Route path={Routes.BUY_REQUESTS}>
                                <BuyRequestsPage/>
                            </Route>
                            <Route path={`${Routes.ORGANIZATIONS}/create`}>
                                <OrganizationPage/>
                            </Route>
                            <Route path={`${Routes.ORGANIZATIONS}/edit/:id`}>
                                <OrganizationPage/>
                            </Route>
                            <Route path={Routes.ORGANIZATIONS}>
                                <OrganizationsPage/>
                            </Route>
                            <Route path={`${Routes.ORGANIZATION_CATEGORIES}/create`}>
                                <OrganizationCategoryPage/>
                            </Route>
                            <Route path={`${Routes.ORGANIZATION_CATEGORIES}/edit/:id`}>
                                <OrganizationCategoryPage/>
                            </Route>
                            <Route path={Routes.ORGANIZATION_CATEGORIES}>
                                <OrganizationCategoriesPage/>
                            </Route>
                            <Route path={`${Routes.USERS}/:id`}>
                                <UserPage/>
                            </Route>
                            <Route path={Routes.USERS}>
                                <UsersPage/>
                            </Route>
                            <Route path={`${Routes.BROKERS}/create`}>
                                <BrokerPage/>
                            </Route>
                            <Route path={`${Routes.BROKERS}/edit/:id`}>
                                <BrokerPage/>
                            </Route>
                            <Route path={Routes.BROKERS}>
                                <BrokersPage/>
                            </Route>
                            <Route path={`${Routes.MODULES}/edit/:id`}>
                                <ModulePage/>
                            </Route>
                            <Route path={`${Routes.MODULES}/create`}>
                                <ModulePage/>
                            </Route>
                            <Route path={Routes.MODULES}>
                                <ModulesPage/>
                            </Route>
                            <Route path={Routes.MODULES_CUSTOM}>
                                <ModulesCustomPage/>
                            </Route>
                            <Route path={`${Routes.MOVE_STEPS}/edit/:id`}>
                                <MoveStepPage/>
                            </Route>
                            <Route path={`${Routes.MOVE_STEPS}/create`}>
                                <MoveStepPage/>
                            </Route>
                            <Route path={`${Routes.MOVE_CATEGORIES}/edit/:id`}>
                                <MoveCategoryPage/>
                            </Route>
                            <Route path={`${Routes.MOVE_CATEGORIES}/create`}>
                                <MoveCategoryPage/>
                            </Route>
                            <Route path={Routes.RATINGS}>
                                <RatingsPage/>
                            </Route>
                            <Route path={Routes.CONFIG}>
                                <ConfigPage/>
                            </Route>
                            <Route path={Routes.HOME}>
                                <MoveRequestsPage/>
                            </Route>
                        </Switch>
                    </Content>
                )}
                <Footer style={{textAlign: "center"}}>
                    Moving Waldo ©{ new Date().getFullYear() }
                </Footer>
            </Layout>

        </Layout>
    );
});
