import * as yup from "yup";
import { RootStore } from "./root";
import { observable, action } from "mobx";
import { SearchParams, WithNetworkConcurrency } from "../utils";
import { lightMoveRequestSchema, moveRequestSchema } from "../schemas";
import { Moment } from "moment";

const moveRequestListSchema = yup
    .object({
        count: yup.number().required(),
        rows: yup.array().of(lightMoveRequestSchema).defined(),
    })
    .required();

export type MoveRequestList = yup.InferType<typeof moveRequestListSchema>;

export class MoveRequestStore extends WithNetworkConcurrency {
    @observable moveRequestList: MoveRequestList | null = null;
    @observable isFetchingListCount = 0;

    constructor(private rootStore: RootStore) {
        super();
    }

    @action
    async fetchList(this: MoveRequestStore, params: SearchParams) {
        this.isFetchingListCount++;
        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "requests/paginated",
                params,
            },
            moveRequestListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.moveRequestList = response.data;
        }
    }

    @action
    async fetchMoveRequest(this: MoveRequestStore, moveRequestId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `requests/${moveRequestId}`,
            },
            moveRequestSchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    fetchForCsv(
        this: MoveRequestStore,
        organizationId: string | undefined,
        from: Moment,
        to: Moment
    ) {
        return this.rootStore.callApiV2({
            url: 'move-requests/export',
            params: {
                organizationId,
                from: from.toISOString(),
                to: to.toISOString(),
            }
        })
    }

    @action
    async delete(
        this: MoveRequestStore,
        moveRequestId: string,
        params: SearchParams
    ) {
        this.isFetchingListCount++;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `requests/${moveRequestId}`,
        });

        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "requests/paginated",
                params,
            },
            moveRequestListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.moveRequestList = response.data;
        }
    }
}
