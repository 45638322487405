import React from "react";
import {observer} from "mobx-react";
import {Typography, Skeleton} from "antd";
import {LinkIcon, FieldWrapper} from "../../components";
import {useNavigation, getItemProp} from "../../utils";
import {useParams} from "react-router-dom";
import {useBuyRequestStore, useOrganizationCategoryStore} from "../../stores";
import {BuyRequest, Move, OrganizationCategory} from "../../schemas";

const {Title} = Typography;

const EMPTY_PLACEHOLDER = ' - '

export const BuyRequestPage = observer(() => {
    const navigation = useNavigation();
    const buyRequestStore = useBuyRequestStore();
    const organizationCategoryStore = useOrganizationCategoryStore();
    const params = useParams<{ id: string }>();
    const [buyRequest, setBuyRequest] = React.useState<BuyRequest | null>(null);
    const [isFetching, setIsFetching] = React.useState(false);

    React.useEffect(() => {
        setIsFetching(true);
        buyRequestStore
            .fetchBuyRequest(params.id)
            .then((br) => setBuyRequest(br))
            .finally(() => setIsFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);


    React.useEffect(() => {
        organizationCategoryStore
            .fetchList({
                offset: 0,
                limit: 99999
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const organizationName = getItemProp(
        buyRequest?.organization.descriptions,
        "fr",
        "name"
    );

    const organizationDescription = getItemProp(
        buyRequest?.organization.descriptions,
        "fr",
        "descriptionShort"
    );

    let organizationCategory = null;
    if (organizationCategoryStore.organizationCategoryList?.rows && buyRequest) {
        organizationCategory = organizationCategoryStore.organizationCategoryList?.rows.find((oc: OrganizationCategory) => oc.id === buyRequest?.organization.categoryId) || null
    }

    type Address = {
        apartment?: string|null
        street?: string|null
        city?: string|null
        region?: string|null
        country?: string|null
        postalCode?: string|null
    }

    const formatAddress = ({
        apartment,
        street,
        city,
        region,
        country,
        postalCode,
    }: Address) => {
        if (!street) {
            return
        }
            const parts = []
        if (apartment) {
            parts.push(`${apartment} - ${street}`)
        } else {
            parts.push(street)
        }

        if (city) {
            parts.push(city)
        }
        if (region) {
            if (country) {
            parts.push(`${region} (${country})`)
            } else {
            parts.push(region)
            }
        }
        if (postalCode) {
            parts.push(postalCode)
        }

        return parts.join(', ')
    }

    const getAddressDetailValue = (value?: string|null) => {
        if (!value) {
            return EMPTY_PLACEHOLDER
        }
        const dictionnary: Record<string, string> = {
            basement: 'Basement',
            groundFloor: 'Ground floor',
            secondFloor: '2nd floor',
            thirdFloor: '3rd floor',
            fourthFloor: '4th floor',
            fifthFloor: '5th floor and up',

            apartment: 'Apartment building',
            condominium: 'condominium',
            splitLevel: 'split level',
            townhouse: 'townhouse',
            semiDetached: 'semi-detached',
            house: 'house',
            cottage: 'cottage',
            assistedLiving: 'Assisted living',
            commercial: 'Commercial',
            highRise: 'High rise',
            storage: 'Storage',
            warehouse: 'Warehouse',
            other: 'Other',

            elevator: 'elevator',
            externalStaircase: 'external staircase',
            internalStaircase: 'internal staircase',
            noStaircase: 'none needed',

            studio: 'Studio',
            '2AndHalf': '2½',
            '3AndHalf': '3½',
            '4AndHalf': '4½',
            '5AndHalf': '5½',
            '6AndHalf': '6½ and larger',
            '1bedroom': '1 bedroom',
            '2bedrooms': '2 bedrooms',
            '3bedrooms': '3 bedrooms',
            '4bedrooms': '4 bedrooms',
            '5bedroomsAndMore': '5+ bedrooms',

            parkingLot: 'Parking lot',
            privateDriveway: 'Private driveway',
            garage: 'Garage',
            street: 'Street',

            noStairs: 'No stairs / ground level',
            '1Flight': '1 flight',
            '2Flights': '2 flights',
            '3Flights': '3 flights',
            '4Flights': '4 flights',
            moreThan5Flights: '5+ flights',

            lessThen100ft: 'Less than 100ft',
            '100ft': '100 - 199 ft',
            '200ft': '200 - 299 ft',
            '300ft': '300 - 399 ft',
            '400ft': '400 - 499 ft',
            '500ft': '500 - 599 ft',
            '600ft': '600 - 699 ft',
            '700ft': '700 - 799 ft',
            '800ft': '800 - 899 ft',
            '900ft': '900 - 999 ft',
            moreThen1000ft: 'More than 1000 ft',

            yes: 'Yes',
            no: 'No',

            am: 'Morning (8am - 11am)',
            pm: 'Afternoon (12pm - 4pm)',
            evening: 'Evening (5pm - 8pm)',

            packingHelp: 'Packing help',
            boxingAndPackingMaterial: 'Boxes and packing materials',
            shortTermStorage: 'Short term storage',
            monthlyStorage: 'Monthly storage',
        }
        return dictionnary[value] ?? value
    }

    const getAddressDetailValues = (values: any) => {
        if(!Array.isArray(values) || values.length === 0){
            return EMPTY_PLACEHOLDER
        }
        return values.map(getAddressDetailValue).join(', ')
    }

    const getMovingDate = (move?: Move) => {
        if(move?.movingStuffDatetime){
            return move?.movingStuffDatetime.format('LL')
        }
        if(move?.date){
            return move?.date.utc().format('LL')
        }
        return EMPTY_PLACEHOLDER
    }

    const getMovingTimeRange = (timeRange?: string[]) => {
        if(!timeRange || timeRange.length === 0){
            return EMPTY_PLACEHOLDER
        }
        const dictionnary: Record<string, string> = {
            am: 'Morning',
            pm: 'Afternoon',
            evening: 'Evening',
        }
        return timeRange.map((time) => {
            return dictionnary[time] ?? time
        }).join(', ')
    }

    return (
        <div>
            <Title level={2}>Buy request</Title>
            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            General info
                        </span>
                        <FieldWrapper
                            label="Id"
                            text={buyRequest?.id}
                            flex={4}
                        />
                        <FieldWrapper
                            label="Buy offer short description"
                            text={
                                buyRequest?.buyOffer?.shortDescriptionFr ?? ""
                            }
                            flex={4}
                        />
                        <FieldWrapper
                            label="Creation date"
                            text={buyRequest?.createdAt?.format("LL") ?? ""}
                            flex={4}
                        />
                    </div>
                </Skeleton>
            </div>
            <br />
            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    <span className="small_separation_text flex-12">
                        Organization{" "}
                        <LinkIcon
                            onClick={() =>
                                navigation.editOrganization(
                                    buyRequest?.organizationId
                                )
                            }
                        />
                    </span>
                    <div className="flex-wrapper">
                        <FieldWrapper
                            label="Name"
                            text={organizationName ?? ""}
                            flex={6}
                        />
                        <FieldWrapper
                            label="Description"
                            text={organizationDescription ?? ""}
                            flex={6}
                        />
                        <FieldWrapper label="Category" flex={6}>
                            <span>{organizationCategory?.titleEn}</span>
                        </FieldWrapper>
                    </div>
                </Skeleton>
            </div>
            <br/>
            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            User{" "}
                            <LinkIcon
                                onClick={() =>
                                    navigation.goToUser(buyRequest?.userId)
                                }
                            />
                        </span>
                        <FieldWrapper
                            label="User email"
                            text={buyRequest?.user?.email ?? ""}
                            flex={6}
                        />
                        <FieldWrapper
                            label="User name"
                            text={`${buyRequest?.user?.firstname} ${buyRequest?.user?.lastname}`}
                            flex={6}
                        />
                        <FieldWrapper
                            label="User cell number"
                            text={buyRequest?.user?.phoneCell ?? ""}
                            flex={6}
                        />
                        <FieldWrapper
                            label="User phone type"
                            text="Cell"
                            flex={6}
                        />
                    </div>
                </Skeleton>
            </div>
            <br/>


            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{rows: 16}}
                >
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            Move{" "}
                            <LinkIcon
                                onClick={() =>
                                    navigation.goToUser(buyRequest?.userId)
                                }
                            />
                        </span>
                        <FieldWrapper
                            label="Moving date"
                            text={getMovingDate(buyRequest?.move)}
                            flex={6}
                        />
                        <FieldWrapper
                            label="Preferred time"
                            text={getMovingTimeRange(buyRequest?.move?.timeRange)}
                            flex={6}
                        />
                        <span className="flex-6">
                            <div className="description-wrapper">
                                <div className="flex-wrapper">
                                <FieldWrapper
                                    label="From address"
                                    text={formatAddress({
                                        apartment: buyRequest?.move?.fromAppt,
                                        street: buyRequest?.move?.fromStreet,
                                        city: buyRequest?.move?.fromCity,
                                        region: buyRequest?.move?.fromRegion,
                                        country: buyRequest?.move?.fromCountry,
                                        postalCode: buyRequest?.move?.fromZip,
                                    })}
                                    flex={12}
                                />
                                
                                <FieldWrapper
                                    label="Residence type"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceType)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Parking type"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceParkingType)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Parking distance"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceParkingDistance)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Stairs"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceStairs)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Elevator"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceElevator)}
                                    flex={6}
                                />
                                
                                <FieldWrapper
                                    label="Nb bedrooms"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceDimension)}
                                    flex={6}
                                />

                                {buyRequest?.move?.originResidenceAccess && (<FieldWrapper
                                    label="Access"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceAccess)}
                                    flex={6}
                                />)}
                                {buyRequest?.move?.originResidenceLevel && (<FieldWrapper
                                    label="Level"
                                    text={getAddressDetailValue(buyRequest?.move?.originResidenceLevel)}
                                    flex={6}
                                />)}
                                </div>
                            </div>
                        </span>
                        <span className="flex-6">
                            <div className="description-wrapper">
                                <div className="flex-wrapper">
                                <FieldWrapper
                                    label="To address"
                                    text={formatAddress({
                                        apartment: buyRequest?.move?.toAppt,
                                        street: buyRequest?.move?.toStreet,
                                        city: buyRequest?.move?.toCity,
                                        region: buyRequest?.move?.toRegion,
                                        country: buyRequest?.move?.toCountry,
                                        postalCode: buyRequest?.move?.toZip,
                                    })}
                                    flex={12}
                                />
                                <FieldWrapper
                                    label="Residence type"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceType)}
                                    flex={6}
                                />
                                
                                <FieldWrapper
                                    label="Parking type"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceParkingType)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Parking distance"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceParkingDistance)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Stairs"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceStairs)}
                                    flex={6}
                                />
                                <FieldWrapper
                                    label="Elevator"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceElevator)}
                                    flex={6}
                                />

                                {buyRequest?.move?.destinationResidenceAccess && (<FieldWrapper
                                    label="Access"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceAccess)}
                                    flex={6}
                                />)}
                                {buyRequest?.move?.destinationResidenceLevel && (<FieldWrapper
                                    label="Level"
                                    text={getAddressDetailValue(buyRequest?.move?.destinationResidenceLevel)}
                                    flex={6}
                                />)}
                                </div>
                            </div>
                        </span>
                        <FieldWrapper
                            label="Special items"
                            text={buyRequest?.move?.movingStuff ?? EMPTY_PLACEHOLDER}
                            flex={12}
                        />

                        <FieldWrapper
                            label="Moving services"
                            text={getAddressDetailValues(buyRequest?.move?.movingServices)}
                            flex={12}
                        />
                    </div>
                </Skeleton>
            </div>
        </div>
    );
});
