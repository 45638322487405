import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import style from './style.module.scss'

const COPIED_DURATION = 1000

type CopyableTextProps = {
  text?: string | undefined | null
  children?: React.ReactNode
}

const CopyableText: React.FC<CopyableTextProps> = ({
  text,
  children
}) => {
  const [ displayCopied, setDisplayCopied ] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayCopied(false)
    }, COPIED_DURATION)

    return () => clearTimeout(timeout)
  }, [setDisplayCopied, displayCopied])

  if(!text){
    return null
  }

  const onClick = () => {
    navigator.clipboard.writeText(text)
    setDisplayCopied(true)
  }

  return (
    <>
      <Button
        icon={<CopyOutlined />}
        size='small'
        className={[
          style.copyButton,
          displayCopied && style.success,
        ].join(' ')}
        onClick={onClick}
      >
        {displayCopied ? 'Copied!' : 'Copy'}
      </Button>
      {' '}
      { children ?? text }
    </>
  )
}

export default CopyableText
