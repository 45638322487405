//@ts-ignore
const CATEGORY_ICONS_CHOICES = [
    {
        display: "None",
        value: "",
    },
    {
        display: "Box",
        value: "box",
    },
    {
        display: "Money",
        value: "money",
    },
    {
        display: "Flag",
        value: "flag",
    },
    {
        display: "Umbrella",
        value: "umbrella",
    },
    {
        display: "Side-bar",
        value: "side-bar",
    },
    {
        display: "Reward",
        value: "reward",
    },
    {
        display: "Book-open",
        value: "book-open",
    },
    {
        display: "Shield",
        value: "shield",
    },
    {
        display: "Hard-drive",
        value: "hard-drive",
    },
    {
        display: "Cellphone",
        value: "cellphone",
    },
    {
        display: "File-text",
        value: "file-text",
    },
    {
        display: "Wifi",
        value: "wifi",
    },
    {
        display: "Zap",
        value: "zap",
    },
    {
        display: "Archive",
        value: "archive",
    },
    {
        display: "X-Square",
        value: "x-square",
    },
    {
        display: "Users",
        value: "users",
    },
    {
        display: "Truck",
        value: "truck",
    },
    {
        display: "Trash",
        value: "trash",
    },
    {
        display: "Sun",
        value: "sun",
    },
    {
        display: "Car-Ship",
        value: "car-ship",
    }

]

//@ts-ignore
export default CATEGORY_ICONS_CHOICES;
//@ts-ignore
