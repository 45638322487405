import { useQuery, useMutation } from '@apollo/client'

import { QueryResult } from '../../graphql'
import { Config } from './config.model'
import * as queries from './config.queries'
import * as mutations from './config.mutations'

/**
 * get all frontend configs
 */
export const useAllFrontendConfig = ({ reload = false, ...queryArgs } = {}): QueryResult<Config[]> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, ...queryProps } = useQuery(queries.getAllFrontendConfig, {
    fetchPolicy,
    ...queryArgs,
  })

  return {
    data: data?.getAllFrontendConfig ?? [],
    loading: loading && networkStatus === 1,
    ...queryProps,
  }
}

/**
 * update frontend configs
 */
 export const useUpdateFrontendConfigAction = () => {
  const [mutate, { data: updatedConfig, ...mutationProps }] = useMutation(mutations.updateConfig)

  return {
    updateConfig: async (payload: Config[]) => {
      const { data } = await mutate({
        variables: {
          updateConfig: payload,
        },
      })

      return data.updateConfig
    },
    data: updatedConfig?.updateConfig,
    ...mutationProps,
  }
}
