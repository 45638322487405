import React from "react";
import {observer} from "mobx-react";
import {Typography, Button, Skeleton, message} from "antd";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import {
    InputWrapper,
    ImageInput
} from "../../components";
import {useNavigation} from "../../utils";
import {MoveStepForm, moveStepFormSchema} from "../../forms";
import {useMoveStepStore} from "../../stores";
import {transform} from "./transfomer";

const {Title} = Typography;

const initialValues = {
    titleFr: "",
    titleEn: "",
    order: 0,
    name: "",
    image: ""
};

export const MoveStepPage = observer(() => {
    const navigation = useNavigation();
    const moveStepStore = useMoveStepStore();
    const [isFetchingMoveStep, setFetchingMoveStep] = React.useState(false);
    const [isUpserting, setUpserting] = React.useState(false);
    const params = useParams<{ id?: string }>();
    const formik = useFormik<MoveStepForm>({
        initialValues,
        validationSchema: moveStepFormSchema,
        validateOnMount: true,
        onSubmit: () => {
        }
    });

    React.useEffect(() => {
        if (params.id) {
            setFetchingMoveStep(true);
            moveStepStore
                .fetchMoveStep(params.id)
                .then(m => formik.setValues(transform(m)))
                .finally(() => setFetchingMoveStep(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const editMoveStep = React.useCallback(() => {
        if (params.id) {
            setUpserting(true);
            moveStepStore
                .editMoveStep(params.id, formik.values)
                .then(() => void message.success("MoveStep edited"))
                .finally(() => setUpserting(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, formik.values]);

    const createMoveStep = React.useCallback(() => {
        setUpserting(true);
        moveStepStore
            .createMoveStep(formik.values)
            .then(() => {
                message.success("MoveStep Created");
                navigation.goToModulesCustom();
            })
            .finally(() => setUpserting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    return (
        <div>
            <Title level={2}>Steps Module</Title>

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingMoveStep}
                    active={true}
                    paragraph={{rows: 20}}
                >
                    <span className="small_separation_text flex-12">
                        Global information
                    </span>

                    <InputWrapper
                        required
                        title="Name"
                        value={formik.values.name}
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />

                    <div className="flex-3">
                        <span>Image</span>
                        <ImageInput
                            url={formik.values.image ?? null}
                            folder="move-steps/image"
                            onChange={(v) => formik.setFieldValue("image", v)}
                            onDelete={() =>
                                formik.setFieldValue("image", null)
                            }
                        />
                    </div>
                </Skeleton>
            </div>

            <br/>

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingMoveStep}
                    active={true}
                    paragraph={{rows: 20}}
                >
                    <div className="flex-12">
                        <span className="small_separation_text">
                            Public information
                        </span>
                    </div>

                    <InputWrapper
                        required
                        title="Title Fr"
                        value={formik.values.titleFr}
                        name="titleFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                    <InputWrapper
                        required
                        title="Title En"
                        value={formik.values.titleEn}
                        name="titleEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                </Skeleton>
            </div>
            <br/>

            {params.id ? (
                <Button
                    onClick={editMoveStep}
                    type="primary"
                    loading={isUpserting}
                    disabled={!formik.isValid || !formik.dirty || isUpserting}
                >
                    Edit
                </Button>
            ) : (
                <Button
                    onClick={createMoveStep}
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty}
                >
                    Create
                </Button>
            )}
        </div>
    );
});
