import { useQuery } from '@apollo/client'

import { QueryResult } from '../../graphql'
import { useMoveStore } from '../../stores'
import { downloadFile } from '../../utils'
import { createMoveRatingCsvExport } from '../../utils/csv/moveRatingCsvHelper'
import { AggregatedRatings } from './ratings.models'
import * as queries from './ratings.queries'

/**
 * get aggregated rating stats
 */
export const useAggregatedRatings = ({ reload = false, ...queryArgs } = {}): QueryResult<AggregatedRatings> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, ...queryProps } = useQuery(queries.getAggregatedRatings, {
    fetchPolicy,
    ...queryArgs,
  })

  return {
    data: data?.getAggregatedRatings,
    loading: loading && networkStatus === 1,
    ...queryProps,
  }
}

/**
 * export all ratings into CSV
 */
export const useExportAction = async () => {
  const moveStore = useMoveStore()
  return async () => {
    const ratings = await moveStore.getAllRatings()
    downloadFile('ratings.csv', createMoveRatingCsvExport(ratings))
  }
}
