//@ts-ignore
const BUTTON_TYPES_CHOICES = [
    {
        display: "Normal",
        value: "",
    },
    {
        display: "Reverse",
        value: "reverse",
    },
    {
        display: "Gray",
        value: "gray",
    },
     {
        display: "Gray / Reverse",
        value: "gray-reverse",
    }
]

//@ts-ignore
export default BUTTON_TYPES_CHOICES;
//@ts-ignore
