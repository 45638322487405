import * as yup from "yup";
import { MomentSchema } from "../utils";
import { userSchemaRequired } from "./index";

export const moveSchema = yup
    .object({
        id: yup.string().required(),
        consent: yup.boolean().nullable(),
        date: new MomentSchema().nullable(),
        timeRange: yup.array(yup.string().required()).optional(),
        movingServices: yup.array(yup.string().required()).optional(),
        destinationResidenceParkingType: yup.string().nullable(),
        destinationResidenceParkingDistance: yup.string().nullable(),
        destinationResidenceStairs: yup.string().nullable(),
        destinationResidenceElevator: yup.string().nullable(),
        destinationResidenceAccess: yup.string().nullable(),
        destinationResidenceDimension: yup.string().nullable(),
        destinationResidenceLevel: yup.string().nullable(),
        destinationResidenceType: yup.string().nullable(),
        originResidenceParkingType: yup.string().nullable(),
        originResidenceParkingDistance: yup.string().nullable(),
        originResidenceStairs: yup.string().nullable(),
        originResidenceElevator: yup.string().nullable(),
        detectedOriginTimezone: yup.string().nullable(),
        externalId: yup.string().nullable(),
        fromAppt: yup.string().nullable(),
        fromCity: yup.string().nullable(),
        fromCountry: yup.string().nullable(),
        fromRegion: yup.string().nullable(),
        fromStreet: yup.string().nullable(),
        fromZip: yup.string().nullable(),
        movingStuff: yup.string().nullable(),
        movingStuffDatetime: new MomentSchema().nullable(),
        originResidenceAccess: yup.string().nullable(),
        originResidenceDimension: yup.string().nullable(),
        originResidenceLevel: yup.string().nullable(),
        originResidenceType: yup.string().nullable(),
        toAppt: yup.string().nullable(),
        toCity: yup.string().nullable(),
        toCountry: yup.string().nullable(),
        toRegion: yup.string().nullable(),
        toStreet: yup.string().nullable(),
        toZip: yup.string().nullable(),
        userId: yup.string().nullable(),
        createdAt: new MomentSchema().required(),
        updatedAt: new MomentSchema().required(),
        deletedAt: new MomentSchema().nullable(),
    })
    .required();

export const moveWithUserSchema = moveSchema.shape({
    user: userSchemaRequired
});

export type Move = yup.InferType<typeof moveSchema>;
export type MoveWithUser = yup.InferType<typeof moveWithUserSchema>;
