import * as yup from "yup";
import {
    SearchParams,
    WithNetworkConcurrency,
} from "../utils";
import {RootStore} from "./root";
import {observable, action} from "mobx";
import {
    OrganizationCategory,
    organizationCategorySchema,
} from "../schemas";
import {OrganizationCategoryForm} from "../forms/";

const organizationCategoryListSchema = yup
    .object({
        count: yup.number().required(),
        rows: yup.array().of(organizationCategorySchema).defined(),
    })
    .required();

export type OrganizationCategoryList = yup.InferType<typeof organizationCategoryListSchema>;

export class OrganizationCategoryStore extends WithNetworkConcurrency {
    // For the table presentation
    @observable organizationCategoryList: OrganizationCategoryList | null = null;
    @observable isFetchingListCount = 0;

    // For the type to search
    @observable selectionList: OrganizationCategory[] = [];
    @observable isFetchingSelectionCount = 0;


    @observable isPatching = false;
    @observable isCreating = false;

    constructor(private rootStore: RootStore) {
        super();
    }

    @action
    async fetchList(this: OrganizationCategoryStore, params: SearchParams) {
        const tag = this.getTag();
        this.isFetchingListCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "organization-categories",
                params,
            },
            organizationCategoryListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.organizationCategoryList = response.data;
        }
    }

    @action
    async fetchOne(this: OrganizationCategoryStore, id: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: `organization-categories/${id}`,
            },
            organizationCategorySchema
        );

        if (response.data) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async delete(
        this: OrganizationCategoryStore,
        organizationCategoryId: string,
        params: SearchParams | null
    ) {
        this.isFetchingListCount++;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `organization-categories/${organizationCategoryId}`,
        });

        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "organization-categories",
                params,
            },
            organizationCategoryListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.organizationCategoryList = response.data;
        }
    }


    @action
    async deleteCategoryType(
        this: OrganizationCategoryStore,
        organizationCategoryTypeId: string
    ) {

        const response = await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `organization-categories/type/${organizationCategoryTypeId}`,
        });

        if (response.err) {
            console.log("The error", response.err);
        } else {
            return response.data;
        }
    }


    @action
    async editOrder(this: OrganizationCategoryStore, organizationCategoryId: string, newIdx: number) {
        this.isPatching = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "POST",
                url: `organization-categories/${organizationCategoryId}/order`,
                data: {
                    order: newIdx
                },
            }
        );
        this.isPatching = false;

        if (response.err) {
            console.log("The error", response.err);
        } else {
            return response.data;
        }
    }

    @action
    async edit(this: OrganizationCategoryStore, organizationCategoryId: string, organizationCategoryForm: OrganizationCategoryForm) {
        this.isPatching = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "POST",
                url: `organization-categories/${organizationCategoryId}`,
                data: organizationCategoryForm,
            },
            organizationCategorySchema
        );
        this.isPatching = false;

        if (response.err) {
            console.log("The error", response.err);
        } else {
            return response.data;
        }
    }

    @action
    async create(this: OrganizationCategoryStore, organizationCategoryForm: OrganizationCategoryForm) {
        this.isCreating = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "POST",
                url: `organization-categories`,
                data: organizationCategoryForm,
            },
            organizationCategorySchema
        );
        this.isCreating = false;

        if (response.err) {
            console.log("The error", response.err);
        } else {
            return response.data;
        }
    }
}
