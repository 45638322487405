import React from "react";
import {observer} from "mobx-react";
import {Typography, Button, Skeleton, message} from "antd";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import {
    InputWrapper,
    SelectWrapper,
    SmartTrashIcon,
    SwitchWrapper
} from "../../components";
import cloneDeep from 'lodash/cloneDeep'
import {GROUP_CATEGORIES, reorderArr, useNavigation} from "../../utils";
import {OrganizationCategoryForm, organizationCategoryFormSchema} from "../../forms";
import {useOrganizationCategoryStore} from "../../stores";
import style from "./style.module.scss";
import IMAGES_CHOICES from "../../utils/images-choices";
import CATEGORY_ICONS_CHOICES from "../../utils/category-icons-choices";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {CountriesSelect} from "../../components/countries-select";


const groupCategories = [
    {
        value: '',
        display: 'None'
    },
    ...GROUP_CATEGORIES
]

const {Title} = Typography;

const initialValues = {
    titleFr: "",
    titleEn: "",
    headlineFr: "",
    headlineEn: "",
    ctaFr: "",
    ctaEn: "",
    handle: "",
    visibleInMove: true,
    groupHandle: '',
    image: '',
    icon: '',
    order: 99,
    country: 'CA',
    types: []
};

export const OrganizationCategoryPage = observer(() => {
    const navigation = useNavigation();
    const organizationCategoryStore = useOrganizationCategoryStore();
    const [isFetchingOrganizationCategory, setFetchingOrganizationCategory] = React.useState(false);
    const [isUpserting, setUpserting] = React.useState(false);
    const [typesIdCount, setTypesIdCount] = React.useState(0);
    const params = useParams<{ id?: string }>();
    const formik = useFormik<OrganizationCategoryForm>({
        initialValues,
        validationSchema: organizationCategoryFormSchema,
        validateOnMount: true,
        onSubmit: () => {
        }
    });

    React.useEffect(() => {
        if (params.id) {
            setFetchingOrganizationCategory(true);
            organizationCategoryStore
                .fetchOne(params.id)
                .then(m => formik.setValues(m))
                .finally(() => setFetchingOrganizationCategory(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const editOrganizationCategory = React.useCallback(() => {
        if (params.id) {
            setUpserting(true);
            organizationCategoryStore
                .edit(params.id, formik.values as OrganizationCategoryForm)
                .then(() => void message.success("Category edited"))
                .finally(() => setUpserting(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, formik.values]);


    const removeOrganizationCategoryType = React.useCallback((id) => {
        let values = cloneDeep(formik.values)
        let typeIdx = values.types.findIndex(t => t.id === id)
        if (typeIdx === -1) return
        let type = values.types[typeIdx]
        values.types.splice(typeIdx, 1)
        formik.setValues(values)

        //still not save on server
        if (!type.createdAt) {
            return
        }

        setUpserting(true);
        organizationCategoryStore
            .deleteCategoryType(id)
            .finally(() => {
                message.success("Type removed")
                setUpserting(false)
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    const createOrganizationCategory = React.useCallback(() => {
        setUpserting(true);
        organizationCategoryStore
            .create(formik.values as OrganizationCategoryForm)
            .then(() => {
                message.success("Category Created");
                navigation.goToOrganizationCategories();
            })
            .finally(() => setUpserting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    const createOrganizationCategoryType = React.useCallback(() => {
        let values = formik.values
        values.types.push({
            id: `${typesIdCount}-${typesIdCount}`,
            titleFr: "",
            titleEn: "",
            icon: '',
            order: values.types.length,
            handle: '',
            createdAt: undefined,
            updatedAt: undefined
        })
        setTypesIdCount(typesIdCount + 1)
        formik.setValues(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);


    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.source || !result.destination) {
            return;
        }

        let newTypes = formik.values.types.map(t => cloneDeep(t));

        //just a reorder
        newTypes = reorderArr(
            newTypes,
            result.source.index,
            result.destination.index
        );
        let values = formik.values

        //add new order in data
        values.types = newTypes.map((t, idx) => {
            t.order = idx
            return t
        })

        formik.setValues(values);
    }

    return (
        <div>
            <Title level={2}>Organization Category</Title>

            <div className="description-wrapper">
                <Skeleton
                    loading={isFetchingOrganizationCategory}
                    active={true}
                    paragraph={{rows: 20}}
                >
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            Global information
                        </span>


                        <InputWrapper
                            required
                            title="Title Fr"
                            value={formik.values.titleFr}
                            name="titleFr"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            flexSize={6}
                        />

                        <InputWrapper
                            required
                            title="Title En"
                            value={formik.values.titleEn}
                            name="titleEn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            flexSize={6}
                        />
                        <InputWrapper
                            required
                            title="Headline Fr"
                            value={formik.values.headlineFr}
                            name="headlineFr"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            flexSize={6}
                        />
                        <InputWrapper
                            required
                            title="Headline En"
                            value={formik.values.headlineEn}
                            name="headlineEn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            flexSize={6}
                        />
                        <InputWrapper
                            required
                            title="Call to action Fr"
                            value={formik.values.ctaFr}
                            name="ctaFr"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            flexSize={6}
                        />
                        <InputWrapper
                            required
                            title="Call to action En"
                            value={formik.values.ctaEn}
                            name="ctaEn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            flexSize={6}
                        />

                        <SelectWrapper
                            title="Image"
                            setFieldValue={formik.setFieldValue}
                            name="image"
                            flexSize={6}
                            value={formik.values.image ?? ""}
                            options={IMAGES_CHOICES}
                        />
                        <SelectWrapper
                            title="Icon"
                            setFieldValue={formik.setFieldValue}
                            name="icon"
                            flexSize={6}
                            value={formik.values.icon ?? ""}
                            options={CATEGORY_ICONS_CHOICES}
                        />
                        <div className="flex-4 flex-no-margin">
                            <InputWrapper
                                required
                                title="Handle (URL)"
                                value={formik.values.handle}
                                name="handle"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                flexSize={12}
                            />
                            <p className="flex-12">
                                Modify the handle at your own risk.<br/>Changing the handle may break the code.
                            </p>
                        </div>
                        <SelectWrapper
                            title="Group Categories"
                            setFieldValue={formik.setFieldValue}
                            name="groupHandle"
                            flexSize={4}
                            value={formik.values.groupHandle ?? ""}
                            options={groupCategories}
                        />

                        <SwitchWrapper
                            title="Is visible in move page"
                            checked={formik.values.visibleInMove ?? false}
                            name="visibleInMove"
                            flexSize={4}
                            setFieldValue={formik.setFieldValue}
                        />

                        <CountriesSelect
                            title="Countries"
                            value={formik.values.country ?? ""}
                            name="country"
                            setFieldValue={formik.setFieldValue}
                            flexSize={12}
                        />


                    </div>


                    {formik.values.types.length > 0 && (
                        <span className={"small_separation_text flex-12 " + style.marginTop}>
                            Types
                        </span>
                    )}


                    <div
                        style={{paddingBottom: 60}}
                    >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                                droppableId="types"
                                type="organization-type"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {formik.values.types.map((type, typeIdx) => (
                                            <Draggable
                                                key={type.id}
                                                draggableId={type.id}
                                                index={typeIdx}
                                            >
                                                {(
                                                    providedType,
                                                    snapshotType
                                                ) => (
                                                    <div key={typeIdx}
                                                         ref={providedType.innerRef}
                                                         {...providedType.draggableProps}
                                                         {...providedType.dragHandleProps}
                                                         className={"description-wrapper sub-category " + style.marginBottom}>
                                                        <div className="flex-wrapper">

                                                            <InputWrapper
                                                                required
                                                                name={`types[${typeIdx}].titleFr`}
                                                                title="Title Fr"
                                                                value={type.titleFr}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                flexSize={3}
                                                            />
                                                            <InputWrapper
                                                                required
                                                                title="Title En"
                                                                name={`types[${typeIdx}].titleEn`}
                                                                value={type.titleEn}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                flexSize={3}
                                                            />
                                                            <InputWrapper
                                                                required
                                                                title="Handle (URL)"
                                                                name={`types[${typeIdx}].handle`}
                                                                value={type.handle}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                flexSize={3}
                                                            />
                                                            <div className={style.deleteIcon}>
                                                                <SmartTrashIcon
                                                                    onConfirm={() => {
                                                                        removeOrganizationCategoryType(type.id)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>


                    <Button
                        onClick={createOrganizationCategoryType}
                        type="primary"
                        className={style.marginY}
                    >
                        Add Type
                    </Button>


                </Skeleton>
            </div>


            <br/>

            {params.id ? (
                <Button
                    onClick={editOrganizationCategory}
                    type="primary"
                    loading={isUpserting}
                    disabled={!formik.isValid || !formik.dirty || isUpserting}
                >
                    Edit
                </Button>
            ) : (
                <Button
                    onClick={createOrganizationCategory}
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty}
                >
                    Create
                </Button>
            )}
        </div>
    );
});
