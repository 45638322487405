import * as yup from "yup";
import {SearchParams} from "../utils";
import {RootStore} from "./root";
import {moveStepSchema, MoveStep} from "../schemas";
import {observable, action} from "mobx";
import {MoveStepForm} from "../forms";

type MoveStepsList = {
    count: number;
    rows: MoveStep[];
};

export class MoveStepStore {
    @observable moveStepList: MoveStepsList | null = null;
    @observable isFetchingList = false;

    constructor(private rootStore: RootStore) {
    }

    @action
    async fetchList(this: MoveStepStore, params: SearchParams) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "move-steps",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(moveStepSchema).required(),
            })
        );
        this.isFetchingList = false;

        if (response.data) {
            this.moveStepList = response.data;
        }
    }

    get moveCategoriesAsOptions(): { value: string; display: string }[] {
        return (this.moveStepList?.rows ?? []).map((m) => ({
            value: m.name ?? "",
            display: m.name ?? "",
        }));
    }

    async fetchMoveStep(this: MoveStepStore, moveStepId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `move-steps/${moveStepId}`,
            },
            moveStepSchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    async editMoveStep(this: MoveStepStore, moveStepId: string, moveStep: MoveStepForm) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: `move-steps/${moveStepId}`,
            data: moveStep,
        });

        if (response.err) {
            throw response.err;
        }
    }

    async createMoveStep(this: MoveStepStore, moveStep: MoveStepForm) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: `move-steps`,
            data: moveStep,
        });

        if (response.err) {
            throw response.err;
        }
    }

    @action
    async delete(this: MoveStepStore, moveStepId: string, params: SearchParams) {
        this.isFetchingList = true;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `move-steps/${moveStepId}`,
        });

        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "move-steps",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(moveStepSchema).defined(),
            })
        );
        this.isFetchingList = false;

        if (response.data) {
            this.moveStepList = response.data;
        }
    }
}
