export const query = () => {
  return new URLSearchParams(window.location.search);
}

export const getQuery = (queryName: string, defaultValue: any) => {
    let queryData = query();

    if (!queryData.has(queryName)){
        return defaultValue;
    }

    let value = queryData.get(queryName)

    if (!value){
        return defaultValue
    }

    return value;
}