import React from "react";
import {observer} from "mobx-react";
import {useUserStore} from "../../stores";
import {useLazyEffect} from "../../utils/user-lazy-effect";
import {User, Organization} from "../../schemas";
import {Table, Pagination, Typography, Input} from "antd";
import {ColumnProps} from "antd/es/table";
import {
    usePagination,
    useNavigation,
    createUsersCsv,
    downloadFile,
} from "../../utils";
import moment from "moment";
import {SmartTrashIcon, CsvExporter} from "../../components";
import style from "./style.module.scss";
import ExportMetro from "./ExportMetro";

const {Title} = Typography;
const FAKE_ORGS: Organization[] = [
    {

        id: "cogecoQc",
        country: "CA",
        descriptions: [
            {
                id: "cogecoQc",
                name: "Cogeco Québec",
                organizationId: "fake",
                descriptionLong: "",
                descriptionShort: "",
                lang: "fr",
                questions: "",
            },
        ],
        createdAt: moment(),
        deletedAt: null,
        updatedAt: moment(),
    },
    {
        id: "cogecoOn",
        country: "CA",
        descriptions: [
            {
                id: "cogecoOn",
                name: "Cogeco Ontario",
                organizationId: "fake-ontario",
                descriptionLong: "",
                descriptionShort: "",
                lang: "fr",
                questions: "",
            },
        ],
        createdAt: moment(),
        deletedAt: null,
        updatedAt: moment(),
    },
    {
        id: "telusQuebecCodes",
        country: "CA",
        descriptions: [
            {
                id: "telusQuebecCodes",
                name: "Telus Quebec",
                organizationId: "fake",
                descriptionLong: "",
                descriptionShort: "",
                lang: "fr",
                questions: "",
            },
        ],
        createdAt: moment(),
        deletedAt: null,
        updatedAt: moment(),
    },
];

export const UsersPage = observer(() => {
    const store = useUserStore();
    const navigation = useNavigation();
    const [params, setParams, setSearch, setAddToUrl] = usePagination();

    //set to true in mounted
    React.useEffect(() => {
        setAddToUrl(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLazyEffect(() => {
        store.fetchList({
            offset: params.offset,
            limit: params.limit,
            search: params.search,
        });

    }, [params], 500)


    const columns: ColumnProps<User>[] = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Pro User Referer",
            key: "proUserReferer",
            render: (_, record) => {
                if (!record.proReferer) {
                    return ''
                }
                return `${record.proReferer.refererToken}`
            },
        },
        {
            title: "Name",
            key: "name",
            render: (_, record) => `${record.firstname} ${record.lastname}`,
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        {
            title: "Creation Date",
            key: "creation_date",
            render: (_, record) =>
                moment(record.createdAt).format("YYYY-MM-DD"),
        },
        {
            title: "",
            key: "remove",
            render: (_, record) => (
                <SmartTrashIcon
                    onConfirm={() =>
                        store.delete(record.id, {
                            offset: params.offset,
                            limit: params.limit,
                            search: params.search,
                        })
                    }
                />
            ),
        },
    ];

    return (
        <div>
            <div className={style.top_content}>
                <div>
                    <Title level={2}>Users</Title>
                    <Input
                        className="search-input"
                        placeholder="search"
                        value={params.search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CsvExporter
                        list={FAKE_ORGS}
                        isListLoading={false}
                        onSearchChange={(search) => {
                        }}
                        onExportRequest={async (orgId, startDate, endDate) => {
                            const users = await store.fetchForCsv(
                                orgId,
                                startDate,
                                endDate,
                            )
                            downloadFile('users.csv', createUsersCsv(users ?? []));
                        }}
                    />
                    <ExportMetro />
                </div>
            </div>
            <Table<User>
                dataSource={store.userList?.rows}
                rowKey="id"
                columns={columns}
                loading={store.isFetchingListCount > 0}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => navigation.goToUser(record.id),
                })}
            />
            <br/>
            <Pagination
                showSizeChanger
                onChange={setParams}
                current={params.page}
                defaultCurrent={1}
                pageSize={params.pageSize}
                total={store.userList?.count}
            />
        </div>
    );
});
