import React, { useState } from 'react'
import { Button } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import moment from 'moment'

import { useUserStore } from '../../stores'
import { downloadFile } from "../../utils"
import { createMetroCsvExport } from "../../utils/csv/metroCsvHelper"
import styles from './style.module.scss'

const ExportMetro: React.FC = () => {
  const [ exporting, setExporting ] = useState(false)
  const userStore = useUserStore()

  const today = moment().format('YYYY-MM-DD')

  const onExport = async () => {
    try {
        setExporting(true)
        const metroUsers = await userStore.getMetroExport()
        downloadFile(`export-metro-${today}.csv`, createMetroCsvExport(metroUsers))
    } catch(error) {
    } finally {
        setExporting(false)
    }
}

  return (
    <div className={styles.exportMetro}>
    <Button
      icon={<CloudDownloadOutlined />}
      onClick={onExport}
      disabled={exporting}
    >
      { exporting ? 'Exporting ....' : 'Export Métro'  }
    </Button>
    </div>
  )
}

export default ExportMetro
