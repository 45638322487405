import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { useAggregatedRatings } from './ratings.hooks'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const Chart: React.FC = () => {
  const { data: aggregatedRatings } = useAggregatedRatings()

  if(!aggregatedRatings){
    return null
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
          beginAtZero: true
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: 'Last 12 months ratings',
      },
    },
  }

  const monthlyStats = Object.fromEntries(aggregatedRatings.averageRatingByMonth.map(({ month, averageRating }) => ([months[month - 1], averageRating])))

  const data = {
    labels: Object.keys(monthlyStats),
    datasets: [
      {
        label: 'Average rating',
        data: Object.values(monthlyStats),
        borderColor: 'rgb(24, 144, 255)',
        backgroundColor: 'rgba(24, 144, 255, 0.5)',
        tension: 0.3,
      },
    ],
  }

  return (
    <Line
      options={options}
      data={data}
      height={500}
    />
  )
}

export default Chart
