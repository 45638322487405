import * as yup from "yup";
import {
    SearchParams,
    WithNetworkConcurrency,
    getOrganizationName,
} from "../utils";
import { RootStore } from "./root";
import { observable, action } from "mobx";
import {
    Organization,
    organizationSchema,
    lightOrganizationSchema,
} from "../schemas";
import qs from "querystring";
import { OrganizationForm } from "../containers/organization/form";

const organizationListSchema = yup
    .object({
        count: yup.number().required(),
        rows: yup.array().of(lightOrganizationSchema).defined(),
    })
    .required();

export type OrganizationList = yup.InferType<typeof organizationListSchema>;

export class OrganizationStore extends WithNetworkConcurrency {
    // For the table presentation
    @observable organizationList: OrganizationList | null = null;
    @observable isFetchingListCount = 0;

    // For the type to search
    @observable selectionList: Organization[] = [];
    @observable isFetchingSelectionCount = 0;

    // For the deal
    @observable dealList: { value: string; display: string }[] = [];
    @observable isFetchingDeals = false;

    @observable isPatching = false;
    @observable isCreating = false;
    @observable isUploadingZipCode = false;
    @observable isUploadingZipCodeDestination = false;

    constructor(private rootStore: RootStore) {
        super();
    }

    @action
    async fetchList(this: OrganizationStore, params: SearchParams) {
        const tag = this.getTag();
        this.isFetchingListCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "organizations/paginated",
                params,
            },
            organizationListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.organizationList = response.data;
        }
    }

    @action
    async fetchSelectionList(this: OrganizationStore, search: string = "") {
        const tag = this.getTag();
        this.isFetchingSelectionCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "organizations/name",
                params: { search },
            },
            yup.array().of(organizationSchema)
        );
        this.isFetchingSelectionCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.selectionList = response.data;
        }
    }

    @action
    async fetchOne(this: OrganizationStore, id: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: `organizations/${id}`,
            },
            organizationSchema
        );

        if (response.data) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async fetchDeals(this: OrganizationStore) {
        this.isFetchingDeals = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: `/organizations?${qs.stringify({
                    offset: 0,
                    limit: 99999,
                    filters: `{"descriptions.lang": "fr", "categoryHandle":"deal"}`,
                    sort: "descriptions.name|ASC",
                })}`,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array().of(organizationSchema).defined(),
            })
        );
        this.isFetchingDeals = false;

        if (response.data) {
            const deals: { value: string; display: string }[] = [];
            for (const organization of response.data.rows ?? []) {
                const organizationName = getOrganizationName(organization);
                for (const buyOffer of organization.buyOffers ?? []) {
                    deals.push({
                        display: `${organizationName}/${buyOffer.shortDescriptionFr}`,
                        value: buyOffer.id,
                    });
                }
            }
            this.dealList = deals;
        }
    }

    @action
    async delete(
        this: OrganizationStore,
        organizationId: string,
        params: SearchParams
    ) {
        this.isFetchingListCount++;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `organizations/${organizationId}`,
        });

        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "organizations/paginated",
                params,
            },
            organizationListSchema
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.organizationList = response.data;
        }
    }

    @action
    async deleteBuyOffer(this: OrganizationStore, buyOfferId: string) {
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `buy-offers/${buyOfferId}`,
        });
    }

    @action
    async deleteBuyOfferZipCode(this: OrganizationStore, buyOfferId: string) {
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `buy-offers/${buyOfferId}/zip-codes`,
        });
    }

    @action
    async deleteBuyOfferZipCodeDestination(this: OrganizationStore, buyOfferId: string) {
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `buy-offers/${buyOfferId}/zip-codes-destination`,
        });
    }

    @action
    async uploadBuyOfferZipCode(
        this: OrganizationStore,
        buyOfferId: string,
        fileName: string,
        payload: unknown[]
    ) {
        this.isUploadingZipCode = true;
        const response = await this.rootStore.makeNetworkCall({
            method: "PATCH",
            url: `buy-offers/${buyOfferId}/zip-codes`,
            data: { fileName, payload },
        });
        this.isUploadingZipCode = false;

        return !response.err;
    }

    @action
    async uploadBuyOfferZipCodeDestination(
        this: OrganizationStore,
        buyOfferId: string,
        fileName: string,
        payload: unknown[]
    ) {
        this.isUploadingZipCodeDestination = true;
        const response = await this.rootStore.makeNetworkCall({
            method: "PATCH",
            url: `buy-offers/${buyOfferId}/zip-codes-destination`,
            data: { fileName, payload },
        });
        this.isUploadingZipCodeDestination = false;

        return !response.err;
    }

    @action
    async deleteEntity(this: OrganizationStore, entityId: string) {
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `organizations-entities/${entityId}`,
        });
    }

    @action
    async deleteExtraInfo(this: OrganizationStore, extraInfoId: string) {
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `buy-offer-extra-info/${extraInfoId}`,
        });
    }

    @action
    async deleteSection(this: OrganizationStore, sectionId: string) {
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `buy-offer-sections/${sectionId}`,
        });
    }

    @action
    async patch(this: OrganizationStore, organizationForm: OrganizationForm) {
        this.isPatching = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "PATCH",
                url: `organizations/${organizationForm.id}`,
                data: organizationForm,
            },
            organizationSchema
        );
        this.isPatching = false;

        if (response.err) {
            console.log("The error", response.err);
        } else {
            return response.data;
        }
    }

    @action
    async create(this: OrganizationStore, organizationForm: OrganizationForm) {
        this.isCreating = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "POST",
                url: `organizations`,
                data: organizationForm,
            },
            organizationSchema
        );
        this.isCreating = false;

        if (response.err) {
            console.log("The error", response.err);
        } else {
            return response.data;
        }
    }
}
