import { notification } from "antd";
import "./style.scss";

export const NotificationManager = {
    showError: (description: string, key?: string) => {
        notification.open({
            className: "notification-error",
            message: "An error has occured",
            description,
            key,
        });
    },
    showSuccess: (description: string, key?: string) => {
        notification.open({
            className: "notification-success",
            message: "Success",
            description,
            key,
        });
    },
};
