import * as yup from "yup";
import { SearchParams } from "../utils";
import { RootStore } from "./root";
import { moduleSchema, Module } from "../schemas";
import { observable, action } from "mobx";
import { ModuleForm } from "../forms";

type ModuleList = {
    count: number;
    rows: Module[];
};

export class ModuleStore {
    @observable moduleList: ModuleList | null = null;
    @observable isFetchingList = false;

    constructor(private rootStore: RootStore) {}

    @action
    async fetchList(this: ModuleStore, params: SearchParams) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "modules?all=1",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(moduleSchema).defined(),
            })
        );
        this.isFetchingList = false;

        if (response.data) {
            this.moduleList = response.data;
        }
    }

    get modulesAsOptions(): { value: string; display: string }[] {
        return (this.moduleList?.rows ?? [])
            .filter(m => !m.isPrivate)
            .map((m) => ({
                value: m.name ?? "",
                display: m.name ?? "",
            })
        );
    }

    get privateModulesAsOptions(): { value: string; display: string }[] {
        return (this.moduleList?.rows ?? [])
            .filter(m => m.isPrivate)
            .map((m) => ({
                value: m.name ?? "",
                display: m.name ?? "",
            })
        );
    }

    async fetchModule(this: ModuleStore, moduleId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `modules/${moduleId}`,
            },
            moduleSchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    async editModule(this: ModuleStore, moduleId: string, module: ModuleForm) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: `modules/${moduleId}`,
            data: module,
        });

        if (response.err) {
            throw response.err;
        }
    }

    async createModule(this: ModuleStore, module: ModuleForm) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: `modules`,
            data: module,
        });

        if (response.err) {
            throw response.err;
        }
    }

    @action
    async delete(this: ModuleStore, moduleId: string, params: SearchParams) {
        this.isFetchingList = true;

        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `modules/${moduleId}`,
        });

        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "modules",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(moduleSchema).defined(),
            })
        );
        this.isFetchingList = false;

        if (response.data) {
            this.moduleList = response.data;
        }
    }
}
