import * as yup from "yup";
import {MomentSchema} from "../utils";

export const moveCategorySchema = yup
    .object({
        id: yup.string().required(),
        titleFr: yup.string().required(),
        titleEn: yup.string().required(),
        name: yup.string().nullable(),
        order: yup.number().required().nullable(),
        image: yup.string().nullable(),
        timeline: yup.string().nullable(),
        createdAt: new MomentSchema().required(),
        updatedAt: new MomentSchema().required(),
        deletedAt: new MomentSchema().nullable(),

        //for list
        children: yup.array().optional()
    })
    .required();

export type MoveCategory = yup.InferType<typeof moveCategorySchema>;
