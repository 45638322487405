import Papa from 'papaparse'

const BOM = '\uFEFF'

const userFriendlyColumns: Record<string, string> = {
    userCreatedAt: 'createdAt',
    userId: 'ID',
    userRoles: 'Roles',
    userFirstName: 'First Name',
    userLastName: 'Last Name',
    userGender: 'M/F',
    userBirthDate: 'Birthdate (YYYY-MM-DD)',
    userEmail: 'Email Address',
    userPhone: 'Phone',
    userPhoneType: 'Phone Type (cell/home/work)',
    userReachTime: 'Best Time to Reach',
    userAgreementSignedAt: 'Agreement Signed At (YYYY-MM-DD)',
    moveFromAddress: 'From Address',
    moveFromStreet: 'From add street',
    moveFromCity: 'From add city',
    moveFromRegion: 'From add province',
    moveFromZip: 'From add ZIP',
    moveFromAppt: 'From add Apt',
    moveToAddress: 'To Address',
    moveToStreet: 'To add street',
    moveToCity: 'To add city',
    moveToRegion: 'To add province',
    moveToZip: 'To add ZIP',
    moveToAppt: 'To add Apt',
    moveDate: 'Moving Date (YYYY-MM-DD)',
    moveConsent: 'Consent (y/n)',
    moveNeedTelcom: 'Need Telecoms',
    moveTelecomProvider: 'Telco Provider',
    moveHasInsurance: 'Has insurance (y/n)',
    moveHomeInsuranceRenewalMonth: 'Renewal Month',
    moveHomeOwnership: 'Own/rent/co-op',
    userLanguage: 'Language',
}

export const createUsersCsv = (users: Record<string, any>[]) => {
    if(!Array.isArray(users) || users.length === 0){
        return ''
    }
     const csv =  Papa.unparse([
        Object.keys(users[0]).map((key: string) => {
            if(/^user/.test(key)){
                return 'User'
            }
            if(/^move/.test(key)){
                return 'Move'
            }
            return key
        }),
        Object.keys(users[0]).map((key: string) => userFriendlyColumns[key] ?? key),
        ...users.map(row => Object.values(row))
    ])
    return BOM + csv
}
