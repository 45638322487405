import * as yup from "yup";
import {
    buyRequestSchema,
    moveWithUserSchema,
    moveRequestSchema,
} from "./index";

export const moveWithRequestsSchema = moveWithUserSchema.shape({
    requests: yup.array(moveRequestSchema),
    buyRequests: yup.array(buyRequestSchema),
});

export type MoveWithRequests = yup.InferType<typeof moveWithRequestsSchema>;
