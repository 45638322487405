import React from "react";
import { Moment } from "moment";
import { Select, DatePicker, Button } from "antd";
import { Organization } from "../../schemas";
import { DownloadOutlined } from "@ant-design/icons";
import style from "./style.module.scss";

const { RangePicker } = DatePicker;

interface Props {
    list: Organization[];
    isListLoading: boolean;
    onSearchChange: (search: string) => void;
    onExportRequest: (
        orgId: string | undefined,
        startDate: Moment,
        endDate: Moment
    ) => Promise<void>;
}

export const CsvExporter = (props: Props) => {
    const [startDate, setStartDate] = React.useState<Moment | null>(null);
    const [endDate, setEndDate] = React.useState<Moment | null>(null);
    const [orgId, setOrgId] = React.useState<string | undefined>();
    const [isExecutingAsync, setExecutionAsync] = React.useState(false);

    const canExport = Boolean(startDate && endDate);

    const onButtonClick = async () => {
        if (canExport) {
            try {
                setExecutionAsync(true);
                await props.onExportRequest(orgId, startDate!!, endDate!!);
            } finally {
                setExecutionAsync(false);
            }
        }
    };

    return (
        <div className={style.container}>
            <Select
                placeholder="Organization"
                onChange={(id) => setOrgId(id)}
                value={orgId}
                allowClear
                showSearch
                filterOption={false}
                onSearch={props.onSearchChange}
                loading={props.isListLoading}
                style={{ width: "200px" }}
            >
                {props.list.map((organization) => (
                    <Select.Option
                        value={organization.id ?? ""}
                        key={organization.id}
                    >
                        {organization.descriptions?.[0]?.name}
                    </Select.Option>
                ))}
            </Select>

            <RangePicker
                format="YYYY-MM-DD HH:mm"
                onCalendarChange={(dates) => {
                    if (dates !== null) {
                        setStartDate(dates[0]);
                        setEndDate(dates[1]);
                    }
                }}
                showTime={{
                    format: "HH:mm",
                    minuteStep: 15,
                }}
            />
            <Button
                type="primary"
                icon={<DownloadOutlined />}
                size="middle"
                disabled={!canExport}
                onClick={onButtonClick}
                loading={isExecutingAsync}
            >
                Export CSV
            </Button>
        </div>
    );
};
