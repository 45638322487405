const DISPLAY_CHOICES = [
    {
        display: "Broker name",
        value: "broker"
    },
    {
        display: "Company name",
        value: "company"
    },
];

export default DISPLAY_CHOICES;
