import React, { useState, useMemo } from 'react'
import {
  Button,
  Select,
  List,
  Typography,
  Skeleton,
} from "antd";
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";

import { OrganizationCategory, BrokerOrganizationRulesSchema } from '../../schemas'

type IndexedCategories = Record<string, {
  id: string
  titleEn: string
  available: boolean
}>

type OrganizationRulesProps = {
  rules: BrokerOrganizationRulesSchema[]
  organizations: Record<string, [string, string][]>
  organizationCategories?: OrganizationCategory[]
  name: string
  setFieldValue: Function
}

const OrganizationRules: React.FC<OrganizationRulesProps> = ({
  rules,
  organizations,
  organizationCategories,
  name,
  setFieldValue,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string|undefined>()
  const categories = useMemo(() => {
    if(!organizationCategories?.length){
      return undefined
    }
    const indexed:IndexedCategories = {}
    organizationCategories
    .forEach(({id, titleEn}) => {
      indexed[id] = {
        id,
        titleEn,
        available: !rules.find(rule => rule.organizationCategoryId === id)
      }
    })
    return indexed
  }, [organizationCategories, rules])

  const sortRules = (rules: BrokerOrganizationRulesSchema[]):  BrokerOrganizationRulesSchema[] => {
    return rules.sort((a, b) => {
      const titleA = categories?.[a.organizationCategoryId]?.titleEn || a.organizationCategoryId
      const titleB = categories?.[b.organizationCategoryId]?.titleEn || b.organizationCategoryId
      if(titleA < titleB){
        return -1
      }
      if(titleA > titleB){
        return 1
      }
      return 0
    })
  }

  const addCategory = () => {
    if(!selectedCategory){
      return
    }
    setFieldValue(
      name,
      [
        {
          rule: 'include-only',
          organizationCategoryId: selectedCategory,
          organizationsId: [],
        },
        ...rules,
      ]
    )
    setSelectedCategory(undefined)
  }

  const removeCategory = (categoryId: string) => {
    setFieldValue(
      name,
      [...rules.filter(({organizationCategoryId}) => organizationCategoryId !== categoryId)]
    )
  }

  const changeRule = (value: string, categoryId: string) => {
    const index = rules.findIndex(({organizationCategoryId}) => organizationCategoryId === categoryId)

    const newRules = [...rules]
    newRules[index] = {
      ...newRules[index],
      rule: value
    }

    setFieldValue(
      name,
      newRules
    )
  }

  const changeOrganizations = (values:string[], categoryId: string) => {
    const index = rules.findIndex(({organizationCategoryId}) => organizationCategoryId === categoryId)

    const newRules = [...rules]
    newRules[index] = {
      ...newRules[index],
      organizationsId: values,
    }

    setFieldValue(
      name,
      newRules
    )
  }

  if(!categories || Object.keys(organizations).length === 0){
    return <Skeleton active />
  }

  return (
    <div className="flex-12">
      <div className="flex-6">
        <div className="flex-wrapper flex-" style={{ alignItems: 'center', paddingBottom: '0.5rem' }}>
          Add organization rule for 
          <Select
            value={selectedCategory}
            onChange={setSelectedCategory}
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ flex: 1, margin: '0 1rem' }}
          >
            {Object.values(categories)
            .sort((a, b) => {
              if(a.titleEn < b.titleEn){
                return -1
              }
              if(a.titleEn > b.titleEn){
                return 1
              }
              return 0
            })
            .filter(category => category.available).map(({id, titleEn}) => (
              <Select.Option key={id} value={id}>{titleEn}</Select.Option>
            ))}
          </Select>

          <Button
            icon={<PlusOutlined />}
            disabled={!selectedCategory}
            onClick={addCategory}
          >
            Add
          </Button>
        </div>
      </div>

      {rules.length > 0 && (
        <>
          <List bordered style={{ background: 'rgb(252, 252, 252)' }}>
            {
              sortRules(rules).map((rule) => (
              <List.Item key={rule.organizationCategoryId} style={{ display: 'block' }}>
                <Typography.Title level={5}>
                  { categories[rule.organizationCategoryId]?.titleEn ?? rule.organizationCategoryId }
                    <Button
                      shape="circle"
                      size="small"
                      danger
                      onClick={() => removeCategory(rule.organizationCategoryId)}
                      style={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '0.5rem',
                        width: 18,
                        minWidth: 18,
                        height: 18,
                      }}
                    >
                      <MinusOutlined style={{ paddingTop: 2 }} />
                    </Button>
                </Typography.Title>
                <div className="flex-wrapper">
                  <div className="flex-wrapper flex-4">
                  <Select
                    defaultValue="include-only"
                    value={rule.rule}
                    onChange={value => changeRule(value, rule.organizationCategoryId)}
                    style={{width: '100%', height: 32}}
                  >
                    <Select.Option value="include-only">
                      Include only these companies
                    </Select.Option>
                    <Select.Option value="add">
                      Add these companies
                    </Select.Option>
                    <Select.Option value="exclude">
                      Exclude these companies
                    </Select.Option>
                  </Select>
                  </div>
                  <div className="flex-wrapper flex-8">
                  <Select
                    mode="multiple"
                    value={rule.organizationsId ?? []}
                    onChange={values => changeOrganizations(values, rule.organizationCategoryId)}
                    style={{ flex: 1 }}
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    { organizations[rule.organizationCategoryId]?.map(([organizationId, organizationName]) => (
                      <Select.Option key={organizationId} value={organizationId} label={organizationName}>
                        {organizationName}
                      </Select.Option>
                    )) }
                  </Select>
                  </div>
                </div>
              </List.Item>
              ))
            }
          </List>
        </>
      )}
    </div>
  )
}

export default OrganizationRules
