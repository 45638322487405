import { SOURCE_MOVINGWALDO, SOURCE_CENTRIS } from './sources.const'

const SOURCE_CHOICES = [
    {
        display: "MovingWaldo",
        value: SOURCE_MOVINGWALDO
    },
    {
        display: "Centris",
        value: SOURCE_CENTRIS
    },
];


export default SOURCE_CHOICES;

