export const Routes = {
    HOME: "/",
    LOGIN: "/login",
    MOVE_REQUESTS: "/move-requests",
    BUY_REQUESTS: "/buy-requests",
    ORGANIZATIONS: "/organizations",
    ORGANIZATION_CATEGORIES: "/organization-categories",
    USERS: "/users",
    MODULES: "/modules",
    MODULES_CUSTOM: "/modules-custom",
    BROKERS: "/brokers",
    MOVE_STEPS: "/move-steps",
    MOVE_CATEGORIES: "/move-categories",
    RATINGS: "/ratings",
    CONFIG: "/config",
} as const;
