import Papa from 'papaparse'

const BOM = '\uFEFF'

export const createMoveRatingCsvExport = (rows?: Record<string, any>[]) => {
    if(!Array.isArray(rows) || rows.length === 0){
        return ''
    }
    const csv =  Papa.unparse([
        Object.keys(rows[0]),
        ...rows.map(row => Object.values(row)),
    ])

    return BOM + csv
}
