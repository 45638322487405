import * as yup from "yup";
import { moveSchema } from "../schemas";
import { RootStore } from "./root";
import { action } from "mobx";

export class MoveStore {
    constructor(private rootStore: RootStore) {}

    @action
    async fetchForUser(this: MoveStore, userId: string) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `moves`,
                params: { filters: `{ "userId": "${userId}" }` },
            },
            yup.object({
                rows: yup.array(moveSchema).defined(),
                count: yup.number(),
            })
        );

        if (response.data) {
            return response.data.rows;
        } else {
            throw response.err;
        }
    }

    @action
    getAllRatings(this: MoveStore) {
        return this.rootStore.callApiV2({
            url: 'moves/ratings/export'
        })
    }
}
