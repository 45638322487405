import * as yup from "yup";
import {MomentSchema} from "../utils";
import {
    brokerInfoSchema,
    brokerSchema,
    moduleSchema,
    moveCategorySchema,
    moveStepSchema,
} from "../schemas";

/**
 *
 *
 * Broker form
 *
 *
 */
 const brokerInfoFormSchema = brokerInfoSchema.shape({
    id: yup.string(),
});

const brokerOrganizationRulesFormSchema = yup.object({
    id: yup.string(),
    rule: yup.string().required(),
    organizationCategoryId: yup.string().required(),
    organizationsId: yup.array(yup.string().required()).required(),
});

export type BrokerOrganizationRulesFormSchema = yup.InferType<typeof brokerOrganizationRulesFormSchema>;

export const brokerFormSchema = brokerSchema.shape({
    id: yup.string(),
    country: yup.string().required(),
    preferredLang: yup.string().required(),
    info: yup
        .array(brokerInfoFormSchema.required())
        .min(2)
        .max(2)
        .required(),
});

export type BrokerForm = yup.InferType<typeof brokerFormSchema>;

/**
 *
 *
 * Module form
 *
 *
 */
export const moduleFormSchema = moduleSchema.shape({
    id: yup.string(),
    name: yup.string().required(),
    updatedAt: new MomentSchema().notRequired(),
    createdAt: new MomentSchema().notRequired(),
    deletedAt: new MomentSchema().nullable().notRequired(),
});

export type ModuleForm = yup.InferType<typeof moduleFormSchema>;


/**
 *
 *
 * Move Category form
 *
 *
 */
export const moveCategoryFormSchema = moveCategorySchema.shape({
    id: yup.string(),
    name: yup.string().required(),
    updatedAt: new MomentSchema().notRequired(),
    createdAt: new MomentSchema().notRequired(),
    deletedAt: new MomentSchema().nullable().notRequired(),
});

export type MoveCategoryForm = yup.InferType<typeof moveCategoryFormSchema>;


/**
 *
 *
 * Move Step form
 *
 *
 */
export const moveStepFormSchema = moveStepSchema.shape({
    id: yup.string(),
    name: yup.string().required(),
    updatedAt: new MomentSchema().notRequired(),
    createdAt: new MomentSchema().notRequired(),
    deletedAt: new MomentSchema().nullable().notRequired(),
});

export type MoveStepForm = yup.InferType<typeof moveStepFormSchema>;

export const organizationCategoryTypeFormSchema = yup
    .object({
        id: yup.string().required(),
        titleFr: yup.string().required(),
        titleEn: yup.string().required(),
        handle: yup.string().required(),
        icon: yup.string().nullable(),
        order: yup.number().required(),
        updatedAt: new MomentSchema().notRequired().default(undefined),
        createdAt: new MomentSchema().notRequired().default(undefined)
    })
    .required();

export const organizationCategoryFormSchema = yup
    .object({
        id: yup.string(),
        titleFr: yup.string().required(),
        titleEn: yup.string().required(),
        headlineFr: yup.string(),
        headlineEn: yup.string(),
        order: yup.number(),
        ctaFr: yup.string(),
        ctaEn: yup.string(),
        handle: yup.string().required(),
        image: yup.string().nullable(),
        icon: yup.string().nullable(),
        country: yup.string().required(),
        groupHandle: yup.string().nullable(),
        visibleInMove: yup.boolean().required(),
        types: yup.array(organizationCategoryTypeFormSchema).defined().min(0),
    })
    .required();

export type OrganizationCategoryTypeForm = yup.InferType<typeof organizationCategoryTypeFormSchema>;
export type OrganizationCategoryForm = yup.InferType<typeof organizationCategoryFormSchema>;
