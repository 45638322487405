import * as yup from "yup";
import {MomentSchema} from "../utils";

export const buyOfferSchema = yup
    .object({
        contactLanguage: yup.string().nullable(),
        contactMethod: yup.string().nullable(),
        createdAt: new MomentSchema().nullable(),
        customMessageEn: yup.string().nullable(),
        customMessageFr: yup.string().nullable(),
        deletedAt: new MomentSchema().nullable(),
        email: yup.string().nullable(),
        emailCC: yup.string().nullable(),
        filter: yup.string().nullable(),
        id: yup.string().required(),
        imageMediumUrl: yup.string().nullable(),
        imageSmallUrl: yup.string().nullable(),
        longDescriptionEn: yup.string().nullable(),
        longDescriptionFr: yup.string().nullable(),
        organizationId: yup.string().nullable(),
        phoneNumber: yup.string().nullable(),
        price: yup.number().nullable(),
        pricingInfoEn: yup.string().nullable(),
        pricingInfoFr: yup.string().nullable(),
        regions: yup.string().nullable(),
        shortDescriptionEn: yup.string().nullable(),
        shortDescriptionFr: yup.string().nullable(),
        smartMovingUrl: yup.string().nullable(),
        tagEn: yup.string().nullable(),
        tagFr: yup.string().nullable(),
        titleEn: yup.string().nullable(),
        titleFr: yup.string().nullable(),
        trackingId: yup.string().nullable(),
        types: yup.array(yup.string().required()),
        updatedAt: new MomentSchema().nullable(),
        urlEn: yup.string().nullable(),
        urlFr: yup.string().nullable(),
        zipCodeFile: yup.string().nullable(),
        country: yup.string().nullable(),
    })
    .required();

export type BuyOffer = yup.InferType<typeof buyOfferSchema>;
