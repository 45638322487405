import * as React from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

const converter = new Showdown.Converter({
    tables: false,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: false,
    simpleLineBreaks: true
});

interface Props {
    value: string;
    setValue: (value: string) => void;
}

export default function MarkDownEditor(props: Props) {
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");

    const toolbars = [
        ["header", "bold", "italic", "strikethrough"],
        ["link", "quote"],
        ["unordered-list", "ordered-list"]
    ];
    return (
        <div className="container">
            <ReactMde
                toolbarCommands={toolbars}
                value={props.value}
                onChange={props.setValue}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={markdown =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
            />
        </div>
    );
}
