import produce from "immer";
import * as yup from "yup";
import {action} from "mobx";
import axios, {AxiosRequestConfig, AxiosError} from "axios";
import {NotificationManager} from "../components";
import {MoveRequestStore} from "./move-request";
import {OrganizationStore} from "./organizations";
import {UserStore} from "./users";
import {MoveStore} from "./moves";
import {ModuleStore} from "./modules";
import {MoveCategoryStore} from "./move-categories";
import {MoveStepStore} from "./move-steps";
import {BrokerStore} from "./brokers";
import {AuthStore} from "./auth";
import {BuyRequestStore} from "./buy-requests";
import {OrganizationCategoryStore} from "./organization-categories";
import {GlobalStore} from "./global";
import Cookies from "js-cookie";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const API_V2_URL = process.env.REACT_APP_API_V2_URL

const isAxiosError = (error: any): error is AxiosError => {
    return error && error.isAxiosError;
};

export class RootStore {
    public moveRequestStore = new MoveRequestStore(this);
    public organizationStore = new OrganizationStore(this);
    public userStore = new UserStore(this);
    public moveStore = new MoveStore(this);
    public moduleStore = new ModuleStore(this);
    public moveStepStore = new MoveStepStore(this);
    public moveCategoryStore = new MoveCategoryStore(this);
    public brokerStore = new BrokerStore(this);
    public authStore = new AuthStore();
    public buyRequestStore = new BuyRequestStore(this);
    public organizationCategoryStore = new OrganizationCategoryStore(this);
    public globalStore = new GlobalStore(this);

    /**
     * Helper method to wrap all the chores associated with networks requests.
     *  • JWT management
     *  • Error handling
     *  • Base url
     *  • Payload validation
     *
     * @param this
     * @param opts
     * @param schema
     */
    @action
    async makeNetworkCall<NetworkResponse>(
        this: RootStore,
        opts: AxiosRequestConfig,
        schema?: yup.Schema<NetworkResponse>,
        validationOptions?: yup.ValidateOptions
    ): Promise<{ data: NetworkResponse; err: null } | { data: null; err: true }> {
        try {
            const augmentedOpts = produce(opts, (draft) => {
                if (!draft.headers) {
                    draft.headers = {}
                }
                draft.headers["accept-language"] = "en";
                draft.headers["Authorization"] = ` Bearer ${this.authStore.jwt}`;
                draft.headers["x-countries-code"] = this.globalStore.countriesSelected.join('|');

                draft.baseURL = `${REACT_APP_API_URL}`;
            });

            const response = await axios.request<NetworkResponse>(
                augmentedOpts
            );

            if (schema) {
                const validatedData = await schema.validate(
                    response.data,
                    validationOptions
                );
                return {data: validatedData, err: null};
            }
            return {data: response.data, err: null};
        } catch (e) {
            if (isAxiosError(e)) {
                const status = e.response?.status;
                const unauthorized = status === 401
                const forbidden = status === 403

                if (unauthorized || forbidden || !e.response) {
                    this.authStore.setJwt(null);
                }
                if(unauthorized){
                    NotificationManager.showError('Your session has expired', 'expired_session');
                }
                if(unauthorized || forbidden){
                    return {data: null, err: true}
                }
            }
            console.log("The error", e);
            NotificationManager.showError(e.response?.data?.message);
            return {data: null, err: true};
        }
    }

    /**
     * Call the API (V2)
     * this doesn't use API Gateway and send the session token instead of the JWT Token
     */
    async callApiV2(request: AxiosRequestConfig){
        try {
            const { data } = await axios.request({
                ...request,
                headers: {
                    ...request?.headers,
                    'x-token': Cookies.get('token'),
                },
                baseURL: API_V2_URL,
            })
            return data
        } catch(error) {
            if (isAxiosError(error)) {
                const status = error.response?.status
                const unauthorized = status === 401

                if(unauthorized){
                    Cookies.remove('token')
                    NotificationManager.showError('Your session has expired', 'expired_session')
                    return
                }
            }
            console.error('An error occured calling API V2', error)
            const message = (error as any)?.response?.data?.message
            if(message){
                NotificationManager.showError(message)
            }
        }
    }
}