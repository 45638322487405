import React from "react";
import { Lang, getItemProp } from "../../utils";
import { InputWrapper } from "../input-wrapper";
import produce from "immer";

type Translation = {
    id?: string;
    lang: Lang;
    value?: string;
};

type Question = {
    id?: string;
    organizationId?: string;
    translations?: Translation[];
};

type Props = {
    questions?: Question[];
    organizationId?: string;
    name: string;
    setFieldValue: (name: string, value: unknown) => void;
    lang: Lang;
    onBlur: (e: React.FocusEvent) => void;
};

export const QuestionsWrapper = (props: Props) => {
    const [innerValue, setInnerValue] = React.useState(() => {
        const values = props.questions
            ?.map((question) =>
                getItemProp(question.translations, props.lang, "value")
            )
            .filter((v) => !!v);
        return values?.join("|") ?? "";
    });

    const onChange = (e: React.ChangeEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setInnerValue(value);
        const rawQuestions = value.split("|");
        const maxLength = Math.max(
            rawQuestions.length,
            props.questions?.length ?? 0
        );
        const newQuestionsValue = produce(props.questions, () => {}) ?? [];

        for (let i = 0; i < maxLength; i++) {
            const rawQuestion = rawQuestions[i];
            const question = props.questions?.[i];

            if (!question) {
                newQuestionsValue?.push({
                    organizationId: props.organizationId,
                    translations: [{ lang: props.lang, value: rawQuestion }],
                });
            } else if (!rawQuestion) {
                const index = newQuestionsValue[i].translations?.findIndex(
                    (t) => t.lang === props.lang
                );
                if (index != null && index !== -1) {
                    newQuestionsValue[i].translations?.splice(index, 1);
                }
                if (newQuestionsValue[i].translations?.length === 0) {
                    newQuestionsValue.splice(i, 1);
                }
            } else {
                const index = newQuestionsValue[i].translations?.findIndex(
                    (t) => t.lang === props.lang
                );

                if (index != null && index !== -1) {
                    newQuestionsValue[i].translations![
                        index
                    ].value = rawQuestion;
                } else {
                    newQuestionsValue[i].translations!.push({
                        lang: props.lang,
                        value: rawQuestion,
                    });
                }
            }

            props.setFieldValue("questions", newQuestionsValue);
        }
    };

    return (
        <InputWrapper
            value={innerValue}
            name={props.name}
            title="Questions"
            onBlur={props.onBlur}
            onChange={onChange}
            flexSize={12}
        />
    );
};
