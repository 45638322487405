const LANGUAGE_CHOICES = [
    {
        display: "English",
        value: "en"
    },
    {
        display: "French",
        value: "fr"
    },
];

export default LANGUAGE_CHOICES;
