import React from 'react'
import {Button} from "antd";
import {ButtonType} from 'antd/lib/button'
import {useGlobalStore} from "../../stores";
import style from './style.module.scss'

interface Props {

}

export const CountriesBtns = (props: Props) => {
    const globalStore = useGlobalStore()

    return (
        <div className={style.btns_row}>
            {globalStore.countries.map((country: any) => {
                let type: ButtonType = globalStore.countriesSelected.includes(country.id) ? 'primary' : 'default'
                return (
                    <Button key={country.id} type={type}
                            onClick={() => globalStore.toggleCountry(country.id)}
                    >
                        {country.name}
                    </Button>
                )
            })}
        </div>
    )
}