//@ts-ignore
const BUTTON_ICONS_CHOICES = [
    {
        display: "None",
        value: "",
    },
    {
        display: "Arrow",
        value: "arrow",
    },
    {
        display: "Phone",
        value: "phone",
    },
    {
        display: "Book",
        value: "book",
    },
    {
        display: "Cart",
        value: "cart",
    }
]

//@ts-ignore
export default BUTTON_ICONS_CHOICES;
//@ts-ignore
