import React from 'react'
import moment from 'moment'
import {
  Input,
  InputNumber,
  Switch,
  DatePicker,
  } from 'antd'

import { Config } from './config.model'

const parseValue = (value: any) => {
  try {
    return JSON.parse(value)
  } catch(error){
    return ''
  }
}

type ConfigFieldProps = {
  config: Config
  onChange: (value: string) => void
}

const ConfigField: React.FC<ConfigFieldProps> = ({
  config,
  onChange,
}) => {
  if(config.type === 'string'){
    return (
      <Input
        value={parseValue(config.value)}
        onChange={(event) => {
          onChange(JSON.stringify(event.target.value))
        }}
      />
    )
  }

  if(config.type === 'number'){
    return (
      <InputNumber
        style={{ minWidth: 200 }}
        value={config.value}
        onChange={value => {
          onChange(JSON.stringify(value))
        }}
      />
    )
  }

  if(config.type === 'boolean'){
    return (
      <Switch
        checked={config.value === 'true'}
        onChange={checked => {
          onChange(checked ? 'true' : 'false')
        }}
      />
    )
  }

  if(config.type === 'date'){
    return (
      <DatePicker
        value={config.value ? moment(parseValue(config.value)) : undefined}
        onChange={date => {
          onChange(date ? JSON.stringify(date.toISOString()) : '')
        }}
        showTime
      />
    )
  }

  return null
}

export default ConfigField
