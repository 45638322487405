import * as yup from "yup";

export const regionSchema = yup
    .object({
        id: yup.string().required(),
        name: yup.string().required(),
        countryId: yup.string().required()
    })

export type Region = yup.InferType<typeof regionSchema>;
