import * as yup from "yup";
import { SearchParams, WithNetworkConcurrency } from "../utils";
import { RootStore } from "./root";
import {
    brokerSchema,
    lightBrokerSchema,
    LightBroker,
} from '../schemas';
import { observable, action } from "mobx";
import { BrokerForm } from "../forms";

type BrokersList = {
    count: number;
    rows?: LightBroker[];
};

export class BrokerStore extends WithNetworkConcurrency {
    @observable list: BrokersList | null = null;
    @observable isFetchingListCount = 0;
    @observable allBrokers: Record<string, any>[] | undefined;

    constructor(private rootStore: RootStore) {
        super();
    }

    @action
    async getAllBrokers(this: BrokerStore) {
        const brokers = await this.rootStore.callApiV2({
            url: 'brokers'
        })
        this.allBrokers = brokers
        return brokers
    }

    @action
    async fetchList(this: BrokerStore, params: SearchParams) {
        this.isFetchingListCount++;
        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "brokers",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(lightBrokerSchema).defined(),
            })
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.list = response.data;
        }
    }

    async fetchBroker(
        this: BrokerStore,
        brokerId: string
    ) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "get",
                url: `brokers/${brokerId}?stats=1`,
            },
            brokerSchema
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    async editBroker(
        this: BrokerStore,
        brokerId: string,
        broker: BrokerForm
    ) {
        const response = await this.rootStore.makeNetworkCall({
            method: "put",
            url: `brokers/${brokerId}`,
            data: broker,
        });

        if (response.err) {
            throw response.err;
        }
        return response.data
    }

    async createBroker(
        this: BrokerStore,
        broker: BrokerForm
    ) {
        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            url: 'brokers',
            data: broker,
        });

        if (response.err) {
            throw response.err;
        }
    }

    async delete(
        this: BrokerStore,
        brokerId: string,
        params: SearchParams
    ) {
        this.isFetchingListCount++;
        await this.rootStore.makeNetworkCall({
            method: "DELETE",
            url: `brokers/${brokerId}`,
        });
        const tag = this.getTag();
        const response = await this.rootStore.makeNetworkCall(
            {
                method: "GET",
                url: "brokers",
                params,
            },
            yup.object({
                count: yup.number().required(),
                rows: yup.array(lightBrokerSchema).defined(),
            })
        );
        this.isFetchingListCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.list = response.data;
        }
    }
}
