import React from 'react'
import {
  Skeleton,
  Alert,
  Typography,
  Card,
  Col,
  Row,
  Button,
} from 'antd'
import ExportIcon from "@ant-design/icons/CloudDownloadOutlined";

import { Progress } from './ratings.types'
import { useAggregatedRatings, useExportAction } from './ratings.hooks'
import styles from './ratings.module.css'
import Chart from './Chart'
import LatestComments from './LatestComments'
import RatingsByStars from './RatingsByStars';

const { Title } = Typography
const { Meta } = Card

const AggregatedStat: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span className={styles.aggregatedStat}>
    { children }
  </span>
)

const RecentProgress: React.FC<{ progress: Progress }> = ({ progress }) => {
  const getColor = () => {
    switch(progress){
      case Progress.Up:
        return 'green'
      case Progress.Down:
         return 'darkred'
      case Progress.Same:
      default:
        return 'orange'
      }
    }

  const getSign = () => {
    switch(progress){
      case Progress.Up:
        return '▴'
      case Progress.Down:
         return '▾'
      case Progress.Same:
      default:
        return '-'
      }
    }

    return (
      <span style={{ color: getColor(), }}>
      { getSign() }
    </span>
    )
  }

const RatingsPage: React.FC = () => {
  const { loading, data: aggregatedRatings, error } = useAggregatedRatings()
  const exportAllRatings = useExportAction()

  if(error){
    return (
      <Alert
        message={`An error occured while loading the config. ${error}`}
        type="error"
        showIcon
      />
    )
  }

  return (
    <Skeleton
      loading={loading || !aggregatedRatings}
      active={true}
      paragraph={{rows: 16}}
    >
      
      <div className={styles.header}>
      <Title level={2}>Ratings</Title>
      <Button
        icon={<ExportIcon />}
        className={styles.exportButton}
        onClick={async () => { (await exportAllRatings)() }}
      >
        Export
      </Button>
      </div>
      {aggregatedRatings && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Card bordered={false}>
                <AggregatedStat>{aggregatedRatings.averageRating || '-'} / 5</AggregatedStat>
                <Meta title="Overall score" description={ <>Based on <strong>{aggregatedRatings.totalRatings}</strong> evaluations</> } />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
                <AggregatedStat>
                  { aggregatedRatings.last30DaysAverageRating || '-' } / 5
                  { aggregatedRatings.last30DaysAverageRating > 0 && <RecentProgress progress={aggregatedRatings.last30DaysProgress} /> }
                  </AggregatedStat>
                <Meta title="Last 30 days" description={ <>Based on <strong>{ aggregatedRatings.last30DaysTotalRating }</strong> evaluations</> } />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
                <RatingsByStars nbRatings={Object.fromEntries(aggregatedRatings.nbRatingsByScore.map(({ rating, nbRatings }) => [rating, nbRatings]))} />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={12}>
              <Card bordered={false} style={{ height: 550 }}>
                <Chart />
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false} style={{ height: 550 }}>
                <LatestComments />
              </Card>
            </Col>
          </Row>
        </div>
      )}
      
    </Skeleton>
  )
}

export default RatingsPage
