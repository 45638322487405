import React from "react";
import {observer} from "mobx-react";
import {Typography, Button, Table, Pagination, Input} from "antd";
import {PlusOutlined, CloudDownloadOutlined} from "@ant-design/icons";
import {useBrokerStore} from "../../stores";
import {LightBroker} from "../../schemas";
import {downloadFile, useNavigation, usePagination} from "../../utils";
import {SmartTrashIcon} from "../../components";
import style from "../style.module.scss";
import {ColumnProps} from "antd/es/table";
import {useLazyEffect} from "../../utils/user-lazy-effect";
import {CountriesBtns} from "../../components/countries-btns";
import { SOURCE_MOVINGWALDO } from '../../utils/sources.const'
import { createBrokerCsvExport } from "../../utils/csv/brokerCsvHelper";

const {Title} = Typography;

export const BrokersPage = observer(() => {
    const navigation = useNavigation();
    const brokerStore = useBrokerStore();
    const [params, setParams, setSearch, setAddToUrl] = usePagination();
    const [ exporting, setExporting ] = React.useState(false)

    const onExport = async () => {
        try {
            setExporting(true)
            const brokers = await brokerStore.getAllBrokers()
            downloadFile('brokers.csv', createBrokerCsvExport(brokers))
        } catch(error) {
        } finally {
            setExporting(false)
        }
    }

    //set to true in mounted
    React.useEffect(() => {
        setAddToUrl(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLazyEffect(() => {
        brokerStore.fetchList({
            offset: params.offset,
            limit: params.limit,
            search: params.search,
        });
    }, [params], 500)

    const columns: ColumnProps<LightBroker>[] = [
        {
            title: "Name",
            key: "name",
            render: (_, record) => record.name,
        },
        {
            title: "Source",
            key: "source",
            render: (_, record) => record.source,
        },
        {
            title: "Agent Id",
            key: "agentId",
            render: (_, record) => record.source !== SOURCE_MOVINGWALDO ? record.agentId : null,
        },
        {
            title: "Referer code",
            key: "message",
            render: (_, record) => record.refererToken,
        },
        {
            title: "Email",
            key: "email",
            render: (_, record) => record.email,
        },
        {
            title: "Creation date",
            key: "creation_date",
            render: (_, record) => record.createdAt.format("YYYY-MM-DD HH:mm"),
        },
        {
            title: "",
            key: "remove",
            render: (_, record) => (
                <SmartTrashIcon
                    onConfirm={() =>
                        brokerStore.delete(record.id, {
                            offset: params.offset,
                            limit: params.limit,
                            search: params.search,
                        })
                    }
                />
            ),
        },
    ];

    return (
        <div>
            <Title level={2}>Brokers</Title>

            <div className={style.top_content}>
                <div className={style.top_content__row}>
                    <Input
                        className="search-input"
                        placeholder="search"
                        value={params.search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <CountriesBtns/>
                </div>

                <div>
                    <Button
                        icon={<CloudDownloadOutlined />}
                        className={style.export_button}
                        onClick={onExport}
                        disabled={exporting}
                    >
                        { exporting ? 'Exporting ....' : 'Export'  }
                    </Button>

                    <Button
                        type="primary"
                        icon={<PlusOutlined/>}
                        className={style.create_button}
                        onClick={() => navigation.createBroker()}
                    >
                        Create new
                    </Button>
                </div>
            </div>
            <Table<LightBroker>
                dataSource={brokerStore.list?.rows}
                rowKey="id"
                columns={columns}
                loading={brokerStore.isFetchingListCount > 0}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => navigation.editBroker(record.id),
                })}
            />
            <br/>
            <Pagination
                showSizeChanger
                onChange={setParams}
                current={params.page}
                defaultCurrent={1}
                pageSize={params.pageSize}
                total={brokerStore.list?.count}
            />
        </div>
    );
});
