import React from 'react'

import styles from './ratings.module.css'
import Stars from './Stars'

type RatingsPercentageProps = {
  percentage: number
}

const RatingsPercentage: React.FC<RatingsPercentageProps> = ({ percentage }) => {
  return (
    <div className={styles.ratingPercentageContainer}>
      <div className={styles.ratingPercentage} style={{ width: `${percentage}%` }} />
   </div>
  )
}

const NB_STARS = 5

type RatingsByStarsProps = {
  nbRatings: Record<number, number>
}

const RatingsByStars: React.FC<RatingsByStarsProps> = ({ nbRatings }) => {
  const totalRatings = Object.values(nbRatings).reduce((acc, value) => { return acc + value }, 0)
  const getPercentage = (value: number) => totalRatings === 0 ? 0 : Math.round((value / totalRatings) * 100)

  return (
    <>
      { Array(NB_STARS).fill(undefined).map((_, index) => {
        const stars = NB_STARS - index        
        const nb = nbRatings[stars] ?? 0
        const percentage = getPercentage(nb)
        
        return (
          <div className={styles.ratingByStarsRow} key={index}>
            <Stars rating={stars} />
            <div className={styles.ratingByStarsNbRatings}> <strong>{nb}</strong> </div>
            <RatingsPercentage percentage={percentage} />
          </div>
        )
      }) }
    </>
  )
}

export default RatingsByStars
