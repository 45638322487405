import React from "react";

export const FieldWrapper = (props: {
    label: string;
    text?: string;
    flex: number;
    children?: React.ReactNode;
}) => (
    <span className={`flex-${props.flex}`}>
        <span>
            <b>{props.label}</b>
        </span>
        <br />
        {props.children ? props.children : <span>{props.text}</span>}
    </span>
);
