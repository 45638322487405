import React from "react";
import {observer} from "mobx-react";
import {Typography, Button, Skeleton, message, Radio, Alert} from "antd";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import {
    InputWrapper,
    SelectWrapper,
    TextAreaWrapper,
    SwitchWrapper,
    RegionsSelect,
} from "../../components";
import {useNavigation} from "../../utils";
import {ModuleForm, moduleFormSchema} from "../../forms";
import {useModuleStore, useOrganizationStore} from "../../stores";
import {transform} from "./transfomer";
import BUTTON_ICONS_CHOICES from "../../utils/button-icons-choices";
import BUTTON_TYPES_CHOICES from "../../utils/button-types-choices";
import SOURCE_CHOICES from "../../utils/source-choices";
import {CountriesSelect} from "../../components/countries-select";

const {Title} = Typography;

const initialValues = {
    titleFr: "",
    titleEn: "",
    shortDescriptionFr: "",
    shortDescriptionEn: "",
    longDescriptionFr: "",
    longDescriptionEn: "",
    buttonTextFr: "",
    buttonTextEn: "",
    linkFr: "",
    linkEn: "",
    name: "",
    order: 0,
    showFor: "both",
    isPrivate: false,
    brokerSourceWhitelisted: "",
    brokerSourceBlacklisted: "",
    buttonType: "",
    buttonIcon: "",
    isAnonExternalLink: true,
    isExternalLink: false,
    anonLinkTarget: "_self",
    linkTarget: "_self",
    country: 'CA'
};

const linkTargets = [
    {
        value: "_self",
        display: "Same page"
    },
    {
        value: "_blank",
        display: "New page"
    }
];

export const ModulePage = observer(() => {
    const navigation = useNavigation();
    const moduleStore = useModuleStore();
    const organizationStore = useOrganizationStore();
    const [isFetchingModule, setFetchingModule] = React.useState(false);
    const [isUpserting, setUpserting] = React.useState(false);
    const params = useParams<{ id?: string }>();
    const formik = useFormik<ModuleForm>({
        initialValues,
        validationSchema: moduleFormSchema,
        validateOnMount: true,
        onSubmit: () => {
        }
    });

    React.useEffect(() => {
        organizationStore.fetchDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (params.id) {
            setFetchingModule(true);
            moduleStore
                .fetchModule(params.id)
                .then(m => formik.setValues(transform(m)))
                .finally(() => setFetchingModule(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const editModule = React.useCallback(() => {
        if (params.id) {
            setUpserting(true);
            moduleStore
                .editModule(params.id, formik.values)
                .then(() => void message.success("Module edited"))
                .finally(() => setUpserting(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, formik.values]);

    const createModule = React.useCallback(() => {
        setUpserting(true);
        moduleStore
            .createModule(formik.values)
            .then(() => {
                message.success("Module Created");
                navigation.goToModules();
            })
            .finally(() => setUpserting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    const setBrokerSourceFilteringValue = (name: string, value: unknown) => {
        if (value === undefined) {
            formik.setFieldValue(name, "");
        } else if (Array.isArray(value)) {
            formik.setFieldValue(name, value.join("|"));
        }
    };

    return (
        <div>
            <Title level={2}>Module</Title>

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingModule}
                    active={true}
                    paragraph={{rows: 20}}
                >
                    <span className="small_separation_text flex-12">
                        Global information
                    </span>

                    <InputWrapper
                        required
                        title="Name (max 20)"
                        value={formik.values.name}
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                        maxLength={20}
                    />
                    <InputWrapper
                        title="Zip code filter"
                        value={formik.values.zipCodeFilter ?? ""}
                        name="zipCodeFilter"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={4}
                    />
                    <CountriesSelect
                        required
                        title="Countries"
                        value={formik.values.country}
                        name="country"
                        setFieldValue={formik.setFieldValue}
                        flexSize={4}
                    />
                    <RegionsSelect
                        country={formik.values.country}
                        title="Regions"
                        value={formik.values.provinceFilter ?? ""}
                        name="provinceFilter"
                        setFieldValue={formik.setFieldValue}
                        flexSize={4}
                    />

                    <InputWrapper
                        title="Tracking id"
                        value={formik.values.trackingId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="trackingId"
                        flexSize={2}
                    />
                    <div className="flex-2">
                        <label>
                            Show for
                        </label>
                        <div>
                            <Radio.Group
                                onChange={formik.handleChange}
                                name="showFor"
                                value={formik.values.showFor}>
                                <Radio value="owner">Owner</Radio>
                                <Radio value="renter">Renter</Radio>
                                <Radio value="both">Both</Radio>
                            </Radio.Group>
                        </div>
                    </div>

                    <span className="small_separation_text flex-12">
                        Visibility
                    </span>

                    <div className="flex-4">
                        <SwitchWrapper
                            title="Is a private module"
                            checked={formik.values.isPrivate ?? false}
                            setFieldValue={formik.setFieldValue}
                            name="isPrivate"
                            flexSize={12}
                        />
                        {formik.values.isPrivate && (
                            <Alert
                                message="This module will not be displayed by default"
                                type="warning"
                                showIcon
                            />
                        )}
                    </div>

                    <div className="flex-4">
                        <SelectWrapper
                            title="Hide for these brokers"
                            setFieldValue={setBrokerSourceFilteringValue}
                            name="brokerSourceBlacklisted"
                            value={formik.values.brokerSourceBlacklisted ? formik.values.brokerSourceBlacklisted.split('|') : []}
                            options={SOURCE_CHOICES}
                            mode="multiple"
                            flexSize={12}
                            disabled={!!formik.values.isPrivate}
                        />
                    </div>

                    <div className="flex-4">
                        <SelectWrapper
                            title="Show for these brokers"
                            setFieldValue={setBrokerSourceFilteringValue}
                            name="brokerSourceWhitelisted"
                            value={formik.values.brokerSourceWhitelisted ? formik.values.brokerSourceWhitelisted.split('|') : []}
                            options={SOURCE_CHOICES}
                            mode="multiple"
                            flexSize={12}
                            disabled={!formik.values.isPrivate}
                        />
                    </div>
                </Skeleton>
            </div>

            <br/>

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingModule}
                    active={true}
                    paragraph={{rows: 20}}
                >
                    <div className="flex-12">
                        <span className="small_separation_text">
                            Public information
                        </span>
                    </div>

                    <InputWrapper
                        required
                        title="Title Fr"
                        value={formik.values.titleFr}
                        name="titleFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                    <InputWrapper
                        required
                        title="Title En"
                        value={formik.values.titleEn}
                        name="titleEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                    <InputWrapper
                        required
                        title="Button text Fr"
                        value={formik.values.buttonTextFr}
                        name="buttonTextFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                    <InputWrapper
                        required
                        title="Button text En"
                        value={formik.values.buttonTextEn}
                        name="buttonTextEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={3}
                    />
                    <SelectWrapper
                        title="Button Icon"
                        setFieldValue={formik.setFieldValue}
                        name="buttonIcon"
                        flexSize={6}
                        value={formik.values.buttonIcon ?? ""}
                        options={BUTTON_ICONS_CHOICES}
                    />

                    <SelectWrapper
                        title="Button Type"
                        setFieldValue={formik.setFieldValue}
                        name="buttonType"
                        value={formik.values.buttonType ?? ""}
                        flexSize={6}
                        options={BUTTON_TYPES_CHOICES}
                    />

                    <InputWrapper
                        title="Link Fr"
                        value={formik.values.linkFr}
                        name="linkFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />
                    <InputWrapper
                        title="Link En"
                        value={formik.values.linkEn}
                        name="linkEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />
                    <InputWrapper
                        required
                        title="Short description Fr"
                        value={formik.values.shortDescriptionFr}
                        name="shortDescriptionFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />
                    <InputWrapper
                        required
                        title="Short description En"
                        value={formik.values.shortDescriptionEn}
                        name="shortDescriptionEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <TextAreaWrapper
                        required
                        title="Long description Fr"
                        value={formik.values.longDescriptionFr}
                        name="longDescriptionFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <TextAreaWrapper
                        required
                        title="Long description En"
                        value={formik.values.longDescriptionEn}
                        name="longDescriptionEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <SelectWrapper
                        title="Link target"
                        options={linkTargets}
                        value={formik.values.linkTarget ?? "_self"}
                        name="linkTarget"
                        setFieldValue={formik.setFieldValue}
                        flexSize={3}
                    />

                    <SelectWrapper
                        title="Featured Buy Offer"
                        isLoading={organizationStore.isFetchingDeals}
                        options={organizationStore.dealList}
                        value={
                            formik.values.extraData?.featuredBuyOfferId ?? ""
                        }
                        name="extraData.featuredBuyOfferId"
                        setFieldValue={formik.setFieldValue}
                        flexSize={3}
                    />

                    <SwitchWrapper
                        title="Is external link"
                        checked={formik.values.isExternalLink ?? false}
                        setFieldValue={formik.setFieldValue}
                        name="isExternalLink"
                        flexSize={3}
                    />
                </Skeleton>
            </div>
            <br/>

            <div className="description-wrapper flex-wrapper">
                <Skeleton
                    loading={isFetchingModule}
                    active={true}
                    paragraph={{rows: 20}}
                >
                    <div className="flex-12">
                        <span className="small_separation_text">
                            Anonymous information
                        </span>
                    </div>

                    <InputWrapper
                        title="Anonymous link fr"
                        value={formik.values.anonymousLinkFr ?? ""}
                        name="anonymousLinkFr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <InputWrapper
                        title="Anonymous link en"
                        value={formik.values.anonymousLinkEn ?? ""}
                        name="anonymousLinkEn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        flexSize={6}
                    />

                    <SelectWrapper
                        title="Anonymous link target"
                        options={linkTargets}
                        value={formik.values.anonLinkTarget ?? "_self"}
                        name="anonLinkTarget"
                        setFieldValue={formik.setFieldValue}
                        flexSize={2}
                    />

                    <SwitchWrapper
                        title="Is anon external link"
                        checked={formik.values.isAnonExternalLink ?? true}
                        setFieldValue={formik.setFieldValue}
                        name="isAnonExternalLink"
                        flexSize={3}
                    />
                </Skeleton>
            </div>

            <br/>

            {params.id ? (
                <Button
                    onClick={editModule}
                    type="primary"
                    loading={isUpserting}
                    disabled={!formik.isValid || !formik.dirty || isUpserting}
                >
                    Edit
                </Button>
            ) : (
                <Button
                    onClick={createModule}
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty}
                >
                    Create
                </Button>
            )}
        </div>
    );
});
