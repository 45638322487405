import moment, { Moment } from "moment";
import * as yup from "yup";

export class MomentSchema extends yup.mixed<Moment> {
    constructor() {
        super({ type: "moment" });
        (this as any).transforms.push(function (this: any, value: any) {
            if (this.isType(value)) {
                return value;
            }
            value = moment(value);
            return value.isValid() ? value : moment.invalid();
        });
    }

    _typeCheck(v: any) {
        return v?.isValid?.();
    }
}
