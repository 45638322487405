import React from "react";
import Papa from "papaparse";
import {observer} from "mobx-react";
import {Typography, Button, Drawer, Tabs, Upload} from "antd";
import {RcCustomRequestOptions} from "antd/lib/upload/interface";
import {TypesSelect} from "../../../components/types-select";
import {
    InputWrapper,
    ImageInput,
    TextAreaWrapper,
    NotificationManager,
    NewIcon,
    RegionsSelect,
    SelectWrapper,
    SmartTrashIcon,
} from "../../../components";
import {buyOfferContactMethods, contactLanguages} from "../../../utils";
import {ReactSortable} from "react-sortablejs";
import {
    BuyOfferForm,
    BuyOfferExtraInfoForm,
    BuyOfferSectionForm,
} from "../form";
import style from "./style.module.scss";
import sortBy from "lodash/sortBy";
import produce from "immer";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {v4 as uuid} from "uuid";
import {useOrganizationStore} from "../../../stores";
import MarkDownEditor from "../../../components/markdown-editor";
import {Label} from "../../../components/input-wrapper";
import {CountriesSelect} from "../../../components/countries-select";
import CopyableText from "../../../components/CopyableText";

const {Title} = Typography;
const {TabPane} = Tabs;

const getSection = () => ({
    id: uuid(),
    isNew: true,
    order: 0,
    titleFr: "Nouvelle section",
    titleEn: "New section",
    associatedFilter: "",
    descriptionEn: "Description En",
    descriptionFr: "Description Fr",
});

interface BuyOfferDrawerProps {
    onChange: (e: React.ChangeEvent) => void;
    onBlur: (e: React.ChangeEvent) => void;
    onClose: (isOpen: false) => void;
    setFieldValue: (field: string, value: any) => void;
    buyOffer: BuyOfferForm | null | undefined;
    types: { value: string, display: string }[] | []
    index: number;
    isOpen: boolean;
}

export const BuyOfferDrawer = observer((props: BuyOfferDrawerProps) => {
    const organizationStore = useOrganizationStore();

    const sortedSections = sortBy(props.buyOffer?.buyOfferSections, "order");
    const sortedExtraInfo = sortBy(props.buyOffer?.buyOfferExtraInfo, "order");

    const onSectionsChange = (list: BuyOfferSectionForm[]) => {
        const modifiedList = produce(list, (sections) => {
            sections.forEach((l, index) => (l.order = index));
        });
        props.setFieldValue(
            `buyOffers[${props.index}].buyOfferSections`,
            modifiedList
        );
    };

    const onExtraInfoChange = (list: BuyOfferExtraInfoForm[]) => {
        const modifiedList = produce(list, (extraInfos) => {
            extraInfos.forEach((l, index) => (l.order = index));
        });
        props.setFieldValue(
            `buyOffers[${props.index}].buyOfferExtraInfo`,
            modifiedList
        );
    };

    const removeSection = (index: number, id: string, isNew?: boolean) => {
        if (!isNew) {
            organizationStore.deleteSection(id);
        }

        const modifiedList = produce(sortedSections, (sections) => {
            sections.splice(index, 1);
            sections.forEach((l, index) => (l.order = index));
        });
        props.setFieldValue(
            `buyOffers[${props.index}].buyOfferSections`,
            modifiedList
        );
    };

    const removeExtraInfo = (index: number, id: string, isNew?: boolean) => {
        if (!isNew) {
            organizationStore.deleteExtraInfo(id);
        }
        const modifiedList = produce(sortedExtraInfo, (extraInfos) => {
            extraInfos.splice(index, 1);
            extraInfos.forEach((l, index) => (l.order = index));
        });
        props.setFieldValue(
            `buyOffers[${props.index}].buyOfferExtraInfo`,
            modifiedList
        );
    };

    const addSection = () => {
        const modifiedList = produce(sortedSections, (sections) => {
            sections.unshift(getSection());
            sections.forEach((l, index) => (l.order = index));
        });
        props.setFieldValue(
            `buyOffers[${props.index}].buyOfferSections`,
            modifiedList
        );
    };

    const addExtraInfo = () => {
        const modifiedList = produce(sortedExtraInfo, (extraInfos) => {
            extraInfos.unshift(getSection());
            extraInfos.forEach((l, index) => (l.order = index));
        });
        props.setFieldValue(
            `buyOffers[${props.index}].buyOfferExtraInfo`,
            modifiedList
        );
    };

    const uploadFile = async (options: RcCustomRequestOptions) => {
        const {file} = options;
        Papa.parse(file, {
            complete: async (results) => {
                const fileName = file.name;
                const payload = results.data.flat();

                const success = await organizationStore.uploadBuyOfferZipCode(
                    props.buyOffer!.id,
                    fileName,
                    payload
                );

                if (success) {
                    props.setFieldValue(
                        `buyOffers[${props.index}].zipCodeFile`,
                        fileName
                    );
                    options.onSuccess({}, file);
                }
            },
            error: (...err) => {
                console.error(...err);
                NotificationManager.showError("Error while parsing the file");
            },
        });
    };

    const uploadDestinationFile = async (options: RcCustomRequestOptions) => {
        const {file} = options;
        Papa.parse(file, {
            complete: async (results) => {
                const fileName = file.name;
                const payload = results.data.flat();

                const success = await organizationStore.uploadBuyOfferZipCodeDestination(
                    props.buyOffer!.id,
                    fileName,
                    payload
                );

                if (success) {
                    props.setFieldValue(
                        `buyOffers[${props.index}].zipCodeDestinationFile`,
                        fileName
                    );
                    options.onSuccess({}, file);
                }
            },
            error: (...err) => {
                console.error(...err);
                NotificationManager.showError("Error while parsing the file");
            },
        });
    };

    const deleteZipCodeFilter = async () => {
        props.setFieldValue(`buyOffers[${props.index}].zipCodeFile`, null);
        await organizationStore.deleteBuyOfferZipCode(props.buyOffer!.id);
    };

    const deleteZipCodeDestinationFilter = async () => {
        props.setFieldValue(`buyOffers[${props.index}].zipCodeDestinationFile`, null);
        await organizationStore.deleteBuyOfferZipCodeDestination(props.buyOffer!.id);
    };

    return (
        <Drawer
            visible={props.isOpen}
            onClose={() => props.onClose(false)}
            width={900}
            mask={false}
        >
            <div id="buy-offer-drawer">
                <Title level={3} className="flex-12">
                    Buy offer
                </Title>

                {props.buyOffer?.id && (
                    <div style={{ marginLeft: '0.5rem' }}>
                        <CopyableText text={props.buyOffer?.id}>
                            <div style={{ display: 'inline-block', paddingLeft: '0.25rem' }}>
                                id: <strong>{ props.buyOffer?.id }</strong>
                            </div>
                        </CopyableText>
                    </div>
                )}

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Information" key="1">
                        <div
                            className={`flex-wrapper ${style.buy_offer_drawer}`}
                        >
                            <span className="small_separation_text flex-12">
                                Public Info
                            </span>
                            <InputWrapper
                                value={props.buyOffer?.titleFr}
                                required={true}
                                title="Title (Fr)"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].titleFr`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.titleEn}
                                title="Title (En)"
                                required={true}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].titleEn`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.shortDescriptionFr}
                                title="Short description Fr"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].shortDescriptionFr`}
                                flexSize={12}
                            />
                            <InputWrapper
                                value={props.buyOffer?.shortDescriptionEn}
                                title="Short description En"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].shortDescriptionEn`}
                                flexSize={12}
                            />
                            <InputWrapper
                                value={props.buyOffer?.tagFr}
                                title="Tag Fr"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].tagFr`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.tagEn}
                                title="Tag En"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].tagEn`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.trackingId}
                                title="Tracking id"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].trackingId`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.filter}
                                title="Filter"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].filter`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.price}
                                title="Price"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].price`}
                                flexSize={6}
                            />
                            <SelectWrapper
                                flexSize={6}
                                required={true}
                                options={buyOfferContactMethods}
                                value={props.buyOffer?.contactMethod ?? "none"}
                                name={`buyOffers[${props.index}].contactMethod`}
                                setFieldValue={props.setFieldValue}
                                title="Contact method"
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "buy-offer-drawer"
                                    )!!
                                }
                            />
                            <SelectWrapper
                                flexSize={6}
                                required={true}
                                options={contactLanguages}
                                value={props.buyOffer?.contactLanguage ?? "fr"}
                                name={`buyOffers[${props.index}].contactLanguage`}
                                setFieldValue={props.setFieldValue}
                                title="Contact language"
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "buy-offer-drawer"
                                    )!!
                                }
                            />
                            <InputWrapper
                                value={props.buyOffer?.phoneNumber}
                                title="Phone number"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].phoneNumber`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.urlFr}
                                title="Url Fr"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].urlFr`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.urlEn}
                                title="Url En"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].urlEn`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.pricingInfoFr}
                                title="Pricing info Fr"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].pricingInfoFr`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.pricingInfoEn}
                                title="Pricing info En"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].pricingInfoEn`}
                                flexSize={6}
                            />
                            <div className="flex-6">
                                <span>Small image url</span>
                                <ImageInput
                                    url={props.buyOffer?.imageSmallUrl ?? ""}
                                    folder="buy-offer/small-image"
                                    onChange={(v) =>
                                        props.setFieldValue(
                                            `buyOffers[${props.index}].imageSmallUrl`,
                                            v
                                        )
                                    }
                                    onDelete={() =>
                                        props.setFieldValue(
                                            `buyOffers[${props.index}].imageSmallUrl`,
                                            null
                                        )
                                    }
                                />
                            </div>
                            <div className="flex-6">
                                <span>Medium image url</span>
                                <ImageInput
                                    url={props.buyOffer?.imageMediumUrl ?? ""}
                                    folder="buy-offer/medium-image"
                                    onChange={(v) =>
                                        props.setFieldValue(
                                            `buyOffers[${props.index}].imageMediumUrl`,
                                            v
                                        )
                                    }
                                    onDelete={() =>
                                        props.setFieldValue(
                                            `buyOffers[${props.index}].imageMediumUrl`,
                                            null
                                        )
                                    }
                                />
                            </div>
                            <TextAreaWrapper
                                title="Long description Fr"
                                value={props.buyOffer?.longDescriptionFr}
                                name={`buyOffers[${props.index}].longDescriptionFr`}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                flexSize={12}
                            />
                            <TextAreaWrapper
                                title="Long description En"
                                value={props.buyOffer?.longDescriptionEn}
                                name={`buyOffers[${props.index}].longDescriptionEn`}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                flexSize={12}
                            />

                            <TextAreaWrapper
                                title="Custom message Fr"
                                value={props.buyOffer?.customMessageFr}
                                name={`buyOffers[${props.index}].customMessageFr`}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                flexSize={12}
                            />

                            <TextAreaWrapper
                                title="Custom message En"
                                value={props.buyOffer?.customMessageEn}
                                name={`buyOffers[${props.index}].customMessageEn`}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                flexSize={12}
                            />

                            <span className="small_separation_text flex-12">
                                Other Info
                            </span>
                            <InputWrapper
                                value={props.buyOffer?.smartMovingUrl}
                                title="Smart Moving Url"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].smartMovingUrl`}
                                flexSize={12}
                            />
                             <RegionsSelect
                                country={props.buyOffer?.country}
                                title="Regions"
                                value={props.buyOffer?.regions ?? ""}
                                name={`buyOffers[${props.index}].regions`}
                                setFieldValue={props.setFieldValue}
                                flexSize={12}
                            />
                             <CountriesSelect
                                title="Countries"
                                value={props.buyOffer?.country ?? ""}
                                name={`buyOffers[${props.index}].country`}
                                setFieldValue={props.setFieldValue}
                                flexSize={6}
                            />


                            <TypesSelect
                                title="Types"
                                value={props.buyOffer?.types ?? []}
                                name={`buyOffers[${props.index}].types`}
                                setFieldValue={props.setFieldValue}
                                flexSize={6}
                                types={props.types}
                            />
                            <InputWrapper
                                value={props.buyOffer?.email}
                                title="Email"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].email`}
                                flexSize={6}
                            />
                            <InputWrapper
                                value={props.buyOffer?.emailCC}
                                title="Email CC"
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                name={`buyOffers[${props.index}].emailCC`}
                                flexSize={6}
                            />
                            {props.buyOffer && !props.buyOffer.isNew && (
                                <>
                                    <div className="flex-6">
                                    <div className="small_separation_text" style={{ display: 'block' }}>
                                        Zip code filter
                                    </div>
                                    {props.buyOffer.zipCodeFile && (
                                        <div>
                                            <b>Current:</b>{" "}
                                            {props.buyOffer.zipCodeFile}
                                            <SmartTrashIcon
                                                onConfirm={deleteZipCodeFilter}
                                            />
                                        </div>
                                    )}
                                    <Upload
                                        customRequest={uploadFile}
                                        fileList={[]}
                                    >
                                        <Button
                                            icon={<UploadOutlined/>}
                                            loading={
                                                organizationStore.isUploadingZipCode
                                            }
                                        >
                                            Upload csv
                                        </Button>
                                    </Upload>
                                    </div>

                                    <div className="flex-6">
                                        <div className="small_separation_text" style={{ display: 'block' }}>
                                            Zip code filter (destination)
                                        </div>
                                        {props.buyOffer.zipCodeDestinationFile && (
                                            <div>
                                                <b>Current:</b>{" "}
                                                {props.buyOffer.zipCodeDestinationFile}
                                                <SmartTrashIcon
                                                    onConfirm={deleteZipCodeDestinationFilter}
                                                />
                                            </div>
                                        )}
                                        <Upload
                                            customRequest={uploadDestinationFile}
                                            fileList={[]}
                                        >
                                            <Button
                                                icon={<UploadOutlined/>}
                                                loading={
                                                    organizationStore.isUploadingZipCodeDestination
                                                }
                                            >
                                                Upload csv
                                            </Button>
                                        </Upload>
                                    </div>
                                </>
                            )}

                            <br/>
                            <br/>
                        </div>
                    </TabPane>
                    <TabPane
                        tab={`Sections (${sortedSections.length})`}
                        key="2"
                    >
                        <Button
                            type="primary"
                            onClick={() => addSection()}
                            icon={<PlusOutlined/>}
                        >
                            Add
                        </Button>
                        <br/>
                        <br/>
                        <ReactSortable
                            list={sortedSections}
                            setList={onSectionsChange}
                        >
                            {sortedSections.map((section, index) => (
                                <div
                                    className={style.section_row}
                                    key={section.id}
                                >
                                    <span className={style.sortable_header}>
                                        <span>
                                            Section {index + 1}{" "}
                                            <NewIcon id={section.id}/>
                                        </span>
                                        <SmartTrashIcon
                                            skipConfirmation={section.isNew}
                                            onConfirm={() =>
                                                removeSection(
                                                    index,
                                                    section.id,
                                                    section.isNew
                                                )
                                            }
                                        />
                                    </span>
                                    <InputWrapper
                                        required
                                        value={section.titleFr}
                                        title="Title Fr"
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        name={`buyOffers[${props.index}].buyOfferSections[${index}].titleFr`}
                                        flexSize={6}
                                    />
                                    <InputWrapper
                                        required
                                        value={section.titleEn}
                                        title="Title En"
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        name={`buyOffers[${props.index}].buyOfferSections[${index}].titleEn`}
                                        flexSize={6}
                                    />
                                    <div className="flex-6">
                                        <Label title="Description Fr" required={true}/>
                                        <MarkDownEditor value={section.descriptionFr} setValue={(value: string) => {
                                            props.setFieldValue(`buyOffers[${props.index}].buyOfferSections[${index}].descriptionFr`, value)
                                        }}/>
                                    </div>
                                    <div className="flex-6">
                                        <Label title="Description En" required={true}/>
                                        <MarkDownEditor value={section.descriptionEn} setValue={(value: string) => {
                                            props.setFieldValue(`buyOffers[${props.index}].buyOfferSections[${index}].descriptionEn`, value)
                                        }}/>
                                    </div>
                                </div>
                            ))}
                        </ReactSortable>
                    </TabPane>
                    <TabPane tab={`Extra (${sortedExtraInfo.length})`} key="3">
                        <Button
                            type="primary"
                            onClick={() => addExtraInfo()}
                            icon={<PlusOutlined/>}
                        >
                            Add
                        </Button>
                        <br/>
                        <br/>
                        <ReactSortable
                            list={sortedExtraInfo}
                            setList={onExtraInfoChange}
                        >
                            {sortedExtraInfo.map((extraInfo, index) => (
                                <div
                                    className={style.section_row}
                                    key={extraInfo.id}
                                >
                                    <span className={style.sortable_header}>
                                        <span>
                                            Extra info {index + 1}{" "}
                                            <NewIcon id={extraInfo.id}/>
                                        </span>
                                        <SmartTrashIcon
                                            skipConfirmation={extraInfo.isNew}
                                            onConfirm={() =>
                                                removeExtraInfo(
                                                    index,
                                                    extraInfo.id,
                                                    extraInfo.isNew
                                                )
                                            }
                                        />
                                    </span>
                                    <InputWrapper
                                        required
                                        value={extraInfo.titleFr}
                                        title="Title Fr"
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        name={`buyOffers[${props.index}].buyOfferExtraInfo[${index}].titleFr`}
                                        flexSize={6}
                                    />
                                    <InputWrapper
                                        required
                                        value={extraInfo.titleEn}
                                        title="Title En"
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        name={`buyOffers[${props.index}].buyOfferExtraInfo[${index}].titleEn`}
                                        flexSize={6}
                                    />
                                    <TextAreaWrapper
                                        required
                                        title="Description Fr"
                                        value={extraInfo.descriptionFr}
                                        name={`buyOffers[${props.index}].buyOfferExtraInfo[${index}].descriptionFr`}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        flexSize={6}
                                    />
                                    <TextAreaWrapper
                                        required
                                        title="Description En"
                                        value={extraInfo.descriptionEn}
                                        name={`buyOffers[${props.index}].buyOfferExtraInfo[${index}].descriptionEn`}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        flexSize={6}
                                    />
                                </div>
                            ))}
                        </ReactSortable>
                    </TabPane>
                </Tabs>
            </div>
        </Drawer>
    );
});
