import React from 'react'
import StarIcon from '@ant-design/icons/StarFilled'

type StarsProps = {
  rating: number
}

const Stars: React.FC<StarsProps> = ({ rating }) => (
  <div>
    { Array(rating).fill(undefined).map((_, index) => <StarIcon key={`filled-${index}`} style={{ fontSize: '16px', color: '#FFD700' }} />) }
    { Array(5 - rating).fill(undefined).map((_, index) => <StarIcon key={`unfilled-${index}`} style={{ fontSize: '16px', color: '#ccc' }} />) }
  </div>
)

export default Stars
