import React from "react";
import {useMoveRequestStore, useOrganizationCategoryStore} from "../../stores";
import { MoveRequest, OrganizationCategory } from "../../schemas";
import { LinkIcon, FieldWrapper } from "../../components";
import { useParams } from "react-router-dom";
import { Typography, Skeleton, Tag } from "antd";
import style from "./style.module.scss";
import { getItemProp, useNavigation, formatAddress } from "../../utils";

const { Title, Link } = Typography;

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL

export const MoveRequestPage = () => {
    const params = useParams<{ id: string }>();
    const [moveRequest, setMoveRequest] = React.useState<MoveRequest | null>(
        null
    );
    const [isFetching, setIsFetching] = React.useState(false);
    const navigation = useNavigation();
    const store = useMoveRequestStore();
    const organizationCategoryStore = useOrganizationCategoryStore();

    React.useEffect(() => {
        setIsFetching(true);
        store
            .fetchMoveRequest(params.id)
            .then((mr) => setMoveRequest(mr))
            .finally(() => setIsFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

     React.useEffect(() => {
       organizationCategoryStore.fetchList({
           offset:0,
           limit:99999
       })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const organizationName = getItemProp(
        moveRequest?.organization.descriptions,
        "fr",
        "name"
    );

     let organizationCategory = null;
    if (organizationCategoryStore.organizationCategoryList?.rows && moveRequest) {
        organizationCategory = organizationCategoryStore.organizationCategoryList?.rows.find((oc: OrganizationCategory) => oc.id === moveRequest?.organization.categoryId) || null
    }


    const user = moveRequest?.move.user;
    const move = moveRequest?.move;

    return (
        <div className={style.container}>
            <Title level={2}>Move Request</Title>
            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{ rows: 16 }}
                >
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            Move request info
                        </span>
                        <FieldWrapper
                            label="Id"
                            text={moveRequest?.id}
                            flex={4}
                        />
                        <FieldWrapper
                            label="Created at"
                            text={moveRequest?.createdAt?.format(
                                "YYYY-MM-DD HH:mm"
                            )}
                            flex={4}
                        />
                        {moveRequest?.id && (
                            <FieldWrapper label="Move request pdf" flex={4}>
                                <Link
                                    href={`${REACT_APP_BFF_URL}/download-signature/?request_id=${moveRequest.id}`}
                                    target="_blank"
                                >
                                    Download pdf
                                </Link>
                            </FieldWrapper>
                        )}
                        <span className="small_separation_text flex-12">
                            Mover validation field
                        </span>
                        {moveRequest?.questions?.map((question) => (
                            <FieldWrapper
                                label={question?.question ?? ""}
                                text={question?.answer ?? ""}
                                flex={4}
                            />
                        ))}
                    </div>
                </Skeleton>
            </div>
            <br />
            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{ rows: 16 }}
                >
                    <div className="flex-wrapper">
                        <span className="small_separation_text flex-12">
                            Organization{" "}
                            <LinkIcon
                                onClick={() =>
                                    navigation.editOrganization(
                                        moveRequest?.organizationId
                                    )
                                }
                            />
                        </span>
                        <FieldWrapper
                            label="Organization name"
                            text={organizationName ?? ""}
                            flex={6}
                        />
                        <FieldWrapper label="Organization category" flex={6}>
                            <Tag color="magenta">{organizationCategory?.titleEn}</Tag>
                        </FieldWrapper>
                        <span className="small_separation_text flex-12">
                            Entity info{" "}
                        </span>
                        <FieldWrapper
                            label="Name (FR)"
                            flex={4}
                            text={moveRequest?.organizationEntity?.nameFr ?? ""}
                        />
                        <FieldWrapper label="Requires user agreement" flex={4}>
                            {moveRequest?.organizationEntity
                                ?.agreementRequired === true ? (
                                <Tag color="green">Yes</Tag>
                            ) : (
                                <Tag color="red">No</Tag>
                            )}
                        </FieldWrapper>
                        <FieldWrapper
                            label="Customer service phone"
                            flex={4}
                            text={
                                moveRequest?.organizationEntity
                                    ?.customerServicePhone ?? ""
                            }
                        />
                    </div>
                </Skeleton>
            </div>
            <br />
            <div className="description-wrapper">
                <Skeleton
                    loading={isFetching}
                    active={true}
                    paragraph={{ rows: 16 }}
                >
                    <span className="small_separation_text flex-12">
                        User info{" "}
                        <LinkIcon
                            onClick={() => navigation.goToUser(user?.id)}
                        />
                    </span>
                    <div className="flex-wrapper">
                        <FieldWrapper
                            label="Name"
                            text={`${user?.firstname} ${user?.lastname}`}
                            flex={4}
                        />
                        <FieldWrapper
                            label="Email"
                            flex={4}
                            text={user?.email ?? ""}
                        />
                        <FieldWrapper
                            label="Phone"
                            flex={4}
                            text={
                                user?.phoneCell ||
                                user?.phoneWork ||
                                user?.phoneHome ||
                                ""
                            }
                        />
                        <FieldWrapper label="User agreement" flex={4}>
                            <img
                                style={{ width: 150 }}
                                src={user?.signature ?? ""}
                                alt="signature"
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            label="User agreement signed on"
                            flex={4}
                            text={user?.agreementSignedAt?.format("LL") ?? ""}
                        />
                        <FieldWrapper label="Best time to reach" flex={4}>
                            {user?.reachTime
                                ?.filter((time): time is string => !!time)
                                .map((time) => (
                                    <Tag key={time}>{time}</Tag>
                                ))}
                        </FieldWrapper>
                        <span className="small_separation_text flex-12">
                            Move info
                        </span>
                        <FieldWrapper
                            label="Moving from"
                            text={move && formatAddress(move, "from")}
                            flex={4}
                        />
                        <FieldWrapper
                            label="Moving to"
                            text={move && formatAddress(move, "to")}
                            flex={4}
                        />
                        <FieldWrapper
                            label="Moving date"
                            text={move?.date?.format("LL")}
                            flex={4}
                        />
                    </div>
                </Skeleton>
            </div>
            <br />
        </div>
    );
};
