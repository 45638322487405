import React from "react";
import {observer} from "mobx-react";
import {useModuleStore} from "../../stores";
import {Module} from "../../schemas";
import {Table, Pagination, Button, Tag, Tooltip} from "antd";
import {PlusOutlined, EyeInvisibleFilled} from "@ant-design/icons";
import {ColumnProps} from "antd/es/table";
import {SmartTrashIcon} from "../../components";
import {usePagination, useNavigation} from "../../utils";
import {Typography} from "antd";
import style from "../style.module.scss";
import {useLazyEffect} from "../../utils/user-lazy-effect";
import {CountriesBtns} from "../../components/countries-btns";

const {Title} = Typography;

const processProvinceFilter = (filter: string | null | undefined) => {
    return (filter ?? "")
        .split("|")
        .map((province) => <Tag key={province}>{province}</Tag>);
};

export const ModulesPage = observer(() => {
    const moduleStore = useModuleStore();
    const navigation = useNavigation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setParams, setSearch, setAddToUrl] = usePagination();

    //set to true in mounted
    React.useEffect(() => {
        setAddToUrl(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLazyEffect(() => {
        moduleStore.fetchList({
            offset: params.offset,
            limit: params.limit
        });

    }, [params], 500)


    const columns: ColumnProps<Module>[] = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "name",
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
                <>
                    { record.isPrivate && <Tooltip title="This module is not visible by default"><EyeInvisibleFilled />{' '}</Tooltip> }
                    { record.titleFr }
                </>
            ),
        },
        {
            title: "Title",
            key: "titleFr",
            render: (_, record) => record.titleFr,
        },
        {
            title: "Province filter",
            key: "titleFr",
            width: 300,
            render: (_, record) => processProvinceFilter(record.provinceFilter),
        },
        {
            title: "",
            key: "remove",
            render: (_, record) => (
                <SmartTrashIcon
                    onConfirm={() =>
                        moduleStore.delete(record.id, {
                            offset: params.offset,
                            limit: params.limit,
                        })
                    }
                />
            ),
        },
    ];

    return (
        <div>

            <div className={style.top_content}>
                <Title level={2}>Modules</Title>
            </div>

            <div className={style.top_content}>
                <div className={style.top_content__row}>
                    <CountriesBtns/>
                </div>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    className={style.create_button}
                    onClick={() => navigation.createModule()}
                >
                    Create new
                </Button>
            </div>

            <Table<Module>
                dataSource={moduleStore.moduleList?.rows}
                rowKey="id"
                columns={columns}
                loading={moduleStore.isFetchingList}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => navigation.editModule(record.id),
                })}
            />
            <br/>
            <Pagination
                showSizeChanger
                onChange={setParams}
                current={params.page}
                defaultCurrent={1}
                pageSize={params.pageSize}
                total={moduleStore.moduleList?.count}
            />
        </div>
    );
});
