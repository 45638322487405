import * as yup from "yup";
import {regionSchema} from "./region.schema";


export const countrySchema = yup
    .object({
        id: yup.string().required(),
        name: yup.string().required(),
        regions: yup.array(regionSchema).required()
    })

export type Country = yup.InferType<typeof countrySchema>;
